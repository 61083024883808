import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MyWorkspaceCampaign from "./pages/MyWorkspaceCampaign/MyWorkspaceCampaign";
import ClientURLsPage from "./pages/ClientURLsPage/ClientURLsPage";
import CheckActiveBloggersUsingSerperPage from "./pages/CheckActiveBloggersUsingSerperPage/CheckActiveBloggersUsingSerperPage";
import CheckActiveBloggersUsingApifyPage from "./pages/CheckActiveBloggersUsingApifyPage/CheckActiveBloggersUsingApifyPage";
import BlogURLsPage from "./pages/BlogURLsPage/BlogURLsPage";
import BulkProcessPage from "./pages/BulkProcessPage/BulkProcessPage";
import IdentifyParagraphsPage from "./pages/IdentifyParagraphsPage/IdentifyParagraphsPage";
import LinkInsertionSuggestionPage from "./pages/LinkInsertionSuggestionPage/LinkInsertionSuggestionPage";
import ClassifyResultPages from "./pages/ClassifyResultPages/ClassifyResultPages";
import PageQualityCheckPage from "./pages/PageQualityCheckPage/PageQualityCheckPage";
import { CHECK_USER_CAMPAIGN_ACCESS, getFullApiUrl } from "./utils/apiPath";
import BlackListPage from "./pages/BlackListPage/BlackListPage";
import ExploreBlogWebsitePage from "./pages/ExploreBlogWebsitePage/ExploreBlogWebsitePage";
import PreviousClassifiedDataWithScreenshotPage from "./pages/PreviousClassifiedDataWithScreenshotPage/PreviousClassifiedDataWithScreenshotPage";
import GetCampaignInChargeHistoryPage from "./pages/GetCampaignInChargeHistoryPage/GetCampaignInChargeHistoryPage";
import FindLinkInsertPage from "./pages/FindLinkInsertPage/FindLinkInsertPage";

const CampaignRoutes = ({ toggleTheme }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hasAccess, setHasAccess] = useState(null);

  const pathParts = location.pathname.split("/");
  const campaignIdIndex = pathParts.indexOf("my_campaign") + 1;
  const campaignId =
    campaignIdIndex > 0 && campaignIdIndex < pathParts.length
      ? pathParts[campaignIdIndex]
      : null;

  useEffect(() => {
    fetchDataAndCheckAccess();
  }, []);
  const fetchDataAndCheckAccess = async () => {
    if (!campaignId) {
      navigate("/");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
        }),
      };

      const response = await fetch(
        getFullApiUrl(CHECK_USER_CAMPAIGN_ACCESS),
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setHasAccess(data === true);
      if (data !== true) {
        alert("You don't have access to this page.");
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/");
    }
  };
  // if (!campaignId || hasAccess === null) {
  //   return null; // Render nothing while checking access or if no campaignId is found
  // }

  // if (!hasAccess) {
  //   return null; // Render nothing if user doesn't have access
  // }
  const remainingPath = pathParts.slice(campaignIdIndex + 1).join("/");
  switch (remainingPath) {
    case "client_urls":
      return <ClientURLsPage toggleTheme={toggleTheme} />;
    case "check_active_bloggers_using_serper":
      return <CheckActiveBloggersUsingSerperPage toggleTheme={toggleTheme} />;
    // case "check_active_bloggers_using_apify":
    //   return <CheckActiveBloggersUsingApifyPage toggleTheme={toggleTheme} />;
    case "blog_urls":
      return <BlogURLsPage toggleTheme={toggleTheme} />;
    case "bulk_process":
      return <BulkProcessPage toggleTheme={toggleTheme} />;
    case "identify_paragraphs":
      return <IdentifyParagraphsPage toggleTheme={toggleTheme} />;
    case "link_insertion_suggestion":
      return <LinkInsertionSuggestionPage toggleTheme={toggleTheme} />;
    case "classify_result_page":
      return <ClassifyResultPages toggleTheme={toggleTheme} />;
    case "page_quality_check":
      return <PageQualityCheckPage toggleTheme={toggleTheme} />;
    case "black_list":
      return <BlackListPage toggleTheme={toggleTheme} />;
    case "explore_blogger_page":
      return <ExploreBlogWebsitePage toggleTheme={toggleTheme} />;
    case "screenshot":
        return <PreviousClassifiedDataWithScreenshotPage toggleTheme={toggleTheme} />;
    case "campaign_in_charge_history":
      return <GetCampaignInChargeHistoryPage toggleTheme={toggleTheme} />;
    case "find_link_insert":
      return <FindLinkInsertPage toggleTheme={toggleTheme} />;
    default: 
      return <MyWorkspaceCampaign toggleTheme={toggleTheme} />;
  }
};

export default CampaignRoutes;
