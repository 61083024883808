import React, { useEffect, useState } from "react";
import TableWithPagination from "../../utils/TableWithPagination/TableWithPagination";
import { IoIosAddCircleOutline, IoMdOpen } from "react-icons/io";
import { NavigateNext } from "@mui/icons-material";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link,
  Modal,
  Alert,
  Snackbar,CircularProgress
} from "@mui/material";
import {
  ADD_NEW_CAMPAIGN,
  DELETE_CAMPAIGN,
  GET_ALL_CAMPAIGNS_OF_USER,
  GET_ALL_TEAM,
  GET_CAMPAIGNS_OF_USER,
  GET_ID_BY_CAMPAIGNS_NAME,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_USER_LIST_ACCESS_TO_CAMPAIGN,
  GET_WORKSPACE_MEMBER_BY_TEAM_MEMBER,
  SEARCH_WORKSPACE_TO_ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UPDATE_IN_CHARGE_OF_CAMPAIGN,
  getFullApiUrl,
} from "../../utils/apiPath";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import { GrTest } from "react-icons/gr";
import { MdCampaign, MdDelete, MdOutlineWorkOutline } from "react-icons/md";
import { LiaEdit } from "react-icons/lia";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StatusModalComponent from "../../components/StatusModalComponent/StatusModalComponent";

const CampaignListOfUser = ({ toggleTheme }) => {
  const userId = localStorage.getItem("user_id");
  const [campaign_data, set_campaign_data] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [errorFetchMassage, setErrorFetchMassage] = useState(null);
  const [new_campaign_data, set_new_campaign_data] = useState({
    workspace_id: "",
    campaign_name: "",
    campaign_description: "",
  });
  const [newcampaignData, setnewcampaignData] = useState({
    workspace_id: "",
    campaign_name: "",
  });
  const [campaign_data_to_update_incharge, set_campaign_data_to_update_incharge] = useState({
    campaign_id: "",
    incharge_of: "",
    updated_by_user_id: ""
  })
  const [workspace_data, set_workspace_data] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [searchTermworkspace, setSearchTermworkspace] = useState("");
  const [searchWorkspaceResults, setSearchWorkspaceResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const [show_update_incharge_of_campaign_modal, set_show_update_incharge_of_campaign_modal] = useState(false)
  const [showDeletecampaignModal, setshowDeletecampaignModal] = useState(false);
  const handleDeletecampaignModalClose = () => {
    setshowDeletecampaignModal(false);
  };
  const [selectedcampaignsForDelete, setselectedcampaignsForDelete] = useState(
    []
  );
  const [searchTermToUpdateInCharge, setSearchTermToUpdateInCharge] = useState("");
  const [searchResultsToUpdateInCharge, setSearchResultsToUpdateInCharge] = useState([]);
  const [user_list_access_to_campaign, set_user_list_access_to_campaign] = useState()
  const [selected_user_to_update_incharge_of, set_selected_user_to_update_incharge_of] = useState(null)
  const handleSearchWorkspaceInputChange = (e) => {
    setSearchTermworkspace(e.target.value);
    // Reset new_campaign_data.workspace_id when input changes
    set_new_campaign_data({
      ...new_campaign_data,
      workspace_id: null,
    });
  };
  const handleWorkspaceSelect = (workspace) => {
    if (workspace) {
      set_new_campaign_data({
        ...new_campaign_data,
        workspace_id: workspace.workspace_id,
      });
      // Set the selected user
      setSelectedWorkspace(workspace);
    }
  };
  const [isLoading, setIsLoading] = React.useState(true); // Add loading state

  const fetchworkspaceListForSearch = async () => {
    try {
      setIsLoading(true); // Start loading
      const userId = localStorage.getItem("user_id");
      const response = await fetch(
        getFullApiUrl(SEARCH_WORKSPACE_TO_ADD_CAMPAIGN),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const myWorkspaceListData = await response.json();
      set_workspace_data(myWorkspaceListData);
    } catch (error) {
      set_workspace_data([]);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    set_new_campaign_data({ ...new_campaign_data, [name]: value });
  };

  const fetchCampaignList = async (user_id, teams) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_CAMPAIGNS_OF_USER)}?user_id=${user_id}`
      );

      console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const updatedCampaignListData = await data.map((workspaceRow) => {
        const teamWorkspace = teams.find(
          (t) => t.team_id === workspaceRow.team_id
        );
        if (teamWorkspace) {
          workspaceRow.team_name = teamWorkspace.team_name;
        }
        return workspaceRow;
      });
      set_campaign_data(data);
      setErrorFetchMassage(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setErrorFetchMassage(
      //   "There was a server error. Please contact your admin."
      // );
      set_campaign_data([]);

    }
  };
  const fetchTeamList = async () => {
    try {
      
      const response = await fetch(`${getFullApiUrl(GET_ALL_TEAM)}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();

      // set_team_data(teamData);
      return teamData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    const teams = await fetchTeamList();
    await fetchCampaignList(userId, teams);
  };
  const fetchIinitialData = async () => {
    setLoadingTableData(true);
    const teams = await fetchTeamList();
    await fetchCampaignList(userId, teams);
    setLoadingTableData(false);
  };
  useEffect(() => {
    fetchIinitialData();
  }, []);

  const navigate = useNavigate();
  const getCampaignIdByName = async (campaign_name) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_ID_BY_CAMPAIGNS_NAME
        )}?campaign_name=${campaign_name}`
      );

      console.log(response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOpenCampaignSection = (workspace) => {
    const campaign_id = workspace.campaign_id;
    // Navigate to the MyTeam page
    return `/solutions/${campaign_id}`;
  };
  const handleOpenCampaignTestingSection = (workspace) => {
    const campaign_id = workspace.campaign_id;
    // Navigate to the MyTeam page
    return `/my_campaign_testing/${campaign_id}`;
  };
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });

  const handleAddcampaignSubmit = async () => {
    if (!new_campaign_data.workspace_id || !new_campaign_data.campaign_name) {
      setErrorMessage(
        "Please add Campaign name and select Workspace before adding."
      );
      return;
    }
    try {
      setShowAddcampaignForm(false);
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Adding new campaign...",
      });
      setErrorMessage("");
      // Send a POST request to the server
      const response = await fetch(`${getFullApiUrl(ADD_NEW_CAMPAIGN)}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaign_name: new_campaign_data.campaign_name,
          workspace_id: new_campaign_data.workspace_id,
          campaign_description: new_campaign_data.campaign_description,
          created_by: localStorage.getItem("user_id")
        }),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to add new campaign");
      }

      // Handle success
      setModalState({
        isOpen: true,
        type: "success",
        message: "New campaign added successfully!",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 3000);
      // Close the modal or perform any other actions as needed
      handleAddClose();

      // Fetch the updated list of workspace members
      fetchData();
    } catch (error) {
      // Handle errors
      console.error("Error adding new workspace member:", error.message);
      setModalState({ isOpen: false, type: "", message: "" });
    }
  };

  const fetchUserListToSearch = async (campaign_id) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_USER_LIST_ACCESS_TO_CAMPAIGN)}`,
        {
          method: 'POST', // Change to POST method
          headers: {
            'Content-Type': 'application/json', // Set the content type
          },
          body: JSON.stringify({ campaign_id }) // Pass campaign_id in the body
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      set_user_list_access_to_campaign(data.team_member_list || []);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!searchTermworkspace) {
      setSearchWorkspaceResults(workspace_data); // Display all workspace data
      return;
    }

    const filteredWorkspaces = workspace_data.filter((workspace) =>
      workspace.workspace_name
        .toLowerCase()
        .includes(searchTermworkspace.toLowerCase())
    );
    setSearchWorkspaceResults(filteredWorkspaces);
  }, [searchTermworkspace, workspace_data]);

  const [showAddcampaignForm, setShowAddcampaignForm] = React.useState(false);
  const handleAddOpen = () => {
    fetchworkspaceListForSearch();
    setShowAddcampaignForm(true);
  };
  const handleAddClose = () => {
    setShowAddcampaignForm(false);
    setSelectedWorkspace(null);
    set_new_campaign_data({
      workspace_id: "",
      campaign_name: "",
      campaign_description: "",
    });
    setErrorMessage("");
  };


  const handleUpdateInChargeOfModalOpen = async (camapign) => {
    console.log("camapign")
    console.log(camapign.campaign_id)

    await fetchUserListToSearch(camapign.campaign_id);
    set_campaign_data_to_update_incharge({
      campaign_id: camapign.campaign_id,
      incharge_of: "",
      updated_by_user_id: localStorage.getItem("user_id")
    })
    set_show_update_incharge_of_campaign_modal(true);

  };

  const handleUpdateInChargeOfModalClose = () => {
    set_show_update_incharge_of_campaign_modal(false);
    set_user_list_access_to_campaign(null);
    set_campaign_data_to_update_incharge({
      campaign_id: "",
      incharge_of: "",
      updated_by_user_id: localStorage.getItem("user_id")
    });
    setErrorMessage("");
  };

  const handleUpdateInChargeOfData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_IN_CHARGE_OF_CAMPAIGN), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(campaign_data_to_update_incharge),
      });

      if (!response.ok) {
        throw new Error("Failed to edit campaign");
      }

      console.log("campaign edited successfully");
      handleUpdateInChargeOfModalClose(false);
      // Refresh campaign list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing campaign:", error.message);
    }
  };


  const handleUserSelect = (user) => {
    if (user) {
      set_campaign_data_to_update_incharge({
        ...campaign_data_to_update_incharge,
        incharge_of: user.team_member_id,
        updated_by_user_id: localStorage.getItem("user_id")
      });
      // Set the selected user
      set_selected_user_to_update_incharge_of(user);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTermToUpdateInCharge(e.target.value);
  };

  useEffect(() => {
    if (!searchTermToUpdateInCharge) {
      setSearchResultsToUpdateInCharge(user_list_access_to_campaign);
      return;
    }

    const searchTermLower = searchTermToUpdateInCharge.toLowerCase();
    const filteredUsers = user_list_access_to_campaign.filter(
      (user) =>
        user.user_name.toLowerCase().includes(searchTermLower) ||
        user.email_id.toLowerCase().includes(searchTermLower) ||
        (user.first_name &&
          user.first_name.toLowerCase().includes(searchTermLower)) ||
        (user.last_name &&
          user.last_name.toLowerCase().includes(searchTermLower))
    );
    setSearchResultsToUpdateInCharge(filteredUsers);
  }, [searchTermToUpdateInCharge, user_list_access_to_campaign]);



  // edit
  const [showEditcampaignModal, setShowEditcampaignModal] =
    React.useState(false);
  const handleEditClose = () => setShowEditcampaignModal(false);
  const handleEditcampaign = (campaign) => {
    setselectedcampaign(campaign);
    setShowEditcampaignModal(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedcampaign({ ...selectedcampaign, [name]: value });
  };
  const [selectedcampaign, setselectedcampaign] = useState({
    campaign_id: "",
    workspace_id: "",
    campaign_name: "",
  });

  const handleEditcampaignData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_CAMPAIGN), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedcampaign),
      });

      if (!response.ok) {
        throw new Error("Failed to edit campaign");
      }

      console.log("campaign edited successfully");
      setShowEditcampaignModal(false);
      // Refresh campaign list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing campaign:", error.message);
    }
  };
  // useEffect(() => {
  //   getCampaignDetails();
  // }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async (campaign_id) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaign_id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleOpenWorkspaceSection = (workspace) => {
    const workspace_id = workspace.workspace_id;

    // Navigate to the MyTeam page
    return `/my_workspace/${workspace_id}`;
  };
  const handleOpenTeamInfoSection = (workspace) => {
    const workspace_id = workspace.team_id;

    // Navigate to the MyTeam page
    return `/my_team/${workspace_id}`;
  };

  const handleIndividualDeleteClick = (campaign) => {
    if (
      campaign.member_designation === "Owner" ||
      campaign.member_designation === "Manager" ||
      campaign.member_designation === "Lead"
    ) {
      setselectedcampaignsForDelete([campaign]); // Select only the clicked campaign
      setshowDeletecampaignModal(true);
    } else {
      setNotificationMessage("Deletion not allowed ");
      setOpenSnackbar(true);
    }
  };

  // const handleBulkcampaignDeleteClick = async (params) => {
  //   if (!params || params.length === 0 || !Array.isArray(params)) {
  //     console.error("Invalid or empty params:", params);
  //     return;
  //   }

  //   try {
  //     const response = await fetch(getFullApiUrl(GET_ALL_CAMPAIGNS));
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const allcampaigns = await response.json();

  //     const campaignDetails = params.map((campaignId) => {
  //       const campaigns = allcampaigns.find(
  //         (u) => u.campaign_id === campaignId
  //       );
  //       console.log("ss", campaigns);
  //       return campaigns
  //         ? `${campaigns.campaign_name} (${campaigns.description})`
  //         : "";
  //     });
  //     console.log("detail", campaignDetails);

  //     setselectedcampaignsForBulkDelete(params);
  //     setselectedcampaignsForDelete(campaignDetails);
  //     setShowBulkDeleteModal(true);
  //   } catch (error) {
  //     console.error("Error fetching campaign data:", error);
  //   }
  // };

  // const handleBulkDeleteConfirm = async () => {
  //   console.log(selectedcampaignsForBulkDelete);
  //   try {
  //     if (
  //       !selectedcampaignsForBulkDelete ||
  //       !Array.isArray(selectedcampaignsForBulkDelete)
  //     ) {
  //       console.error(
  //         "Invalid or empty params:",
  //         selectedcampaignsForBulkDelete
  //       );
  //       return;
  //     } else {
  //       await handleBulkDelete(selectedcampaignsForBulkDelete);
  //       // Close modal and refresh campaign list
  //       setShowBulkDeleteModal(false);
  //       fetchData();
  //     }
  //   } catch (error) {
  //     console.error("Error deleting campaigns in bulk:", error.message);
  //   }
  // };


  const handleDeletecampaignConfirm = async () => {
    try {
      await Promise.all(
        selectedcampaignsForDelete.map((campaign) =>
          handleDeletecampaign(campaign.campaign_id)
        )
      );
      setselectedcampaignsForDelete([]);
      setshowDeletecampaignModal(false);
      fetchData(); // Refresh campaign list after deletion
    } catch (error) {
      console.error("Error deleting campaigns:", error.message);
    }
  };


  // const handleBulkDelete = async (selected_campaign) => {
  //   set_selected_campaign(selected_campaign);
  //   try {
  //     // Iterate over selected campaigns and delete each one
  //     for (let campaignId of selected_campaign) {
  //       await handleDeletecampaign(campaignId);
  //     }
  //     console.log("Bulk delete successful");
  //     // After bulk deletion, refresh the campaign list
  //     fetchData();
  //   } catch (error) {
  //     console.error("Error deleting campaigns in bulk:", error.message);
  //   }
  // };

  const handleDeletecampaign = async (campaignId) => {
    console.log("delete click");
    console.log(campaignId);
    try {
      const response = await fetch(getFullApiUrl(DELETE_CAMPAIGN), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ campaign_id: campaignId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete campaign");
      }

      console.log("campaign deleted successfully:", campaignId);
      // Refresh campaign list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting campaign:", error.message);
    }
  };
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">MyCampaigns</div>
          </Breadcrumbs>

          <div className="admin_page_heading">Campaigns</div>
          <div>
            {errorFetchMassage ? (
              <div className="alert_massage_container">
                <Alert severity="error">{errorFetchMassage}</Alert>
              </div>
            ) : loadingTableData ? (
              <div className="loading_spinner">
                <CircularProgress />
              </div>
            ) : (
              <TableWithPagination
                data={campaign_data}
                file_name={"campaign_of_user_data"}
                fields={[
                  {
                    type: "action",
                    field: "actions",
                    headerName: "Actions",
                    width: 85,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          <div>&nbsp;&nbsp;</div>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEditcampaign(params)}
                            >
                              <LiaEdit className="table_action_icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                handleIndividualDeleteClick(params)
                              }
                            >
                              <DeleteOutlineIcon className="table_action_icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      );
                    },
                  },

                  {
                    type: "field",
                    field: "campaign_name",
                    headerName: "Campaign",
                    search_type: "text_search",
                    minWidth: 220,
                    width: 260,
                    maxWidth: 1500,
                  },
                  {
                    type: "action",
                    field: "campaign_action",
                    headerName: "",
                    width: 45,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          {/* <div>&nbsp;</div> */}
                          <Tooltip title="Open Campaign">
                            <IconButton>
                              <RouterLink
                                to={handleOpenCampaignSection(params)}
                              >
                                <MdCampaign className="table_action_icon" />
                              </RouterLink>
                            </IconButton>
                          </Tooltip>

                          {/* <Tooltip title="Open Campaign Testing">
                            <IconButton>
                              <RouterLink
                                to={handleOpenCampaignTestingSection(params)}
                              >
                                <GrTest className="table_action_icon" />
                              </RouterLink>
                            </IconButton>
                          </Tooltip> */}
                        </div>
                      );
                    },
                  },
                  {
                    type: "field",
                    field: "workspace_name",
                    headerName: "Workspace",
                    search_type: "text_search",
                    minWidth: 220,
                    width: 260,
                    maxWidth: 1500,
                  },
                  {
                    type: "action",
                    field: "workspace_info_action",
                    headerName: "",
                    width: 50,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          <div>&nbsp;&nbsp;&nbsp;</div>
                          <Tooltip title="Workspace Info">
                            <IconButton>
                              <RouterLink
                                to={handleOpenWorkspaceSection(params)}
                              >
                                <MdOutlineWorkOutline className="table_action_icon" />
                              </RouterLink>
                            </IconButton>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                  {
                    type: "field",
                    field: "team_name",
                    headerName: "Team",
                    search_type: "text_search",
                    minWidth: 220,
                    width: 260,
                    maxWidth: 1500,
                  },
                  {
                    type: "action",
                    field: "team_info_action",
                    headerName: "",
                    width: 30,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          {/* <div>&nbsp;&nbsp;&nbsp;</div> */}
                          <Tooltip title="Team Info">
                            <IconButton>
                              <RouterLink
                                to={handleOpenTeamInfoSection(params)}
                              >
                                <PeopleAltIcon className="table_action_icon" />
                              </RouterLink>
                            </IconButton>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                  {
                    type: "field",
                    field: "member_designation",
                    headerName: "Designation",
                    search_type: "designation_search",
                    minWidth: 200,
                    width: 210,
                    maxWidth: 800,
                  },
                  {
                    type: "action",
                    field: "in_charge_action",
                    search_type: "text_search",
                    headerName: "",
                    width: 50,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          <div>&nbsp;&nbsp;</div>
                          <Tooltip title="Update In Charge Of Campaign">
                            <IconButton
                              onClick={() => handleUpdateInChargeOfModalOpen(params)}
                            >
                              <ManageAccountsIcon className="table_action_icon" />
                            </IconButton>
                          </Tooltip>

                        </div>
                      );
                    },
                  },

                  {
                    type: "field",
                    field: "in_charge_of",
                    headerName: "In Charge",
                    search_type: "in_charge_of",
                    search_type: "text_search",

                    minWidth: 180,
                    width: 200,
                    maxWidth: 800,
                  },

                  {
                    type: "field",
                    field: "created_by",
                    headerName: "Created By",
                    search_type: "created_by",
                    minWidth: 200,
                    width: 250,
                    maxWidth: 800,
                  },
                  {
                    type: "field",
                    field: "credit_points_week_limit",
                    headerName: `Credits Week Limit`,
                    search_type: "number_search",
                    minWidth: 230,
                    width: 260,
                    maxWidth: 1000,
                  },
                  {
                    type: "field",
                    field: "credit_points",
                    headerName: "Owner's Credits Left",
                    search_type: "number_search",
                    minWidth: 230,
                    width: 260,
                    maxWidth: 1000,
                  },
                  {
                    type: "field",
                    field: "created_at",
                    headerName: "Created At",
                    search_type: "date_search",
                    minWidth: 200,
                    width: 250,
                    maxWidth: 1500,
                  },
                  {
                    type: "field",
                    field: "last_used",
                    headerName: "Last Used",
                    search_type: "date_search",
                    minWidth: 200,
                    width: 250,
                    maxWidth: 1500,
                  },
                  {
                    type: "id",
                    field: "campaign_id",
                    headerName: "",
                    minWidth: 1,
                    width: 1,
                    maxWidth: 380,
                  },
                ]}
                bulk_actions={[
                  {
                    action: "add",
                    renderCell: (params) => {
                      return (
                        <Tooltip title="Add Camoaign">
                          <div
                            className="bulk_classify_button download_icons"
                            onClick={handleAddOpen}
                          >
                            <IoIosAddCircleOutline />
                          </div>
                        </Tooltip>
                      );
                    },
                  },

                ]}
                tableDisplay={"Table"}
              />
            )}


            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={notificationMessage}
            />
            <Modal
              open={showAddcampaignForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  {isLoading ? ( // Check for loading state
                    <div style={{ padding: "20px", textAlign: "center" }}>
                      <Typography variant="h6">Loading ...</Typography>
                    </div>
                  ) : (
                    <>
                      {workspace_data.length > 0 && (
                        <div className="modal_header_container">
                          <div className="modal_header_text">Add New Campaign</div>
                          <div className="modal_header_close_icon">
                            <IconButton onClick={handleAddClose}>
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                      <div className="modal_content_container">
                        {workspace_data.length === 0 ? (
                          // Display if no workspaces are found
                          <div style={{ padding: "20px", textAlign: "center" }}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                              🚫 Sorry, you currently don't have any workspaces available to create a new campaign.
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                              Please go to{" "}
                              <a href="/my_workspaces" style={{ color: "blue", textDecoration: "underline" }}>
                                My Workspaces
                              </a>{" "}
                              to create a new workspace.
                            </Typography>
                            <Typography variant="body1">
                              Alternatively, visit{" "}
                              <a href="/my_teams" style={{ color: "blue", textDecoration: "underline" }}>
                                My Teams
                              </a>{" "}
                              to create a new team, then add a workspace within that team.
                            </Typography>
                          </div>
                        ) : (
                          // Display if workspaces are available
                          <>
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": {
                                  width: "80%",
                                  marginLeft: "10%",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                id="campaign_name"
                                label="Campaign Name"
                                variant="standard"
                                name="campaign_name"
                                value={new_campaign_data.campaign_name}
                                onChange={handleNewInputChange}
                                className="modal_form_input"
                              />
                            </Box>

                            {/* Search Workspace Autocomplete */}
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": {
                                  width: "80%",
                                  marginLeft: "10%",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <Autocomplete
                                id="search_workspace"
                                options={searchWorkspaceResults}
                                getOptionLabel={(option) => `${option.workspace_name}`}
                                onChange={(event, newValue) => handleWorkspaceSelect(newValue)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search Workspace"
                                    variant="standard"
                                    onChange={handleSearchWorkspaceInputChange}
                                    className="modal_form_input"
                                  />
                                )}
                              />
                            </Box>
                            {selectedWorkspace && (
                              <div className="modal_text">
                                <Typography>
                                  Selected Workspace: {selectedWorkspace.workspace_name}
                                </Typography>
                              </div>
                            )}
                            <Box
                              component="form"
                              sx={{
                                "& > :not(style)": {
                                  width: "80%",
                                  marginLeft: "10%",
                                },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                id="campaign_description"
                                label="Description"
                                variant="standard"
                                name="campaign_description"
                                value={new_campaign_data.campaign_description}
                                onChange={handleNewInputChange}
                                className="modal_form_input"
                                multiline
                                rows={4}
                              />
                            </Box>
                            {errorMessage && (
                              <div
                                style={{
                                  color: "red",
                                  paddingLeft: "58px",
                                  textAlign: "left",
                                }}
                              >
                                {errorMessage}
                              </div>
                            )}
                            <button onClick={handleAddcampaignSubmit} className="add-user-btn">
                              Add{" "}
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Box>
            </Modal>


            <Modal
              open={showEditcampaignModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header_container">
                    <div className="modal_header_text">Update campaign</div>
                    <div className="modal_header_close_icon">
                      <IconButton onClick={handleEditClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div className="modal_content_container">

                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {

                          width: "80%",
                          marginLeft: "10%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="campaign_name"
                        label="campaign Name"
                        variant="standard"
                        name="campaign_name"
                        value={
                          selectedcampaign ? selectedcampaign.campaign_name : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                      />
                    </Box>
                  </div>


                  <button
                    onClick={handleEditcampaignData}
                    className="add-user-btn"
                  >
                    Update
                  </button>
                </div>
              </Box>
            </Modal>
            <Modal
              open={showDeletecampaignModal}
              onClose={handleDeletecampaignModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header_container">
                    <div className="modal_header_text">Delete campaign(s)</div>
                    <div className="modal_header_close_icon">
                      <IconButton onClick={handleDeletecampaignModalClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                  {selectedcampaignsForDelete.map((campaign) => (
                    <div key={campaign.campaign_id}>
                      <div className="modal_text">
                        campaign: {campaign.campaign_name}
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected campaign(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleDeletecampaignConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>


            <Modal
              open={show_update_incharge_of_campaign_modal}
              onClose={handleUpdateInChargeOfModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header">Update Incharge Of This Campaign</div>

                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {
                        m: 2,
                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Autocomplete
                      id="search_user"
                      options={searchResultsToUpdateInCharge}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name} (${option.user_name}) - ${option.email_id}`
                      }
                      onChange={(event, newValue) => handleUserSelect(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search User"
                          variant="standard"
                          onChange={handleSearchInputChange}
                          className="modal_form_input"
                        />
                      )}
                    />
                  </Box>
                  {selected_user_to_update_incharge_of && (
                    <div className="modal_text">
                      <Typography>
                        Selected User: {selected_user_to_update_incharge_of.first_name}
                      </Typography>
                    </div>
                  )}




                  <button
                    onClick={handleUpdateInChargeOfData}
                    className="add-user-btn"
                  >
                    Update In Charge Of The Campaign
                  </button>
                </div>
              </Box>
            </Modal>

            <StatusModalComponent
              isOpen={modalState.isOpen}
              type={modalState.type}
              message={modalState.message}
              onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignListOfUser;
