export const LAMBDA_FUNCTION_TO_SAVE_USER_UPLOAD_IDENTIFIY_PARAGRAPH = 'https://vfu7qmcoaxvpey7e7ph6kffpku0msaxy.lambda-url.us-east-2.on.aws/'
export const LAMBDA_FUNCTION_TO_GET_FILTERED_IDENTIFIY_PARAGRAPH = 'https://6sbnto7zfcwtiwrobqy7hrspfu0pdaeb.lambda-url.us-east-2.on.aws/'


export const LAMBDA_FUNCTION_TO_GET_INDIVIDUAL_BLOG_DATA = 'https://i3b5gsto3h67pnf6qgevkzx4mi0egvks.lambda-url.us-east-2.on.aws/' // get_blog_data_by_campaign_url

// export const LAMBDA_FUNCTION_TO_GET_INDIVIDUAL_BLOG_DATA = 'https://irnfk6zfir6te7v7vohxxdsdjy0fxlkf.lambda-url.us-east-2.on.aws/' // testing6




export const LAMBDA_FUNCTION_TO_SAVE_INDIVIDUAL_BLOG_DATA = 'https://wvniikr4z2y742rdbm3lv66tr40karjd.lambda-url.us-east-2.on.aws/' // save_blog_data_by_campaign_url

// export const LAMBDA_FUNCTION_TO_SAVE_INDIVIDUAL_BLOG_DATA = 'https://egwe7yrq5kct5nx5qilzfrvscu0bdawe.lambda-url.us-east-2.on.aws/' // testng 7 




export const LAMBDA_FUNCTION_TO_SCRAPE_BLOG_DATA = 'https://q7u4itc7ktomt7ym3yzm2pia5y0qiugh.lambda-url.us-east-2.on.aws/' //process_blog_page_scrape
// export const LAMBDA_FUNCTION_TO_SCRAPE_BLOG_DATA = "https://nx6ebwd5zpax2apxfsrmgtheya0rhaur.lambda-url.us-east-2.on.aws/"; // testing4


// export const LAMBDA_FUNCTION_FOR_BULK_PROCESS = "https://jwlvhvbzfuzdp6edkddfl2mgsm0wrhqe.lambda-url.us-east-2.on.aws/" // testing5

export const LAMBDA_FUNCTION_FOR_BULK_PROCESS ="https://xopl76khyeekinvmhkjicssx740lqkei.lambda-url.us-east-2.on.aws/" // bulk_process_scoring



// export const LAMBDA_FUNCTION_TO_GET_BLOG_DATA_BY_RANGE = "https://dgjly5g7p5xkd3itouw7gqkcya0xnqms.lambda-url.us-east-2.on.aws/"; // testing2
export const LAMBDA_FUNCTION_TO_GET_BLOG_DATA_BY_RANGE = "https://n2be3p6rfgsw5soqoqvx42wory0kwkaj.lambda-url.us-east-2.on.aws/";  // get_blog_url_list_by_range


export const LAMBDA_FUNCTION_TO_GET_BLOG_DATA_LENGTH_AND_FILE_NAME = "https://bampo6yu7dook5voh4goc5ueui0bunvi.lambda-url.us-east-2.on.aws/";  // get_blog_url_list_by_range


// export const LAMBDA_FUNCTION_TO_GET_BLOG_DATA_LENGTH_AND_FILE_NAME = "https://n2be3p6rfgsw5soqoqvx42wory0kwkaj.lambda-url.us-east-2.on.aws/";  // testing1

export const LAMBDA_FUNCTION_TO_SAVE_BLOG_DATA_BY_RANGE = "https://loeflttshyehegva533xkhfue40eanns.lambda-url.us-east-2.on.aws/";  // testing3

