import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";
import "./MyWorkspaces.css";
import React, { useState, useEffect } from "react";
import TableWithPagination from "../../utils/TableWithPagination/TableWithPagination";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import {
  getFullApiUrl,
  ADD_NEW_WORKSPACE,
  GET_ALL_WORKSPACE,
  DELETE_WORKSPACE,
  UPDATE_WORKSPACE,
  UPDATE_USER_OF_WORKSPACE,
  GET_ALL_USERS,
  GET_WORKSPACE_MEMBER_BY_USER,
  ADD_NEW_WORKSPACE_MEMBER_BY_USER,
  GET_ALL_TEAM,
  GET_WORKSPACE_MEMBER_BY_TEAM_MEMBER,
  GET_ALL_TEAM_DATA,
  GET_ALL_TEAM_BY_OWNER_ID,
} from "../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import {
  MdEdit,
  MdDelete,
  MdOutlineDeleteSweep,
  MdOutlineWorkOutline,
} from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  Alert,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link,
  MenuItem,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { IoMdCloseCircle, IoIosAddCircleOutline } from "react-icons/io";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import MyWorkspace from "../MyWorkspaceCampaigns/MyWorkspaceCampaigns";
import { IoMdOpen } from "react-icons/io";
import { MdWorkOutline } from "react-icons/md";
import { NavigateNext } from "@mui/icons-material";
import MyWorkspaceMembers from "../MyWorkspaceMembers/MyWorkspaceMembers";
import MyWorkspaceCampaigns from "../MyWorkspaceCampaigns/MyWorkspaceCampaigns";
import { MdPeopleAlt } from "react-icons/md";
import { MdCampaign } from "react-icons/md";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CloseIcon from "@mui/icons-material/Close";
import StatusModalComponent from "../../components/StatusModalComponent/StatusModalComponent";

const MyWorkspaces = ({ toggleTheme }) => {
  let { workspaceId } = useParams();

  const theme = useTheme();
  const user_id = localStorage.getItem("user_id");
  const [workspace_data, set_workspace_data] = useState([]);
  const [errorFetchMassage, setErrorFetchMassage] = useState(null);
  const [selectedworkspacesForDelete, setselectedworkspacesForDelete] =
    useState([]);
  const [selected_workspace, set_selected_workspace] = useState([]); // State to store selected workspaces for deletion
  const [showDeleteworkspaceModal, setshowDeleteworkspaceModal] =
    useState(false);
  const [selectedworkspacesForBulkDelete, setselectedworkspacesForBulkDelete] =
    useState([]);

  // State to store the selected workspace for deletion
  const [errorMessage, setErrorMessage] = useState("");
  const [user_data, set_user_data] = useState([]);
  const [team_data, set_team_data] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [searchTermteam, setSearchTermteam] = useState("");
  const [searchTeamResults, setSearchTeamResults] = useState([]);
  const handleSearchTeamInputChange = (e) => {
    setSearchTermteam(e.target.value);
    setnewworkspaceData({
      ...newworkspaceData,
      team_id: null,
    });
  };
  const handleTeamSelect = (team) => {
    if (team) {
      setnewworkspaceData({
        ...newworkspaceData,
        team_id: team.team_id,
      });
      // Set the selected user
      setSelectedTeam(team);
    }
    // Copy user_id from selectedUser to new_user_for_workspace
  };
  const [selectedworkspace, setselectedworkspace] = useState({
    workspace_id: "",
    workspace_name: "",
    start_date: "",
    deadline: "",
    status: "",
    user_id: localStorage.getItem("user_id"),
    team_id: "",
    workspace_description: "",
  });
  const [newworkspaceData, setnewworkspaceData] = useState({
    workspace_name: "",
    start_date: "",
    deadline: "",
    status: "",
    user_id: localStorage.getItem("user_id"),
    team_id: "",
    workspace_description: "",
  });

  const [new_user_for_workspace, set_new_user_for_workspace] = useState({
    workspace_id: "",
    user_id: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedworkspace({ ...selectedworkspace, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setnewworkspaceData({ ...newworkspaceData, [name]: value });
  };
  const handleNewInputChangeForUser = (e) => {
    const { name, value } = e.target;
    set_new_user_for_workspace({ ...new_user_for_workspace, [name]: value });
  };
  
  const [loadingTableData, setLoadingTableData] = useState(false);

const fetchData = async () => {
  const teams = await fetchTeamList();
  await fetchTeamListForSearch();
  await fetchworkspaceList(user_id, teams);
  };
  const fetchIinitialData = async () => {
    setLoadingTableData(true);
    const teams = await fetchTeamList();
    await fetchTeamListForSearch();
    await fetchworkspaceList(user_id, teams);
    setLoadingTableData(false);
  };
  useEffect(() => {
    fetchIinitialData();
  }, []);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });

  const handleAddworkspaceSubmit = async () => {
    if (!newworkspaceData.workspace_name || !newworkspaceData.team_id) {
      setErrorMessage(
        "Please add workspace name and select team before adding."
      );
      return;
    }
    try {
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Adding new Workspace...",
      });
      setErrorMessage("");
      setShowAddworkspaceForm(false)
      const response = await fetch(
        `${getFullApiUrl(ADD_NEW_WORKSPACE_MEMBER_BY_USER)}?user_id=${
          newworkspaceData.user_id
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newworkspaceData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add new team");
      }
      await fetchData(); 

      setModalState({
        isOpen: true,
        type: "success",
        message: "New Workspace added successfully!",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 3000);

      handleAddClose();

      // Clear the form data after successful submission
      setnewworkspaceData({
        user_id: localStorage.getItem("user_id"), // Ensure it's set before using it
        workspace_name: "",
        start_date: "",
        deadline: "",
        status: "",
        team_id: "",
        workspace_description: "",
      });

      // Assuming fetchData is defined somewhere

      console.log("New Team added successfully");
    } catch (error) {

      console.error("Error adding new Worspace:", error.message);
      setModalState({ isOpen: false, type: "", message: "" });
    }
  };
  const fetchTeamList = async () => {
    try {
      const response = await fetch(`${getFullApiUrl(GET_ALL_TEAM)}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();

      // set_team_data(teamData);
      return teamData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTeamListForSearch = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_ALL_TEAM_BY_OWNER_ID
        )}?owner_id=${localStorage.getItem("user_id")}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const teamData = await response.json();

      set_team_data(teamData);
      return teamData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchworkspaceList = async (user_id, teams) => {
    try {
      const response = await fetch(
        `${getFullApiUrl(
          GET_WORKSPACE_MEMBER_BY_TEAM_MEMBER
        )}?user_id=${user_id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const myWorkspaceListData = await response.json();
      const updatedWorkspaceListData = await myWorkspaceListData.map(
        (workspaceRow) => {
          const teamWorkspace = teams.find(
            (t) => t.team_id === workspaceRow.team_id
          );
          if (teamWorkspace) {
            workspaceRow.team_name = teamWorkspace.team_name;
          }
          return workspaceRow;
        }
      );
      set_workspace_data(myWorkspaceListData);
      setErrorFetchMassage(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setErrorFetchMassage(
      //   "There was a server error. Please contact your admin."
      // );
    }
  };
  const handleIndividualDeleteClick = (workspace) => {
    if (
      workspace.member_designation === "Owner" ||
      workspace.member_designation === "Manager" ||
      workspace.member_designation === "Lead"
    ) {
      setselectedworkspacesForDelete([workspace]); // Select only the clicked workspace
      setshowDeleteworkspaceModal(true);
    } else {
      setNotificationMessage("You don't have access to delete.");
      setOpenSnackbar(true);
    }
  };

  const handleBulkworkspaceDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_WORKSPACE));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allworkspaces = await response.json();

      const workspaceDetails = params.map((workspaceId) => {
        const workspaces = allworkspaces.find(
          (u) => u.workspace_id === workspaceId
        );
        console.log("ss", workspaces);
        return workspaces
          ? `${workspaces.workspace_name} (${workspaces.description})`
          : "";
      });
      console.log("detail", workspaceDetails);

      setselectedworkspacesForBulkDelete(params);
      setselectedworkspacesForDelete(workspaceDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching workspace data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedworkspacesForBulkDelete)
    try {
      if (
        !selectedworkspacesForBulkDelete ||
        !Array.isArray(selectedworkspacesForBulkDelete)
      ) {
        console.error(
          "Invalid or empty params:",
          selectedworkspacesForBulkDelete
        );
        return;
      } else {
        await handleBulkDelete(selectedworkspacesForBulkDelete);
        // Close modal and refresh workspace list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };
  const handleDeleteworkspaceConfirm = async () => {
    try {
      await Promise.all(
        selectedworkspacesForDelete.map((workspace) =>
          handleDeleteworkspace(workspace.workspace_id)
        )
      );
      setselectedworkspacesForDelete([]);
      setshowDeleteworkspaceModal(false);
      fetchData(); // Refresh workspace list after deletion
    } catch (error) {
      console.error("Error deleting workspaces:", error.message);
    }
  };
  const handleBulkDelete = async (selected_workspace) => {
    set_selected_workspace(selected_workspace);
    try {
      // Iterate over selected workspaces and delete each one
      for (let workspaceId of selected_workspace) {
        await handleDeleteworkspace(workspaceId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the workspace list
      fetchData();
    } catch (error) {
      console.error("Error deleting workspaces in bulk:", error.message);
    }
  };

  const handleDeleteworkspace = async (workspaceId) => {
    console.log("delete click");
    console.log(workspaceId);
    try {
      const response = await fetch(getFullApiUrl(DELETE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ workspace_id: workspaceId }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete workspace");
      }

      console.log("workspace deleted successfully:", workspaceId);
      // Refresh workspace list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting workspace:", error.message);
    }
  };
  const handleEditworkspace = (workspace) => {
    setselectedworkspace(workspace);
    setShowEditworkspaceModal(true);
  };
  const handleEditUser = (workspace) => {
    set_new_user_for_workspace({
      workspace_id: workspace.workspace_id,
      user_id: workspace.user_id,
      start_date: workspace.start_date,
      deadline: workspace.deadline,
      status: workspace.status,
    });
    setShowUserSearchModal(true);
  };
  const handleUserSearchCancel = () => {
    setSelectedUser(null);
  };

  const handleEditworkspaceData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedworkspace),
      });

      if (!response.ok) {
        throw new Error("Failed to edit workspace");
      }

      console.log("workspace edited successfully");
      setSelectedUser(null);

      setShowEditworkspaceModal(false);
      // Refresh workspace list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing workspace:", error.message);
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_user_data(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchUserList();
  }, []);
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const filteredUsers = user_data.filter((user) =>
      user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
  }, [searchTerm, user_data]);

  useEffect(() => {
    if (!searchTermteam) {
      setSearchTeamResults(team_data);
      return;
    }

    const filteredTeams = team_data.filter((team) =>
      team.team_name.toLowerCase().includes(searchTermteam.toLowerCase())
    );
    setSearchTeamResults(filteredTeams);
  }, [searchTermteam, team_data]);

  const handleEditUserForWorkspace = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_USER_OF_WORKSPACE), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspace_id: new_user_for_workspace.workspace_id,
          user_id: new_user_for_workspace.user_id,
        }),
      });
      console.log(new_user_for_workspace);

      if (!response.ok) {
        throw new Error("Failed to update user for the workspace");
      }

      // console.log("User for the workspace updated successfully");
      setShowUserSearchModal(false);
      // Refresh workspace list after updating user for the workspace
      fetchData();
    } catch (error) {
      console.error("Error updating user for the workspace:", error.message);
    }
  };
  //model open

  const [showAddworkspaceForm, setShowAddworkspaceForm] = React.useState(false);
  const [showEditworkspaceModal, setShowEditworkspaceModal] =
    React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const [showUserSeachModal, setShowUserSearchModal] = React.useState(false);
  const handleAddOpen = () => setShowAddworkspaceForm(true);
  const handleAddClose = () => {
    setSelectedTeam(null);
    setShowAddworkspaceForm(false);
    setErrorMessage("");
  };
  const handleEditOpen = () => setShowEditworkspaceModal(true);
  const handleEditClose = () => setShowEditworkspaceModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);
  const handleUserSearchClose = () => {
    setShowUserSearchModal(false);
    setSelectedUser(null);
  };

  const handleUserSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    set_new_user_for_workspace({
      ...new_user_for_workspace,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };
  const handleNewUserSelect = (user) => {
    // Copy user_id from selectedUser to newworkspaceData
    setnewworkspaceData({
      ...newworkspaceData,
      user_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };

  const handleDeleteworkspaceModalClose = () => {
    setshowDeleteworkspaceModal(false);
  };
  const [workspaceID, setWorkspaceID] = useState(null);
  const [teamID, setTeamID] = useState(null);
  const navigate = useNavigate();
  const handleOpenCampaignSection = (workspace) => {
    const workspace_id = workspace.workspace_id;
    const team_id = workspace.team_id;
    // Set the teamId state
    setWorkspaceID(workspace_id);
    setTeamID(team_id);
    console.log(workspaceID);
    console.log(teamID);

    // Navigate to the MyTeam page
    return `/my_workspace_campaign/${workspace_id}`;
    // navigate(`/my_workspace/${workspace_id}`);
  };
  const handleOpenWorkspaceMemberSection = (workspace) => {
    const workspace_id = workspace.workspace_id;
    const team_id = workspace.team_id;
    // Set the teamId state
    setWorkspaceID(workspace_id);
    setTeamID(team_id);
    console.log(workspaceID);
    console.log(teamID);

    // Navigate to the MyTeam page
    return `/my_workspace_members/${workspace_id}`;

    // navigate(`/my_workspace_members/${workspace_id}`);
  };
  const mandatoryFieldsFilled =
    newworkspaceData.workspace_name && newworkspaceData.team_id;
  const handleOpenWorkspaceSection = (workspace) => {
    const workspace_id = workspace.workspace_id;

    // Navigate to the MyTeam page
    return `/my_workspace/${workspace_id}`;
  };
  const handleOpenTeamInfoSection = (workspace) => {
    const workspace_id = workspace.team_id;

    // Navigate to the MyTeam page
    return `/my_team/${workspace_id}`;
  };

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">My Workspaces</div>
          </Breadcrumbs>

          <div className="admin_page_heading">Workspace Management</div>
          <div>{workspaceId}</div>
          
          {errorFetchMassage ? (
        <div className="alert_massage_container">
          <Alert severity="error">{errorFetchMassage}</Alert>
        </div>
      ) : loadingTableData ? (
        <div className="loading_spinner">
          <CircularProgress />
        </div>
      ) : (
        <TableWithPagination
              data={workspace_data}
             file_name={`workspace_of_user_data`}

              fields={[
                {
                  type: "action",
                  field: "actions",
                  headerName: "Actions",
                  width: 80,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <div>&nbsp;&nbsp;</div>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleEditworkspace(params)}
                          >
                            <LiaEdit className="table_action_icon" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleIndividualDeleteClick(params)}
                          >
                            <DeleteOutlineIcon className="table_action_icon" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },

                {
                  type: "field",
                  field: "workspace_name",
                  headerName: "Workspace Name",
                  search_type: "text_search",
                  minWidth: 240 ,
                  width: 300,
                  maxWidth: 1500,
                },
                {
                  type: "action",
                  field: "workspace_info",
                  headerName: "",
                  width: 115,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <Tooltip title="Workspace Info">
                          <IconButton>
                            <RouterLink to={handleOpenWorkspaceSection(params)}>
                              <MdOutlineWorkOutline className="table_action_icon" />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Open Campaign">
                          <IconButton>
                            <RouterLink to={handleOpenCampaignSection(params)}>
                              <MdCampaign className="table_action_icon" />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Open Workspace Members">
                          <IconButton>
                            <RouterLink
                              to={handleOpenWorkspaceMemberSection(params)}
                            >
                              <MdPeopleAlt className="table_action_icon" />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },

                {
                  type: "field",
                  field: "team_name",
                  headerName: "Team",
                  search_type: "text_search",
                  minWidth: 230,
                  width: 280,
                  maxWidth: 1500,
                },
                {
                  type: "action",
                  field: "team_info",
                  headerName: "",
                  width: 50,
                  renderCell: (params) => {
                    return (
                      <div className="action_icons_">
                        <div>&nbsp;&nbsp;</div>
                        <Tooltip title="Team Info">
                          <IconButton>
                            <RouterLink to={handleOpenTeamInfoSection(params)}>
                              <PeopleAltIcon className="table_action_icon" />
                            </RouterLink>
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },

                {
                  type: "field",
                  field: "member_designation",
                  headerName: "Designation",
                  search_type: "designation_search",
                  minWidth: 230,
                  width: 260,
                  maxWidth: 1500,
                },
                {
                  type: "field",
                  field: "credit_points_week_limit",
                  headerName: `Credits Week Limit`,
                  search_type: "number_search",
                  minWidth: 150,
                  width: 225,
                  maxWidth: 1000,
                },
                {
                  type: "field",
                  field: "used_credits_this_week",
                  headerName: "Used Credits this Week",
                  search_type: "number_search",
                  minWidth: 150,
                  width: 245,
                  maxWidth: 1000,
                },
                {
                  type: "field",
                  field: "credit_points_week_limit_left",
                  headerName: "Credit Limit Left this Week",
                  search_type: "number_search",
                  minWidth: 150,
                  width: 270,
                  maxWidth: 1000,
                },
                // {
                //   type: "field",
                //   field: "total_credits",
                //   headerName: "Owner's Credits Left",
                //   search_type: "number_search",
                //   minWidth: 150,
                //   width: 230,
                //   maxWidth: 1000,
                // },
                {
                  type: "field",
                  field: "created_at",
                  headerName: "Created At",
                  search_type: "date_search",
                  minWidth: 190,
                  width: 200,
                  maxWidth: 1000,
                },

                {
                  type: "id",
                  field: "workspace_id",
                  headerName: "",
                  minWidth: 1,
                  width: 1,
                  maxWidth: 360,
                },
              ]}
              bulk_actions={[
                {
                  action: "add",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Add Workspace">
                        <div
                          className="bulk_classify_button download_icons"
                          onClick={handleAddOpen}
                        >
                          <IoIosAddCircleOutline />
                        </div>
                      </Tooltip>
                    );
                  },
                },
                {
                  action: "delete_multiple",
                  renderCell: (params) => {
                    return (
                      <Tooltip title="Delete Selected Workspaces">
                        <div
                          onClick={() => handleBulkworkspaceDeleteClick(params)}
                          className="bulk_delete_button download_icons"
                        >
                          <MdOutlineDeleteSweep fontSize={30} />
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
              tableDisplay={"Table"}
            />
      )}

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={notificationMessage}
          />
          
          <Modal
            open={showAddworkspaceForm}
            onClose={handleAddClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header_container">
                  <div className="modal_header_text">Add New Workspace</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleAddClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="modal_content_container">
                  {/* Workspace Name TextField */}
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="Workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={newworkspaceData.workspace_name}
                    onChange={handleNewInputChange}
                    className="modal_form_input"
                    required
                  />
                </Box>

                {/* Search Team Autocomplete */}
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Autocomplete
                    id="search_user"
                    options={searchTeamResults}
                    getOptionLabel={(option) => `${option.team_name}`}
                    onChange={(event, newValue) => handleTeamSelect(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Team"
                        variant="standard"
                        onChange={handleSearchTeamInputChange}
                        className="modal_form_input"
                        required
                      />
                    )}
                  />
                </Box>
                {/* Conditional rendering for selected team */}
                {selectedTeam && (
                  <div className="modal_text">
                    <Typography>
                      Selected Team: {selectedTeam.team_name}
                    </Typography>
                  </div>
                )}

                {/* Other details inside Accordion */}
                <Accordion id="accordion_modal">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    
                    id="panel1a-header"
                    sx={{
                      padding: "0",
                    }}
                  >
                    <div>Add More Workspace Details</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Start Date */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="start_date"
                        helperText="Please select Start Date"
                        variant="standard"
                        name="start_date"
                        value={newworkspaceData.start_date}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                      />
                    </Box>

                    {/* Deadline */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="deadline"
                        helperText="Please select Deadline Date"
                        variant="standard"
                        name="deadline"
                        value={newworkspaceData.deadline}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                      />
                    </Box>

                    {/* Status */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="status"
                        select
                        label="Status"
                        name="status"
                        helperText="Please select your Status"
                        value={newworkspaceData.status}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                      >
                        <MenuItem value="Not Started">Not Started</MenuItem>
                        <MenuItem value="Progress">Progress</MenuItem>
                        <MenuItem value="Done">Done</MenuItem>
                      </TextField>
                    </Box>

                    {/* Workspace Description */}
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="textarea"
                        id="workspace_description"
                        label="Workspace Description"
                        variant="standard"
                        name="workspace_description"
                        value={newworkspaceData.workspace_description}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                        multiline
                        rows={3}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {errorMessage && (
                  <div
                    style={{
                      color: "red",
                      paddingLeft: "58px",
                      textAlign: "left",
                    }}
                  >
                    {errorMessage}
                  </div>
                )}
                </div>
                
                <button
                  onClick={handleAddworkspaceSubmit}
                  className="add-user-btn"
                  // disabled={!mandatoryFieldsFilled}
                >
                  Add Workspace
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showEditworkspaceModal}
            onClose={handleEditClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="add-user-form">
                <div className="modal_header_container">
                  <div className="modal_header_text">Update workspace</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleEditClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="modal_content_container">
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      
                      width: "80%",
                      marginLeft: "10%",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="workspace_name"
                    label="workspace Name"
                    variant="standard"
                    name="workspace_name"
                    value={
                      selectedworkspace ? selectedworkspace.workspace_name : ""
                    }
                    onChange={handleInputChange}
                    className="modal_form_input"
                  />
                </Box>
                <Accordion id="accordion_modal">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    s
                    id="panel1a-header"
                    sx={{
                      padding: "0",
                    }}
                  >
                    <div>Update other Workspace Details</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="workspace_description"
                        label="workspace Description"
                        variant="standard"
                        name="workspace_description"
                        value={
                          selectedworkspace
                            ? selectedworkspace.workspace_description
                            : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                        multiline
                        rows={3}
                      />
                    </Box>

                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="start_date"
                        helperText="Please select Start Date"
                        variant="standard"
                        name="start_date"
                        value={
                          selectedworkspace ? selectedworkspace.start_date : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                      />
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="date"
                        id="deadline"
                        helperText="Please select deadline Date"
                        variant="standard"
                        name="deadline"
                        value={
                          selectedworkspace ? selectedworkspace.deadline : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                      />
                    </Box>

                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": {
                          m: "10px 0",
                          width: "100%",
                        },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="status"
                        select
                        label="Status"
                        variant="standard"
                        name="status"
                        value={
                          selectedworkspace ? selectedworkspace.status : ""
                        }
                        onChange={handleInputChange}
                        className="modal_form_input"
                      >
                        <MenuItem value=" Not Started"> Not Started</MenuItem>
                        <MenuItem value="Progress"> Progress</MenuItem>
                        <MenuItem value="Done"> Done</MenuItem>
                      </TextField>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                </div>

                <button
                  onClick={handleEditworkspaceData}
                  className="add-user-btn"
                >
                  Update workspace
                </button>
              </div>
            </Box>
          </Modal>

          <Modal
            open={showDeleteworkspaceModal}
            onClose={handleDeleteworkspaceModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header_container">
                  <div className="modal_header_text">Delete workspace(s)</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleDeleteworkspaceModalClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>

                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace.workspace_id}>
                    <div className="modal_text">
                      workspace: {workspace.workspace_name}
                      {/* {workspace.duration}) */}
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleDeleteworkspaceConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/*Bulk Delete Workspaces Model */}
          <Modal
            open={showBulkDeleteModal}
            onClose={handleBulkDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="model_box">
              <div className="delete-user-form">
                <div className="modal_header">Delete workspace(s)</div>
                <div className="modal_header_container">
                  <div className="modal_header_text">Delete workspace(s)</div>
                  <div className="modal_header_close_icon">
                    <IconButton onClick={handleBulkDeleteClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                {selectedworkspacesForDelete.map((workspace) => (
                  <div key={workspace}>
                    <div className="modal_text">
                      workspace Name: {workspace}{" "}
                    </div>
                  </div>
                ))}
                <div className="modal_text">
                  Are you sure you want to delete selected workspace(s)?
                </div>
                <div className="form-group">
                  <Button onClick={handleBulkDeleteConfirm} color="error">
                    <MdDelete className="formIcons" />
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>

          <StatusModalComponent
            isOpen={modalState.isOpen}
            type={modalState.type}
            message={modalState.message}
            onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
          />
        </div>
      </div>
    </div>
  );
};

export default MyWorkspaces;
