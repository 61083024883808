import React from "react";
import { Modal, CircularProgress } from "@mui/material";
import "./StatusModalComponent.css";
import loadingGif from "../../assets/images/loader_gif.gif";

const StatusModalComponent = ({ isOpen, type, message, onClose }) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <div className="status_model_box">
      {type === "loading" ? (
        <div className="status_modal_container loading">
          <div className="header">
            <span>Status</span>
            <span className="icon-sticker">⏳</span>
            {/* <img src={loadingGif} alt="Loading..." className="icon-gif" /> */}
          </div>
          <div className="main_content">
            <div>{message || "Loading..."}</div>

            {/* <CircularProgress color="inherit" /> */}
          </div>
        </div>
      ) : type === "success" ? (
        <div className="status_modal_container success">
          <div className="header">
            <span>Status</span>
            <span className="icon-sticker">✅</span>
          </div>
          <div className="main_content">
            <div>{message || "Action completed successfully!"}</div>
          </div>
        </div>
      ) : null}
    </div>
  </Modal>
);

export default StatusModalComponent;
