import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Typography,
  Breadcrumbs,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import {
  CHECK_USER_CAMPAIGN_ACCESS,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_RECENT_IN_CHARGE_OF_CAMPAIGN,
  getFullApiUrl,
} from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import { NavigateNext } from "@mui/icons-material";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import Header from "../common/Header/Header";
import "./FindLinkInsertPage.css";
import { FaRegCircleQuestion } from "react-icons/fa6";

const FindLinkInsertPage = ({ toggleTheme }) => {
  const { campaignId } = useParams();

  const navigate = useNavigate();
  const goto = (page) => {
    navigate(page); // Navigate to the specified page
  };
  useEffect(() => {
    getCampaignDetails();
    getRectInChangeOfCampaignList();
  }, []);
  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const [get_recent_in_charge_data, set_get_recent_in_charge_data] = useState(
    []
  );

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getRectInChangeOfCampaignList = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_RECENT_IN_CHARGE_OF_CAMPAIGN)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      set_get_recent_in_charge_data(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const [domainOpportunities, setDomainOpportunities] = React.useState("");
  const [alternativeLinks, setAlternativeLinks] = React.useState("");
  const [targetKeyword, setTargetKeyword] = React.useState("yes");
  const [pageTypes, setPageTypes] = React.useState({});
  const [similarityScore, setSimilarityScore] = React.useState([50, 90]);
  const [activeTab, setActiveTab] = useState(0);
  const handleSliderChange = (event, newValue) => {
    setSimilarityScore(newValue);
  };

  const handlePageTypeChange = (event) => {
    setPageTypes({ ...pageTypes, [event.target.name]: event.target.checked });
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [activeIndex, setActiveIndex] = useState(1); // Set default active button

  const buttons = [
    { range: '33 - 70', label: 'Relevant' },
    { range: '50 - 90', label: 'Similar' },
    { range: '70 - 100', label: 'Same' },
    { range: '0 - 100', label: 'All' }
  ];
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">{campaignData.campaign_name}</div>
          </Breadcrumbs>

          <div className="find_link_page_contaainer">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="tabs"
            >
              <Tab label="Finalize on Existing Data" />
              <Tab label="Finalize Uploaded Data" />
            </Tabs>

            {activeTab === 0 && (
              <div className="tab_content_container">
                <div className="header_with_buttons_container">
                  <div className="header_container">
                    <div className="header">Find Link Insert Opportunities</div>
                    <div className="sub_header">
                      Matches your target URLs with relevent links inserts
                    </div>
                  </div>
                  <div>
                    <div class="button_container">
                      <button class="button reset_button">RESET</button>
                      <button class="button proceed_button">PROCEED</button>
                    </div>
                  </div>
                </div>

                {/* Adjust Settings Section */}
                <div className="main_tab_content_container">
                  <div className="settings_and_keywords_filter_container">
                    <div className="settings_section">
                      <div className="header">Adjust Settings</div>
                      <div className="form_container">
                        <div className="form_item">
                          <div className="form_heading">
                            Number of opportunities per domain :{" "}
                          </div>
                          <FormControl fullWidth variant="outlined">
                            {/* <InputLabel>
                              Number of opportunities per domain
                            </InputLabel> */}
                            <Select
                              value={domainOpportunities}
                              onChange={(e) =>
                                setDomainOpportunities(e.target.value)
                              }
                              label="Number of opportunities per domain"
                            >
                              <MenuItem value="all">
                                All (Create an opportunity for every URL)
                              </MenuItem>
                              <MenuItem value="limited">
                                Limited (Create multiple opportunities)
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>

                        <div className="form_item">
                          <div className="form_heading">
                            Number of alternative links per opportunity :{" "}
                          </div>

                          <FormControl fullWidth variant="outlined">
                            <InputLabel></InputLabel>
                            <Select
                              value={alternativeLinks}
                              onChange={(e) =>
                                setAlternativeLinks(e.target.value)
                              }
                              label="Number of alternative links per opportunity"
                            >
                              <MenuItem value="unavailable">
                                Unavailable for multiple opportunities
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="keyword_filter">
                      <div className="header">
                        Must the Prospect Page Contain Your Exact Target
                        Keyword?
                      </div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={targetKeyword}
                          onChange={(e) => setTargetKeyword(e.target.value)}
                          row
                          sx={{
                            flexDirection: "column",
                          }}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label={
                              <div>
                                Yes <FaRegCircleQuestion />
                              </div>
                            }
                          />
                          <FormControlLabel
                            value="prefer"
                            control={<Radio />}
                            label={
                              <>
                                No, but Prefer Pages that Do <FaRegCircleQuestion />
                              </>
                            }
                          />
                          <FormControlLabel
                            value="noFactor"
                            control={<Radio />}
                            label={
                              <>
                                Don’t Factor This In When Finding Opportunities <FaRegCircleQuestion />
                              </>
                            }
                          />

                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <Divider sx={{ bgcolor: "#3e3e4d" }} />

                  <div className="page-type-config">
                    <div className="page_type_confiq_conainer">
                      <div className="form_container">
                        <div className="header_container">
                          <div className="header">Page Type Configuration</div>
                          <div className="sub_header">
                            To customize your selection, uncheck any page types
                            you wish to caclude,
                          </div>
                          <div className="sub_header">
                            If you want to include URLs that do not have any
                            data, make sure to select the [No Data] opcion
                          </div>
                        </div>
                        <div className="handle_url_and_search_bar_container">
                          <FormControl
                            component="fieldset"
                            style={{ marginTop: "1rem" }}
                          >
                            <FormLabel component="legend">
                              Handling URLs with No Page Type
                            </FormLabel>
                            <RadioGroup
                              row
                              defaultValue="include"
                              sx={{
                                flexDirection: "column",
                              }}
                            >
                              <FormControlLabel
                                value="include"
                                control={<Radio />}
                                label="Include URLs with no page type"
                              />
                              <FormControlLabel
                                value="exclude"
                                control={<Radio />}
                                label="Exclude URLs with no page type"
                              />
                            </RadioGroup>
                          </FormControl>
                          <div className="search_bar_content">
                            <div className="search_bar_container">
                              <span className="search_icon">🔍</span>
                              <input
                                type="text"
                                className="search_input"
                                placeholder="Search page type"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="check_box_container">
                          <FormGroup row className="form_group_grid">
                            {Array.from({ length: 20 }).map((_, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={
                                      pageTypes[`blogPost${index}`] || false
                                    }
                                    onChange={handlePageTypeChange}
                                    name={`blogPost${index}`}
                                  />
                                }
                                label="Blog Posts"
                              />
                            ))}
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="opportunity_match">
                    <div className="header_container">
                      <div className="header">Refine Your Opportunity Match Criteria</div>
                      <div className="sub_header">
                        Adjust the similarity score to find pages that match your
                        target.
                      </div>
                    </div>

                    <div className="slider_container">
                      <div className="heading">Preset Similiarity Score</div>
                      <div className="filter-buttons">
                        {buttons.map((button, index) => (
                          <button
                            key={index}
                            className={`filter-button ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => setActiveIndex(index)}
                          >
                            <div>{button.range}</div>
                            <div>{button.label}</div>
                          </button>
                        ))}
                      </div>
                      <Slider
                        value={similarityScore}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        style={{ color: "#90CAF9" }}
                      />

                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 1 && (
              <div>
                <Typography variant="h5" mb={2}>
                  Finalize Uploaded Data
                </Typography>
                {/* Content for the second tab will be added here later */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindLinkInsertPage;
