import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineStar,
  AiOutlineHeart,
  AiOutlineThunderbolt,
  AiOutlineCar,
  AiOutlineCrown,
} from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./FloatingMenuBar.css";
import flow_icon from "../../../assets/icons/flow_icon.png";
import flow_icon_dark from "../../../assets/icons/flow_icon_dark.png";
import { FaInternetExplorer, FaProjectDiagram } from "react-icons/fa";
import { IoBagCheckOutline } from "react-icons/io5";
import { MdBlockFlipped, MdOutlineFactCheck, MdScreenSearchDesktop } from "react-icons/md";
import { BiScreenshot } from "react-icons/bi";



const FloatingMenuBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [campaignId, setCampaignId] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const campaignIdIndex = pathParts.indexOf("my_campaign") + 1;
    if (campaignIdIndex > 0 && campaignIdIndex < pathParts.length) {
      setCampaignId(pathParts[campaignIdIndex]);
    } else {
      setCampaignId(null);
    }
  }, [location.pathname]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const menuItems = [
    {
        id: 1,
        icon: <AiOutlineHome className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname === `/my_campaign/${campaignId}`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Link Insertion Diagram
            </div>
        ),
        link: `/my_campaign/${campaignId}`,
    },
    {
        id: 2,
        icon: <AiOutlineMail className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname === `/my_campaign/${campaignId}/client_urls`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Add Target URLs
            </div>
        ),
        link: `/my_campaign/${campaignId}/client_urls`,
    },
    {
        id: 3,
        icon: <AiOutlineStar className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname ===
                        `/my_campaign/${campaignId}/check_active_bloggers_using_serper`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                {/* Check Active Bloggers using Serper */}
                Scrape Prospect URLs From Google
            </div>
        ),
        link: `/my_campaign/${campaignId}/check_active_bloggers_using_serper`,
    },
    // {
    //     id: 4,
    //     icon: <FaInternetExplorer className="floating_menu_icon" />,
    //     name: (
    //         <div
    //             className={
    //                 location.pathname ===
    //                     `/my_campaign/${campaignId}/explore_blogger_page`
    //                     ? "floating_menu_text active"
    //                     : "floating_menu_text"
    //             }
    //         >
    //             Explore a Blogger Website
    //         </div>
    //     ),
    //     link: `/my_campaign/${campaignId}/explore_blogger_page`,
    // },
    {
        id: 5,
        icon: <MdBlockFlipped className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname ===
                        `/my_campaign/${campaignId}/black_list`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Black List
            </div>
        ),
        link: `/my_campaign/${campaignId}/black_list`,
    },
    {
        id: 6,
        icon: <AiOutlineHeart className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname === `/my_campaign/${campaignId}/blog_urls`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Prepare Prospects for Analysis 
            </div>
        ),
        link: `/my_campaign/${campaignId}/blog_urls`,
    },
    {
        id: 7,
        icon: <AiOutlineThunderbolt className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname === `/my_campaign/${campaignId}/bulk_process`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Match Prospects with Target URLs
            </div>
        ),
        link: `/my_campaign/${campaignId}/bulk_process`,
    },
    {
        id: 8,
        icon: <IoBagCheckOutline className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname ===
                        `/my_campaign/${campaignId}/classify_result_page`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Classify Prospect Page Types
            </div>
        ),
        link: `/my_campaign/${campaignId}/classify_result_page`,
    },
    {
        id: 9,
        icon: <MdOutlineFactCheck className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname ===
                        `/my_campaign/${campaignId}/page_quality_check`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Page Quality Check
            </div>
        ),
        link: `/my_campaign/${campaignId}/page_quality_check`,
    },
    {
        id: 10,
        icon: <AiOutlineCar className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname ===
                        `/my_campaign/${campaignId}/identify_paragraphs`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Inspect Matches Individually
            </div>
        ),
        link: `/my_campaign/${campaignId}/identify_paragraphs`,
    },
    {
        id: 11,
        icon: <BiScreenshot className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname ===
                        `/my_campaign/${campaignId}/screenshot`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Scrrenshot
            </div>
        ),
        link: `/my_campaign/${campaignId}/screenshot`,
    },
    {
        id: 12,
        icon: <AiOutlineCrown className="floating_menu_icon" />,
        name: (
            <div
                className={
                    location.pathname ===
                        `/my_campaign/${campaignId}/link_insertion_suggestion`
                        ? "floating_menu_text active"
                        : "floating_menu_text"
                }
            >
                Link Insertion Suggestion
            </div>
        ),
        link: `/my_campaign/${campaignId}/link_insertion_suggestion`,
    },
];

  const handleMenuItemClick = (link) => {
    handleClose();
    navigate(link);
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        id="speed_dial_flow"
        icon={
          <div
            style={{
              width: 38,
              height: 38,
              background: "#FFF",
              borderRadius: "50%",
              padding: "10px",
              backgroundImage: `url(${flow_icon})`,
              backgroundSize: "67%", // Adjust background size to decrease the image size
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="down"
        className="speed_dial_button"
      >
        <Backdrop open={open} className={open ? "backdrop open" : "backdrop"} sx={{
        //   zIndex: 10,
          backgroundColor: "transparent", // Fully transparent backdrop
        }} />
        {menuItems.map((menuItem) => (
          <SpeedDialAction
            key={menuItem.id}
            icon={menuItem.icon}
            tooltipTitle={menuItem.name}
            tooltipOpen
            onClick={() => handleMenuItemClick(menuItem.link)}
            sx={{
              whiteSpace: "nowrap",
            }}
            className={
              location.pathname === menuItem.link
                ? "floating_menu_icon active"
                : "floating_menu_icon"
            }
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default FloatingMenuBar;
