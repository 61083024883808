import React, { useState, useEffect } from "react";
import { Link, Navigate, navigate, useNavigate } from "react-router-dom";
import TableWithPagination from "../../utils/TableWithPagination/TableWithPagination";
import {
  ADD_NEW_TEAM_BY_OWNER_ID,
  DELETE_TEAM,
  UPDATE_TEAM,
  GET_ALL_USERS,
  getFullApiUrl,
  GET_ALL_TEAM_BY_OWNER_ID,
  GET_ALL_TEAM,
  GET_USER_INFO_BY_ID,
  GET_ALL_TEAMS_OF_USER,
} from "../../utils/apiPath";
import { RiAddLine, RiDeleteBack2Fill } from "react-icons/ri";
import { MdEdit, MdDelete, MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Autocomplete,
  Breadcrumbs,
  Link as MuiLink,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { IoIosAddCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { LiaExpeditedssl } from "react-icons/lia";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditNoteIcon from "@mui/icons-material/";
import { LiaEdit } from "react-icons/lia";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import Header from "../common/Header/Header";

import { NavigateNext } from "@mui/icons-material";
import { BsPersonWorkspace } from "react-icons/bs";
import { CgWorkAlt } from "react-icons/cg";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CloseIcon from "@mui/icons-material/Close";
import StatusModalComponent from "../../components/StatusModalComponent/StatusModalComponent";

const MyTeams = ({ toggleTheme }) => {
  const theme = useTheme();
  const [team_data, set_team_data] = useState([]);
  const [errorFetchMassage, setErrorFetchMassage] = useState(null);
  const [selected_team, set_selected_team] = useState([]);
  const [selectedTeamsForDelete, setselectedTeamsForDelete] = useState([]); // State to store selected teams for deletion
  const [showDeleteTeamModal, setshowDeleteTeamModal] = useState(false);
  const [selectedTeamsForBulkDelete, setselectedTeamsForBulkDelete] = useState(
    []
  );
  const owner_id = localStorage.getItem("user_id");

  // State to store the selected team for deletion

  const [selectedTeam, setselectedTeam] = useState({
    team_id: "",
    team_name: "",
    team_description: "",
    owner_id: "",
  });
  const [newTeamData, setnewTeamData] = useState({
    owner_id: localStorage.getItem("user_id"),
    team_name: "",
    team_description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setselectedTeam({ ...selectedTeam, [name]: value });
  };
  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setnewTeamData({ ...newTeamData, [name]: value });
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  //   add team...
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState("")
  const handleAddTeamSubmit = async () => {
    if (!newTeamData.team_name || !newTeamData.credit_points_week_limit) {
      setFormErrors({
        team_name: !newTeamData.team_name ? "Team Name is required" : "",
        credit_points_week_limit: !newTeamData.credit_points_week_limit
          ? "Credit Points Week Limit is required"
          : "",
      });
      return; // Stop submission if required fields are missing
    }

    try {
      setShowAddteamForm(false)
      setModalState({
        isOpen: true,
        type: "loading",
        message: "Adding new Team...",
      });
      const response = await fetch(
        `${getFullApiUrl(ADD_NEW_TEAM_BY_OWNER_ID)}?owner_id=${newTeamData.owner_id
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newTeamData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add new team");
      }

      // Clear the form data and error messages after successful submission
      setnewTeamData({
        owner_id: localStorage.getItem("user_id"), // Ensure it's set before using it
        team_name: "",
        team_description: "",
        credit_points_week_limit: "",
      });
      setFormErrors({});
      setModalState({
        isOpen: true,
        type: "success",
        message: "New Team added successfully!",
      });

      setTimeout(() => {
        setModalState({ isOpen: false, type: "", message: "" });
      }, 3000);

      fetchData(); // Assuming fetchData is defined somewhere

      console.log("New Team added successfully");
    } catch (error) {
      console.error("Error adding new Team:", error.message);
      setModalState({ isOpen: false, type: "", message: "" });
    }
  };
  // const fetchData = async () => {
  //   await fetchteamList(owner_id);
  // };
  const [loadingTableData, setLoadingTableData] = useState(false);

  const fetchData = async () => {
    await fetchteamList(owner_id);
  };
  const fetchIinitialData = async () => {
    setLoadingTableData(true);
    await fetchteamList(owner_id);
    setLoadingTableData(false);
  };
  useEffect(() => {
    fetchIinitialData();
  }, []);
  // list.....

  const fetchteamList = async (ownerId) => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_TEAMS_OF_USER), {
        method: "POST", // or 'GET' depending on your API endpoint
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: ownerId }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("data", data);
      set_team_data(data);
      setErrorFetchMassage(null); // Reset error on successful fetch
    } catch (error) {
      console.error("Error fetching data:", error);
      // setErrorFetchMassage(
      //   "There was a server error. Please contact your admin."
      // );
    }
  };

  const handleIndividualDeleteClick = (team) => {
    if (
      team.member_designation === "Owner" ||
      team.member_designation === "Manager" ||
      team.member_designation === "Lead"
    ) {
      // Display a snackbar message informing the user that deletion is not allowed
      setselectedTeamsForDelete([team]); // Select only the clicked team
      setshowDeleteTeamModal(true);
    } else {
      setNotificationMessage("Deletion not allowed ");
      setOpenSnackbar(true);
    }
  };

  const handleBulkTeamDeleteClick = async (params) => {
    if (!params || params.length === 0 || !Array.isArray(params)) {
      console.error("Invalid or empty params:", params);
      return;
    }

    try {
      const response = await fetch(getFullApiUrl(GET_ALL_TEAM));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const allteams = await response.json();

      // Extract team details including name and email
      const TeamDetails = params.map((teamId) => {
        const teams = allteams.find((u) => u.team_id === teamId);
        console.log("ss", teams);
        return teams ? `${teams.team_name} (${teams.team_description})` : "";
      });
      console.log("detail", TeamDetails);

      setselectedTeamsForBulkDelete(params);
      setselectedTeamsForDelete(TeamDetails);
      setShowBulkDeleteModal(true);
    } catch (error) {
      console.error("Error fetching team data:", error);
    }
  };

  const handleBulkDeleteConfirm = async () => {
    // console.log(selectedTeamsForBulkDelete)
    try {
      if (
        !selectedTeamsForBulkDelete ||
        !Array.isArray(selectedTeamsForBulkDelete)
      ) {
        console.error("Invalid or empty params:", selectedTeamsForBulkDelete);
        return;
      } else {
        await handleBulkDelete(selectedTeamsForBulkDelete);
        // Close modal and refresh team list
        setShowBulkDeleteModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting teams in bulk:", error.message);
    }
  };

  const handleDeleteteamConfirm = async () => {
    try {
      await Promise.all(
        selectedTeamsForDelete.map((team) => handleDeleteteam(team.team_id))
      );
      setselectedTeamsForDelete([]);
      setshowDeleteTeamModal(false);
      fetchData(); // Refresh team list after deletion
    } catch (error) {
      console.error("Error deleting teams:", error.message);
    }
  };

  const handleBulkDelete = async (selected_team) => {
    set_selected_team(selected_team);
    try {
      // Iterate over selected teams and delete each one
      for (let teamId of selected_team) {
        await handleDeleteteam(teamId);
      }
      console.log("Bulk delete successful");
      // After bulk deletion, refresh the team list
      fetchData();
    } catch (error) {
      console.error("Error deleting teams in bulk:", error.message);
    }
  };

  const handleDeleteteam = async (teamId) => {
    try {
      const response = await fetch(getFullApiUrl(DELETE_TEAM), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ team_id: teamId }),
      });

      if (!response.ok) {
        const data = await response.json();
        alert(data.error);

        throw new Error("Failed to delete team");
      }

      console.log("team deleted successfully:", teamId);
      // Refresh team list after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting team:", error.message);
    }
  };
  // Edit..

  const handleEditteam = (team) => {
    setselectedTeam(team);
    setShowEditteamModal(true);
  };

  const handleEditteamData = async () => {
    try {
      const response = await fetch(getFullApiUrl(UPDATE_TEAM), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedTeam),
      });

      if (!response.ok) {
        throw new Error("Failed to edit team");
      }

      console.log("team edited successfully");
      setShowEditteamModal(false);
      // Refresh team list after editing
      fetchData();
    } catch (error) {
      console.error("Error editing team:", error.message);
    }
  };

  //model open

  const [showAddteamForm, setShowAddteamForm] = React.useState(false);
  const [showEditteamModal, setShowEditteamModal] = React.useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = React.useState(false);
  const handleAddOpen = () => setShowAddteamForm(true);
  const handleAddClose = () => setShowAddteamForm(false);
  const handleEditOpen = () => setShowEditteamModal(true);
  const handleEditClose = () => setShowEditteamModal(false);
  const handleBulkDeleteOpen = () => setShowBulkDeleteModal(true);
  const handleBulkDeleteClose = () => setShowBulkDeleteModal(false);

  const handleDeleteteamModalClose = () => {
    setshowDeleteTeamModal(false);
  };

  // search.....
  const [selectedUser, setSelectedUser] = useState(null);
  const [user_data, set_user_data] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const fetchUserList = async () => {
    try {
      const response = await fetch(getFullApiUrl(GET_ALL_USERS));
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_user_data(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // useEffect(() => {
  //   fetchUserList();
  // }, []);
  // const handleSearchInputChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(user_data);
      return;
    }

    const filteredUsers = user_data.filter((user) =>
      user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
  }, [searchTerm, user_data]);

  const handleUserSelect = (user) => {
    // Copy user_id from selectedUser to new_user_for_workspace
    setnewTeamData({
      ...newTeamData,
      owner_id: user.user_id,
    });
    // Set the selected user
    setSelectedUser(user);
  };
  const [teamId, setTeamId] = useState(null);
  const navigate = useNavigate();

  const handleOpenTeamSection = (team) => {
    const team_id = team.team_id;

    // Navigate to the MyTeam page
    return `/my_team/${team_id}`;
  };
  const handleOpenTeamWorkspaceSection = (team) => {
    const team_id = team.team_id;
    // Set the teamId state

    return `/my_team_workspaces/${team_id}`;
  };
  const handleOpenTeamMemberSection = (team) => {
    const team_id = team.team_id;

    return `/my_team_members/${team_id}`;
  };
  // const [user_info, setUser_info] = useState({});

  // useEffect(() => {
  //   const fetchUserInfo = async () => {
  //     const userId = localStorage.getItem("user_id");
  //     try {
  //       const response = await fetch(
  //         `${getFullApiUrl(GET_USER_INFO_BY_ID)}?user_id=${userId}`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const userData = await response.json();
  //       setUser_info(userData);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchUserInfo();
  // }, []);

  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection">
          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumbs_text">
              <MuiLink
                component={Link}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </MuiLink>
            </div>
            <div className="breadcrumbs_text">
              My Teams
            </div>
          </Breadcrumbs>

          <div className="admin_page_heading">Teams Management</div>
          <div>

            {errorFetchMassage ? (
              <div className="alert_massage_container">
                <Alert severity="error">{errorFetchMassage}</Alert>
              </div>
            ) : loadingTableData ? (
              <div className="loading_spinner">
                <CircularProgress />
              </div>
            ) : (
              <TableWithPagination
                file_name={"team_data"}
                data={team_data}
                fields={[
                  {
                    type: "action",
                    field: "actions",
                    headerName: "Actions",
                    width: 90,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleEditteam(params)}>
                              <LiaEdit className="table_action_icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                handleIndividualDeleteClick(params)
                              }
                            >
                              <DeleteOutlineIcon className="table_action_icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                  {
                    type: "field",
                    field: "team_name",
                    headerName: "Team Name",
                    search_type: "text_search",
                    minWidth: 150,
                    width: 230,
                    maxWidth: 1500,
                  },
                  {
                    type: "action",
                    field: "team_info",
                    headerName: "",
                    width: 110,
                    renderCell: (params) => {
                      return (
                        <div className="action_icons_">
                          <div>&nbsp;&nbsp;</div>
                          <Tooltip title="Team Info">
                            <IconButton>
                              <Link to={handleOpenTeamSection(params)}>
                                <PeopleAltIcon className="table_action_icon" />
                              </Link>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Open Workspace">
                            <IconButton>
                              <Link to={handleOpenTeamWorkspaceSection(params)}>
                                <CgWorkAlt className="table_action_icon" />
                              </Link>
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Open Team Members">
                            <IconButton>
                              <Link to={handleOpenTeamMemberSection(params)}>
                                <BsPersonWorkspace className="table_action_icon" />
                              </Link>
                            </IconButton>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                  {
                    type: "field",
                    field: "team_description",
                    headerName: "Team Description",
                    search_type: "text_search",
                    minWidth: 290,
                    width: 380,
                    maxWidth: 2000,
                  },

                  {
                    type: "field",
                    field: "member_designation",
                    headerName: "Designation",
                    search_type: "designation_search",
                    minWidth: 190,
                    width: 200,
                    maxWidth: 1350,
                  },
                  {
                    type: "field",
                    field: "owner_name",
                    headerName: "Owner",
                    search_type: "text_search",
                    minWidth: 150,
                    width: 220,
                    maxWidth: 1500,
                  },
                  {
                    type: "field",
                    field: "credit_points_week_limit",
                    headerName: `Credits Week Limit`,
                    search_type: "number_search",
                    minWidth: 150,
                    width: 225,
                    maxWidth: 1000,
                  },
                  {
                    type: "field",
                    field: "used_credit_points_this_week",
                    headerName: "Used Credits this Week",
                    search_type: "number_search",
                    minWidth: 150,
                    width: 245,
                    maxWidth: 1000,
                  },
                  {
                    type: "field",
                    field: "credit_points_week_limit_left",
                    headerName: "Credit Limit Left this Week",
                    search_type: "number_search",
                    minWidth: 150,
                    width: 270,
                    maxWidth: 1000,
                  },
                  {
                    type: "field",
                    field: "total_credits",
                    headerName: "Owner's Credits Left",
                    search_type: "number_search",
                    minWidth: 150,
                    width: 230,
                    maxWidth: 1000,
                  },
                  {
                    type: "field",
                    field: "created_at",
                    headerName: "Created At",
                    search_type: "date_search",
                    minWidth: 150,
                    width: 170,
                    maxWidth: 1000,
                  },
                  {
                    type: "id",
                    field: "team_id",
                    headerName: "",
                    minWidth: 1,
                    width: 1,
                    maxWidth: 380,
                  },
                ]}
                bulk_actions={[
                  {
                    action: "add",
                    renderCell: (params) => {
                      return (
                        <Tooltip title="Add New Team">
                          <div
                            onClick={handleAddOpen}
                            className="bulk_classify_button download_icons"
                          >
                            <IoIosAddCircleOutline />
                          </div>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    action: "delete_multiple",
                    renderCell: (params) => {
                      return (
                        <Tooltip title="Delete Selected Team">
                          <div
                            onClick={() => handleBulkTeamDeleteClick(params)}
                            className="bulk_delete_button download_icons"
                          >
                            <MdOutlineDeleteSweep fontSize={30} />
                          </div>
                        </Tooltip>
                      );
                    },
                  },
                ]}
                tableDisplay={"Table"}
              />
            )}

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={notificationMessage}
            />

            {/* Add team.... */}

            <Modal
              open={showAddteamForm}
              onClose={handleAddClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header_container">
                    <div className="modal_header_text">Add New Team</div>
                    <div className="modal_header_close_icon">
                      <IconButton onClick={handleAddClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div className="modal_content_container">
                    <Box component="form" noValidate autoComplete="off" sx={{ "& > :not(style)": { width: "80%", marginLeft: "10%" } }}>
                      <TextField
                        id="team_name"
                        label="Team Name"
                        variant="standard"
                        name="team_name"
                        value={newTeamData.team_name}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                        error={!!formErrors.team_name}
                        helperText={formErrors.team_name}
                        required
                      />
                    </Box>
                    <Box component="form" noValidate autoComplete="off" sx={{ "& > :not(style)": { width: "80%", marginLeft: "10%" } }}>
                      <TextField
                        id="team_description"
                        label="Team Description"
                        variant="standard"
                        name="team_description"
                        value={newTeamData.team_description}
                        onChange={handleNewInputChange}
                        className="modal_form_input modal_textarea"
                        multiline
                        rows={4}
                      />
                    </Box>
                    <Box component="form" noValidate autoComplete="off" sx={{ "& > :not(style)": { width: "80%", marginLeft: "10%" } }}>
                      <TextField
                        type="number"
                        id="credit_points_week_limit"
                        label="Credit Points Week Limit Assigned For Yourself"
                        variant="standard"
                        name="credit_points_week_limit"
                        value={newTeamData.credit_points_week_limit}
                        onChange={handleNewInputChange}
                        className="modal_form_input"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString();
                        }}
                        min={0}
                        error={!!formErrors.credit_points_week_limit}
                        helperText={formErrors.credit_points_week_limit}
                        required
                      />
                    </Box>
                  </div>
                  <button onClick={handleAddTeamSubmit} className="add-user-btn">
                    Add
                  </button>
                </div>
              </Box>
            </Modal>

            {/* update.... */}

            <Modal
              open={showEditteamModal}
              onClose={handleEditClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="add-user-form">
                  <div className="modal_header_container">
                    <div className="modal_header_text">Update Team</div>
                    <div className="modal_header_close_icon">
                      <IconButton onClick={handleEditClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {

                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="team_name"
                      label="Team Name"
                      variant="standard"
                      name="team_name"
                      value={selectedTeam ? selectedTeam.team_name : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": {

                        width: "80%",
                        marginLeft: "10%",
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="team_description"
                      label="Team Description"
                      variant="standard"
                      name="team_description"
                      value={selectedTeam ? selectedTeam.team_description : ""}
                      onChange={handleInputChange}
                      className="modal_form_input"
                      multiline
                      rows={4}
                    />
                  </Box>

                  <button onClick={handleEditteamData} className="add-user-btn">
                    Update
                  </button>
                </div>
              </Box>

              {/* delete... */}
            </Modal>

            {/* delete... */}

            <Modal
              open={showDeleteTeamModal}
              onClose={handleDeleteteamModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header_container">
                    <div className="modal_header_text">Delete Team</div>
                    <div className="modal_header_close_icon">
                      <IconButton onClick={handleDeleteteamModalClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                  {selectedTeamsForDelete.map((team) => (
                    <div key={team.team_id}>
                      <div className="modal_text">
                        Team: {team.team_name} ({team.team_description})
                      </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleDeleteteamConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>

            {/* Bulk delete.... */}

            <Modal
              open={showBulkDeleteModal}
              onClose={handleBulkDeleteClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box id="model_box">
                <div className="delete-user-form">
                  <div className="modal_header_container">
                    <div className="modal_header_text">Delete Team(s)</div>
                    <div className="modal_header_close_icon">
                      <IconButton onClick={handleBulkDeleteClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                  {selectedTeamsForDelete.map((team) => (
                    <div key={team}>
                      <div className="modal_text">Team Name: {team} </div>
                    </div>
                  ))}
                  <div className="modal_text">
                    Are you sure you want to delete selected team(s)?
                  </div>
                  <div className="form-group">
                    <Button onClick={handleBulkDeleteConfirm} color="error">
                      <MdDelete className="formIcons" />
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>

            <StatusModalComponent
              isOpen={modalState.isOpen}
              type={modalState.type}
              message={modalState.message}
              onClose={() => setModalState({ isOpen: false, type: "", message: "" })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTeams;
