import React, { useState, useEffect, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { CSVLink } from "react-csv";
import IconButton from "@mui/material/IconButton";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import { IoCloudDownloadOutline } from "react-icons/io5";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import moment from "moment";
import horizontal_separator_icon from "./assets/horizontal_separator_icon.png";
import "./TableWithPagination.css";
import { BiExpandHorizontal } from "react-icons/bi";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Skeleton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ClickAwayListener,
  FormHelperText,
  CircularProgress,
  Alert,
  Menu,
} from "@mui/material";
import { FaCaretUp, FaEye, FaEyeSlash } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { FaCaretDown } from "react-icons/fa6";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const TableWithPagination = ({
  data: initialData,
  fields,
  bulk_actions,
  tableDisplay,
  file_name,
}) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInputs, setSearchInputs] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [skipPages, setSkipPages] = useState(1); // Default to 0 pages skip
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [selectAllinCurrentPage, setSelectAllinCurrentPage] = useState(false);
  const theme = useTheme();
  const [numberRangeOption, setNumberRangeOption] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElForSelectBoxType, setAnchorElForSelectBoxType] =
    useState(null);
  const [defaultAction, setDefaultAction] = useState("currentPage");
  const handleReset = () => {
    // Clear the search inputs
    setSearchInputs({});
  };

  const [hoveredFields, setHoveredFields] = useState({});

  const handleMouseEnter = (field) => {
    setHoveredFields((prevState) => ({ ...prevState, [field]: true }));
  };

  const handleMouseLeave = (field) => {
    setHoveredFields((prevState) => ({ ...prevState, [field]: false }));
  };

  const default_action_width = 100;
  const default_width = 250;
  const default_min_width = 100;
  const default_max_width = 400;
  const [columnWidths, set_columnWidths] = useState([]);

  const handleColumnResize = (field, newWidth) => {
    // Ensure the newWidth is within the specified min and max width for the column
    const fieldConfig = fields.find((f) => f.field === field);
    const minWidth = fieldConfig?.minWidth || default_min_width;
    const maxWidth = fieldConfig?.maxWidth || default_max_width;
    const clampedWidth = Math.min(maxWidth, Math.max(minWidth, newWidth));

    set_columnWidths({
      ...columnWidths,
      [field]: clampedWidth,
    });

    const searchBars = document.querySelectorAll(".search_bar");
    searchBars.forEach((searchBar) => {
      const searchField = searchBar.getAttribute("data-field");
      if (searchField === field) {
        searchBar.style.width = clampedWidth; //+16 + 'px';
      }
    });
  };

  useEffect(() => {
    const adjustColumnWidthsForScreenSize = () => {
      const screenWidth = window.innerWidth;
      const initialColumnWidths = {};

      fields.forEach((field) => {
        let width;
        if (
          // field.type === "action" ||
          field.type === "id"
        ) {
          width = field.width || default_action_width;
        } else {
          width = field.width || default_width;
        }

        // Adjust width based on screen size breakpoints
        if (screenWidth < 360) {
          // Very small screens (e.g., small mobile)
          width = width;
        } else if (screenWidth < 480) {
          // Extra small screens (e.g., mobile)
          width = width;
        } else if (screenWidth < 768) {
          // Small screens (e.g., small tablets)
          width = width;
        } else if (screenWidth < 992) {
          // Medium screens (e.g., tablets)
          width = width;
        } else if (screenWidth < 1350) {
          // Large screens (e.g., laptops)
          width = width * 0.99;
        } else if (screenWidth < 1600) {
          // Extra large screens (e.g., desktops)
          width = width;
        } else if (screenWidth < 1920) {
          // Very large screens (e.g., large desktops)
          width = width * 1.3; // Default width, no scaling
        } else if (screenWidth < 2200) {
          // Ultra wide screens (e.g., ultra-wide monitors)
          width = width * 1.2;
        } else if (screenWidth < 2560) {
          // Ultra wide screens (e.g., ultra-wide monitors)
          width = width * 1.3;
        } else if (screenWidth < 3100) {
          // Ultra wide screens (e.g., ultra-wide monitors)
          width = width * 1.6;
        } else if (screenWidth < 5000) {
          // Ultra wide screens (e.g., ultra-wide monitors)
          width = width * 2;
        } else if (screenWidth < 6500) {
          // Ultra wide screens (e.g., ultra-wide monitors)
          width = width * 2.6;
        } else {
          // Extra ultra-wide screens
          width = width * 4;
        }

        initialColumnWidths[field.field] = width;
      });

      set_columnWidths(initialColumnWidths);

      const searchBars = document.querySelectorAll(".search_bar");
      searchBars.forEach((searchBar) => {
        const searchField = searchBar.getAttribute("data-field");
        let clampedWidth = initialColumnWidths[searchField];

        searchBar.style.width = clampedWidth + "px"; // Setting the adjusted width
      });
    };

    adjustColumnWidthsForScreenSize();

    const handleResize = () => {
      adjustColumnWidthsForScreenSize();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [fields, default_width]);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (Array.isArray(initialData)) {
      // converting any field with array data to comma-separated data
      const updatedData = initialData.map((item) => {
        const newData = { ...item };
        fields.forEach((field) => {
          if (field.type === "field" && Array.isArray(newData[field.field])) {
            newData[field.field] = newData[field.field].join(", ");
          }
        });
        return newData;
      });
      setData(updatedData);
      setFilteredData(updatedData);
    } else {
      setData([]);
      setFilteredData([]);
      // setCsvData([]);
    }
  }, [initialData, fields]);

  const parseDate = (dateString) => {
    const parts = dateString.split("-");
    // Assuming the date format is "DD-MM-YYYY"
    return new Date(parts[2], parts[1] - 1, parts[0]); // Year, Month (zero-based), Day
  };
  const [dateRangeOption, setDateRangeOption] = useState("");
  const handleSearchChangeForDesignation = (field, value) => {
    setSearchInputs({
      ...searchInputs,
      [field]: value,
    });
  };
  const handleSearchChangeForScrape = (field, value) => {
    setSearchInputs({
      ...searchInputs,
      [field]: value,
    });
  };
  const handleDateRangeOptionChange = (field, value) => {
    setDateRangeOption((prevOptions) => ({
      ...prevOptions,
      [field]: value,
    }));

    if (value !== "custom") {
      const currentDate = moment();
      let minDate = null;
      switch (value) {
        case "last5days":
          minDate = currentDate.clone().subtract(5, "days");
          break;
        case "last10days":
          minDate = currentDate.clone().subtract(10, "days");
          break;
        case "last15days":
          minDate = currentDate.clone().subtract(15, "days");
          break;
        case "last30days":
          minDate = currentDate.clone().subtract(30, "days");
          break;
        default:
          break;
      }

      handleRangeChange(field, {
        min: minDate ? minDate.format("YYYY-MM-DD") : "",
        max: currentDate.format("YYYY-MM-DD"),
      });
    }
  };
  const handleNumberRangeOptionChange = (field, value) => {
    setNumberRangeOption((prevOptions) => ({
      ...prevOptions,
      [field]: value,
    }));

    if (value !== "custom") {
      let min, max;
      switch (value) {
        case "0-1":
          min = 0;
          max = 1;
          break;
        case "1-10":
          min = 1;
          max = 10;
          break;
        case "11-100":
          min = 11;
          max = 100;
          break;
        case "101-1000":
          min = 101;
          max = 1000;
          break;
        case "1001-10000":
          min = 1001;
          max = 10000;
          break;
        case "10001-100000":
          min = 10001;
          max = 100000;
          break;
        case "100001-1000000":
          min = 100001;
          max = 1000000;
          break;
        default:
          min = "";
          max = "";
          break;
      }

      handleRangeChange(field, {
        min: min !== "" ? min.toString() : "",
        max: max !== "" ? max.toString() : "",
      });
    }
  };

  const handleRangeChange = (field, range) => {
    setSearchInputs((prevInputs) => ({
      ...prevInputs,
      [field]: {
        ...prevInputs[field],
        ...range,
      },
    }));
  };
  const handleApplyClick = (field) => {
    setTooltipOpen((prevOpen) => ({
      ...prevOpen,
      [field]: false,
    }));
  };
  const handleTooltipOpen = (field) => {
    setTooltipOpen((prevOpen) => ({
      ...prevOpen,
      [field]: true,
    }));
  };

  const handleTooltipClose = (field) => {
    setTooltipOpen((prevOpen) => ({
      ...prevOpen,
      [field]: false,
    }));
  };
  const [anchorEls, setAnchorEls] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});

  // Initialize state based on fields
  useEffect(() => {
    const initialSelectedOptions = {};
    const initialSearchInputs = {};

    fields.forEach((field) => {
      if (field.search_type === "text_search") {
        initialSelectedOptions[field.field] = "include"; // Default to include
        initialSearchInputs[field.field] = {
          include: "",
          exclude: "",
        };
      }
    });

    setSelectedOptions(initialSelectedOptions);
    setSearchInputs(initialSearchInputs);
  }, [fields]);

  const handleOpenTextSearchMenu = (event, field) => {
    setAnchorEls((prev) => ({
      ...prev,
      [field]: event.currentTarget,
    }));
  };

  const handleCloseTextSearchMenu = (field) => {
    setAnchorEls((prev) => ({
      ...prev,
      [field]: null,
    }));
  };

  const handleMenuOptionChange = (field, option) => {
    const currentOption = selectedOptions[field];
    const currentValue = searchInputs[field]?.[currentOption] || "";

    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [field]: option,
    }));

    setSearchInputs((prevSearchInputs) => ({
      ...prevSearchInputs,
      [field]: {
        ...prevSearchInputs[field],
        [option]: currentValue,
        [currentOption]: "",
      },
    }));

    handleCloseTextSearchMenu(field); // Close the menu
  };

  const handleSearchChange = (field, value) => {
    setSearchInputs((prevSearchInputs) => ({
      ...prevSearchInputs,
      [field]: {
        ...prevSearchInputs[field],
        ...value,
      },
    }));

    // Ensure mutual exclusivity between include and exclude
    if (value.include !== undefined) {
      setSearchInputs((prevSearchInputs) => ({
        ...prevSearchInputs,
        [field]: {
          ...prevSearchInputs[field],
          exclude: "",
        },
      }));
    } else if (value.exclude !== undefined) {
      setSearchInputs((prevSearchInputs) => ({
        ...prevSearchInputs,
        [field]: {
          ...prevSearchInputs[field],
          include: "",
        },
      }));
    }
  };

  useEffect(() => {
    // console.log("Search Inputs:", searchInputs);

    const updatedFilteredData = data.filter((item) => {
      return Object.keys(searchInputs).every((field) => {
        const searchField = fields.find((f) => f.field === field);

        if (searchField) {
          const searchValue = searchInputs[field];
          const searchType = searchField.search_type || "text_search";

          if (searchType === "text_search") {
            const fieldValue = String(item[field]).toLowerCase();
            const include = searchValue.include
              ? searchValue.include.toLowerCase()
              : "";
            const exclude = searchValue.exclude
              ? searchValue.exclude.toLowerCase()
              : "";

            if (include && !fieldValue.includes(include)) {
              return false; // Exclude item if it doesn't include the 'include' string
            }

            if (exclude && fieldValue.includes(exclude)) {
              return false; // Exclude item if it includes the 'exclude' string
            }

            return true; // Include item by default if no include/exclude criteria matches
          } else if (searchType === "designation_search") {
            return String(item[field])
              .toLowerCase()
              .includes(searchValue.toLowerCase());
          } else if (searchType === "scrape_search") {
            const fieldValue = String(item[field]).toLowerCase();
            const searchLower = searchValue.toLowerCase();

            if (searchLower === "scraped") {
              return fieldValue === "scraped";
            } else if (searchLower === "not scraped") {
              return fieldValue === "not scraped";
            } else {
              return false; // Return false for any other search value (optional)
            }
          } else if (searchType === "number_search") {
            const fieldValue = parseFloat(item[field]);
            const min = parseFloat(searchValue.min);
            const max = parseFloat(searchValue.max);
            return (
              (isNaN(min) || fieldValue >= min) &&
              (isNaN(max) || fieldValue <= max)
            );
          } else if (searchType === "date_search") {
            const fieldValue = moment(item[field], [
              "DD-MM-YYYY",
              "DD-MM-YYYY hh:mm A",
            ]).toDate();
            const minDate = searchValue.min
              ? moment(searchValue.min).toDate()
              : null;
            const maxDate = searchValue.max
              ? moment(searchValue.max).toDate()
              : null;
            return (
              (!minDate || fieldValue >= minDate) &&
              (!maxDate || fieldValue <= maxDate)
            );
          }
        }

        return true; // Default to including the item if field or search type is not found
      });
    });

    // console.log("Updated Filtered Data:", updatedFilteredData);

    setFilteredData(updatedFilteredData);
  }, [data, searchInputs, fields]);

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedData = filteredData.sort((a, b) => {
    if (sortField) {
      const aValue = a[sortField];
      const bValue = b[sortField];
      var isNumerical = false;
      /*
      if (fields.find((f) => f.field === sortField && f.type === 'id'))
      {
        isNumerical = !isNaN(aValue) && !isNaN(bValue);
      }
      */
      isNumerical = !isNaN(aValue) && !isNaN(bValue);
      if (isNumerical) {
        // console.log(isNumerical);
        if (sortDirection === "asc") {
          return parseInt(aValue) - parseInt(bValue);
        } else {
          return parseInt(bValue) - parseInt(aValue);
        }
      } else {
        if (sortDirection === "asc") {
          return String(aValue).localeCompare(String(bValue));
        } else {
          return String(bValue).localeCompare(String(aValue));
        }
      }
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  //console.log(currentItems);
  const goToNextPage = () => {
    var newPage = parseInt(currentPage) + parseInt(skipPages);
    if (newPage <= totalPages) {
      setCurrentPage(newPage);
    }
    //sync_select_boxes(selectedRecords);
  };

  const goToPreviousPage = () => {
    const pagesToSkip = Math.min(skipPages, currentPage - 1);
    const newPage = currentPage - pagesToSkip;
    if (newPage >= 1) {
      setCurrentPage(newPage);
    }
    //sync_select_boxes(selectedRecords);
  };

  const sync_select_boxes = (newSelectedRecords) => {
    let prev_selectAllinCurrentPage = selectAllinCurrentPage;
    const recordsOnCurrentPage = currentItems.map(
      (item) => item[fields.find((f) => f.type === "id").field]
    );
    const isAllSelectedinCurrentPage = recordsOnCurrentPage.every((record) =>
      newSelectedRecords.includes(record)
    );
    if (prev_selectAllinCurrentPage !== isAllSelectedinCurrentPage)
      setSelectAllinCurrentPage(isAllSelectedinCurrentPage);

    let prev_selectAll = selectAll;
    const is_selectAll = data.every((record) =>
      newSelectedRecords.includes(
        record[fields.find((f) => f.type === "id").field]
      )
    );
    if (prev_selectAll !== is_selectAll) setSelectAll(is_selectAll);
  };
  sync_select_boxes(selectedRecords);

  const toggleSelectRecord = (record) => {
    const isSelected = selectedRecords.includes(record);
    let newSelectedRecords;
    if (isSelected) {
      newSelectedRecords = selectedRecords.filter((r) => r !== record);
    } else {
      newSelectedRecords = [...selectedRecords, record];
    }
    setSelectedRecords(newSelectedRecords);

    sync_select_boxes(newSelectedRecords);
    //setSelectAll(isAllSelected);

    set_download_data(newSelectedRecords);
  };
  const handleOpenCheckBoxSelect = (event) => {
    setAnchorElForSelectBoxType(event.currentTarget); // Updated state setter
  };

  const handleCloseCheckBoxSelect = () => {
    setAnchorElForSelectBoxType(null); // Updated state setter
  };

  const toggleSelectAll = () => {
    console.log("toggleSelectAll");

    if (selectAll) {
      setSelectedRecords([]);
      set_download_data([]);
    } else {
      const newSelectedRecords = data.map(
        (item) => item[fields.find((f) => f.type === "id").field]
      );
      console.log(newSelectedRecords);
      setSelectedRecords(newSelectedRecords);
      set_download_data(newSelectedRecords);
    }
    setSelectAll(!selectAll);
  };

  const toggleSelectAllPages = () => {
    console.log("toggleSelectAllPages");

    if (!selectAllPages) {
      const allRecords = filteredData.map(
        (item) => item[fields.find((f) => f.type === "id").field]
      );
      console.log(allRecords);
      setSelectedRecords(allRecords);
      set_download_data(allRecords);
    } else {
      setSelectedRecords([]);
      set_download_data([]);
    }
    setSelectAllPages(!selectAllPages);
  };

  const toggleSelectAllinCurrentPage = () => {
    console.log("toggleSelectAllinCurrentPage");
    if (!selectAllinCurrentPage) {
      const recordsOnCurrentPage = currentItems.map(
        (item) => item[fields.find((f) => f.type === "id").field]
      );
      setSelectedRecords([...selectedRecords, ...recordsOnCurrentPage]);
      set_download_data([...selectedRecords, ...recordsOnCurrentPage]);
    } else {
      const recordsOnCurrentPage = currentItems.map(
        (item) => item[fields.find((f) => f.type === "id").field]
      );
      console.log(recordsOnCurrentPage);
      setSelectedRecords(
        selectedRecords.filter(
          (record) => !recordsOnCurrentPage.includes(record)
        )
      );
      set_download_data(
        selectedRecords.filter(
          (record) => !recordsOnCurrentPage.includes(record)
        )
      );
    }
    setSelectAllinCurrentPage(!selectAllinCurrentPage);
  };

  const handleCheckboxChange = () => {
    if (defaultAction === "currentPage") {
      toggleSelectAllinCurrentPage();
    } else if (defaultAction === "AllPage") {
      toggleSelectAllPages();
    } else {
      toggleSelectAll();
    }
    handleCloseCheckBoxSelect(); // Close the menu after selection
  };
  const handleSelectBoxTypeChange = (selectType) => {
    console.log("selectType");
    console.log(selectType);

    if (selectType === "AllPage") {
      toggleSelectAllPages();
    } else if (selectType === "currentPage") {
      toggleSelectAllinCurrentPage();
    } else if (selectType === "all") {
      toggleSelectAll();
    } else {
      toggleSelectAll();
    }
    setDefaultAction(selectType);
    handleCloseCheckBoxSelect();
  };
  const getSelectedRowCount = () => {
    if (defaultAction === "currentPage") {
      return currentItems.filter((item) =>
        selectedRecords.includes(
          item[fields.find((f) => f.type === "id").field]
        )
      ).length;
    } else if (defaultAction === "AllPage") {
      return filteredData.length;
    } else {
      return selectedRecords.length;
    }
  };
  const getTooltipTitle = () => {
    if (selectedRecords.length === 0) {
      return "No data selected";
    } else if (defaultAction === "currentPage") {
      return `${getSelectedRowCount()} rows selected on current page`;
    } else if (defaultAction === "AllPage") {
      return `${getSelectedRowCount()} rows selected across all pages`;
    } else {
      return `${getSelectedRowCount()} rows selected`;
    }
  };

  const set_download_data = (newSelectedRecords) => {
    // Find the field whose type is "id"
    const idField = fields.find((field) => field.type === "id").field;

    // Filter the filteredData based on the newSelectedRecords
    var selectedData = filteredData.filter((item) =>
      newSelectedRecords.includes(item[idField])
    );

    // Update the csvData state
    setCsvData([
      fields
        .filter((field) => field.type === "field")
        .map((field) => field.headerName),
      ...selectedData.map((item) =>
        fields
          .filter((field) => field.type === "field")
          .map((field) => item[field.field])
      ),
    ]);
  };
  useEffect(() => {
    set_download_data(selectedRecords);
  }, [selectedRecords, filteredData]);

  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const toggleSearchBarVisibility = () => {
    setSearchInputs({});
    setDateRangeOption("");
    setNumberRangeOption("");
    setIsSearchBarVisible(!isSearchBarVisible);
  };
  const searchIcon = isSearchBarVisible ? (
    <FilterListOffIcon />
  ) : (
    <FilterListIcon />
  );
  const [minValue, setMinValue] = useState(
    searchInputs[fields.field]?.min || ""
  );
  const [maxValue, setMaxValue] = useState(
    searchInputs[fields.field]?.max || ""
  );
  useEffect(() => {
    const handleClickOutside = (event) => {
      const tooltip = document.querySelector(".MuiTooltip-tooltip");
      if (tooltip && !tooltip.contains(event.target)) {
        // Clicked outside the tooltip
        setTooltipOpen(false); // Close the tooltip
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const truncateText = (text, width) => {
    const maxChars = Math.floor(width / 8) - 3; // Adjust to account for " ..."
    return typeof text === "string" && text.length > maxChars
      ? text.slice(0, maxChars) + " ..."
      : text;
  };

  const [copied, setCopied] = useState(false);
  const tooltipRef = useRef(null);

  const copyToClipboard = () => {
    if (tooltipRef.current) {
      const textToCopy = tooltipRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
        })
        .catch((error) => console.error("Failed to copy:", error));
    }
  };
  const tableBodyRef = useRef(null);
  const tableBodyScrollRef = useRef(null);

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    const tableBodyScroll = tableBodyScrollRef.current;

    if (tableBody && tableBodyScroll) {
      // Set the height of the scrollbar container to match the table body
      tableBodyScroll.style.height = `${tableBody.clientHeight}px`;

      // Calculate the total height of the currently displayed rows
      const rows =
        Math.min(indexOfLastItem, filteredData.length) - indexOfFirstItem; // Number of rows in the current page
      const rowHeight = 53; // Height of each row from .table_cell CSS
      const totalHeight = rows * rowHeight;

      // Clear previous dummy content if it exists
      while (tableBodyScroll.firstChild) {
        tableBodyScroll.removeChild(tableBodyScroll.firstChild);
      }

      // Create a dummy content in the scrollbar container to enable scrolling
      const dummyContent = document.createElement("div");
      dummyContent.style.height = `${totalHeight}px`;

      tableBodyScroll.appendChild(dummyContent);

      // Synchronize the scrolling between table_body and table_body_scroll
      const handleScroll = () => {
        tableBody.scrollTop = tableBodyScroll.scrollTop;
      };

      const handleTableBodyScroll = () => {
        tableBodyScroll.scrollTop = tableBody.scrollTop;
      };

      tableBodyScroll.addEventListener("scroll", handleScroll);
      tableBody.addEventListener("scroll", handleTableBodyScroll);

      return () => {
        tableBodyScroll.removeEventListener("scroll", handleScroll);
        tableBody.removeEventListener("scroll", handleTableBodyScroll);
      };
    }
  }, [
    tableBodyRef.current,
    tableBodyScrollRef.current,
    indexOfFirstItem,
    indexOfLastItem,
    filteredData.length,
  ]);

  // Also depend on currentItems length to recalculate on data change

  return initialData.length > 0 || tableDisplay === "Table" ? (
    <div className="TableWithPagination_section">
      <div className="table_body_with_bulk_section">
        <div className="bulk_action_section">
          <div className="bulk_action_container">
            <CSVLink
              data={csvData}
              filename={
                file_name ? `${file_name}.csv` : "filtered_wishlist_pages.csv"
              }
              className="download-icon"
            >
              <IoCloudDownloadOutline className="download_icons" />
            </CSVLink>

            {bulk_actions.map((field) => (
              <div className="bulk_action_content">
                {field.renderCell(selectedRecords)}
              </div>
            ))}
          </div>

          <IconButton
            id="icon_button_table"
            onClick={toggleSearchBarVisibility}
          >
            {searchIcon}
          </IconButton>
        </div>

        <div className="table_container">
          <div
            className="table_body_scroll"
            ref={tableBodyScrollRef}
            style={{ top: isSearchBarVisible ? "138px" : "54px" }}
          >
            <div style={{ height: `0` }} />
          </div>
          <div className="table_body_container">
            <div
              className="search_bar_section"
              style={{ display: isSearchBarVisible ? "flex" : "none" }}
            >
              <div
                key="check_box_place"
                data-field="check_box_place"
                className="check_box_place_search_bar"
              >
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              {fields.map((field, index) => (
                <div
                  className="search_bar"
                  key={field.field}
                  data-field={field.field}
                  onMouseEnter={() => handleMouseEnter(field.field)}
                  onMouseLeave={() => handleMouseLeave(field.field)}
                  style={{
                    border: "none",
                    borderRadius: "none",
                    width:
                      index === fields.findIndex((f) => f.type === "field")
                        ? "calc(100% )"
                        : "100%",
                  }}
                >
                  {field.type === "field" || field.type === "id" ? (
                    <Box
                      component="form"
                      id="search_bar_box"
                      sx={{
                        "& > :not(style)": { width: "99%" },
                        border: "none",
                        marginTop: "5px",
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      {field.search_type === "text_search" && (
                        <div
                          style={{
                            display: "flex",
                            padding: "12px 10px 0px 0px",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            id={`text_${selectedOptions[field.field]}_${
                              field.field
                            }`}
                            label={
                              selectedOptions[field.field] === "include"
                                ? `Search ${field.headerName} Text`
                                : "Search by Excluding text"
                            }
                            variant="standard"
                            type="text"
                            value={
                              selectedOptions[field.field] === "include"
                                ? searchInputs[field.field]?.include || ""
                                : searchInputs[field.field]?.exclude || ""
                            }
                            onChange={(e) =>
                              handleSearchChange(field.field, {
                                [selectedOptions[field.field]]: e.target.value,
                              })
                            }
                            fullWidth
                          />
                          <Menu
                            anchorEl={anchorEls[field.field]}
                            open={Boolean(anchorEls[field.field])}
                            onClose={() =>
                              handleCloseTextSearchMenu(field.field)
                            }
                            aria-haspopup="true"
                            id="menu_text_search"
                          >
                            <div className="form_controller_text_search">
                              <Select
                                fullWidth
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedOptions[field.field]}
                                onChange={(event) =>
                                  handleMenuOptionChange(
                                    field.field,
                                    event.target.value
                                  )
                                }
                                defaultValue="include"
                              >
                                <MenuItem value="include">Include</MenuItem>
                                <MenuItem value="exclude">Exclude</MenuItem>
                              </Select>
                            </div>
                          </Menu>

                          <FilterListIcon
                            className="filter_icon_for_individual_search"
                            onClick={(e) => {
                              if (!anchorEls[field.field]) {
                                handleOpenTextSearchMenu(e, field.field);
                              }
                            }}
                            style={{ cursor: "pointer" }} // Add pointer to improve the click experience
                          />
                        </div>
                      )}
                      {field.search_type === "designation_search" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // margin: "-2% 0% 0px ",
                            padding: "8px 40px 0px 0px",
                          }}
                        >
                          <FormControl variant="standard" fullWidth>
                            <InputLabel>Select Member</InputLabel>
                            <Select
                              label="Select Member"
                              name="member_designation"
                              id={`table_input_${field.field}`}
                              value={searchInputs[field.field] || ""}
                              onChange={(e) =>
                                handleSearchChangeForDesignation(
                                  field.field,
                                  e.target.value
                                )
                              }
                              style={{ padding: "10px 0px 0px" }}
                              MenuProps={{
                                classes: { paper: "customMenuPaper" },
                              }}
                            >
                              <MenuItem value="Owner">Owner</MenuItem>
                              <MenuItem value="Lead">Lead</MenuItem>
                              <MenuItem value="Manager">Manager</MenuItem>
                              <MenuItem value="Member">Member</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      )}
                      {field.search_type === "scrape_search" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // margin: "-2% 0% 0px ",
                            padding: "8px 40px 0px 0px",
                          }}
                        >
                          <FormControl variant="standard" fullWidth>
                            <InputLabel>Select </InputLabel>
                            <Select
                              label="Select Scrape or Not"
                              name="is_scraped "
                              id={`table_input_${field.field}`}
                              value={searchInputs[field.field] || ""}
                              onChange={(e) =>
                                handleSearchChangeForScrape(
                                  field.field,
                                  e.target.value
                                )
                              }
                              style={{ padding: "10px 0px 0px" }}
                              MenuProps={{
                                classes: { paper: "customMenuPaper" },
                              }}
                            >
                              <MenuItem value="scraped">scraped</MenuItem>
                              <MenuItem value="not scraped">
                                not scraped
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      )}

                      {field.search_type === "number_search" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "8px 40px 0px 0px",
                          }}
                        >
                          <FormControl variant="standard">
                            <InputLabel>Range</InputLabel>
                            <Select
                              value={numberRangeOption[field.field] || ""}
                              onChange={(e) =>
                                handleNumberRangeOptionChange(
                                  field.field,
                                  e.target.value
                                )
                              }
                              style={{
                                padding: "10px 0px 0px",
                                textAlign: "left",
                              }}
                              MenuProps={{
                                classes: { paper: "customMenuPaper" }, // Define custom class here
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="0-1">0 - 1</MenuItem>
                              <MenuItem value="1-10">1 - 10</MenuItem>
                              <MenuItem value="11-100">11 - 100</MenuItem>
                              <MenuItem value="101-1000">101 - 1K</MenuItem>
                              <MenuItem value="1001-10000">1K - 10K</MenuItem>
                              <MenuItem value="10001-100000">
                                10K - 100K
                              </MenuItem>
                              <MenuItem value="100001-1000000">
                                100K - 1M
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleTooltipOpen(field.field);
                                }}
                                value="custom"
                              >
                                Custom
                              </MenuItem>
                            </Select>
                            <Tooltip
                              title={
                                <div className="tooltip-title">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <TextField
                                        id={`min_${field.field}`}
                                        label="Min"
                                        variant="standard"
                                        type="number"
                                        value={
                                          searchInputs[field.field]?.min || ""
                                        }
                                        onChange={(e) =>
                                          handleRangeChange(field.field, {
                                            min: e.target.value,
                                          })
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{
                                          marginBottom: "10px",
                                          paddingRight: "10px",
                                        }}
                                      />
                                      <TextField
                                        id={`max_${field.field}`}
                                        label="Max"
                                        variant="standard"
                                        type="number"
                                        value={
                                          searchInputs[field.field]?.max || ""
                                        }
                                        onChange={(e) =>
                                          handleRangeChange(field.field, {
                                            max: e.target.value,
                                          })
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </div>
                                    <Button
                                      variant="contained"
                                      id="custom-button-color"
                                      onClick={() =>
                                        handleApplyClick(field.field)
                                      }
                                      style={{ marginTop: "10px" }}
                                    >
                                      Apply
                                    </Button>
                                  </div>
                                </div>
                              }
                              placement="bottom-start"
                              arrow
                              open={tooltipOpen[field.field] || false}
                              interactive
                              className="customTooltip"
                            ></Tooltip>
                          </FormControl>
                        </div>
                      )}

                      {field.search_type === "date_search" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // margin: "-2% 4% 0px ",
                            padding: "8px 40px 0px 0px",
                          }}
                        >
                          <FormControl variant="standard">
                            <InputLabel>Date Range</InputLabel>
                            <Select
                              value={dateRangeOption[field.field] || ""}
                              onChange={(e) =>
                                handleDateRangeOptionChange(
                                  field.field,
                                  e.target.value
                                )
                              }
                              style={{
                                padding: "10px 0px 0px",
                                textAlign: "left",
                              }}
                              MenuProps={{
                                classes: { paper: "customMenuPaper" }, // Define custom class here
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="last5days">Last 5 Days</MenuItem>
                              <MenuItem value="last10days">
                                Last 10 Days
                              </MenuItem>
                              <MenuItem value="last15days">
                                Last 15 Days
                              </MenuItem>
                              <MenuItem value="last30days">
                                Last 30 Days
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleTooltipOpen(field.field);
                                }}
                                value="custom"
                              >
                                Custom
                              </MenuItem>
                            </Select>
                            <Tooltip
                              title={
                                <div className="tooltip-title">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <TextField
                                        id={`min_${field.field}`}
                                        label="Min"
                                        variant="standard"
                                        type="date"
                                        value={
                                          searchInputs[field.field]?.min || ""
                                        }
                                        onChange={(e) =>
                                          handleRangeChange(field.field, {
                                            min: e.target.value,
                                          })
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        style={{
                                          marginBottom: "10px",
                                          paddingRight: "10px",
                                        }}
                                      />
                                      <TextField
                                        id={`max_${field.field}`}
                                        label="Max"
                                        variant="standard"
                                        type="date"
                                        value={
                                          searchInputs[field.field]?.max || ""
                                        }
                                        onChange={(e) =>
                                          handleRangeChange(field.field, {
                                            max: e.target.value,
                                          })
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </div>
                                    <Button
                                      variant="contained"
                                      id="custom-button-color"
                                      onClick={() =>
                                        handleApplyClick(field.field)
                                      }
                                      style={{ marginTop: "10px" }}
                                    >
                                      Apply
                                    </Button>
                                  </div>
                                </div>
                              }
                              placement="bottom-start"
                              arrow
                              open={tooltipOpen[field.field] || false}
                              interactive
                              className="customTooltip"
                            />
                          </FormControl>
                        </div>
                      )}
                    </Box>
                  ) : (
                    field.type === "action" && (
                      <div
                        className="action_bar_button"
                        style={{
                          width: "calc(100% - 10px)",
                          margin: "0 calc(100% - 10px)",
                        }}
                      >
                        {/* <Button  id='search_bar_reset_button' onClick={handleReset} ><div className='reset_font'>Reset</div></Button> */}
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>

            <div className="table_header">
              <div
                className="table_cell check_box_place"
                key="check_box_place"
                data-field="check_box_place"
              >
                <div className="select_all_data_checkbox">
                  <div className="select_all_data_checkbox_with_select">
                    <Tooltip title={getTooltipTitle()}>
                      <div className="select_all_data_checkbox_with_select_option">
                        <input
                          type="checkbox"
                          checked={
                            defaultAction === "currentPage"
                              ? selectAllinCurrentPage
                              : selectAllPages
                              ? selectAllPages
                              : selectAll
                          }
                          onChange={handleCheckboxChange}
                        />
                      </div>
                    </Tooltip>

                    <div onClick={handleOpenCheckBoxSelect}>
                      {anchorElForSelectBoxType ? (
                        <FaCaretUp />
                      ) : (
                        <FaCaretDown />
                      )}
                    </div>
                    <Menu
                      anchorEl={anchorElForSelectBoxType}
                      open={Boolean(anchorElForSelectBoxType)}
                      onClose={handleCloseCheckBoxSelect}
                    >
                      <MenuItem
                        onClick={() => handleSelectBoxTypeChange("currentPage")}
                      >
                        Select This Page
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleSelectBoxTypeChange("AllPage")}
                      >
                        Select All Pages
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSelectBoxTypeChange("all")}
                      >
                        Select All Data
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>

              {fields.map((field) =>
                field.type === "field" ? (
                  // || field.type === "id"
                  <div
                    className="table_cell"
                    key={field.field}
                    style={{ width: columnWidths[field.field] }}
                  >
                    <div className="table_cell_left_cell">
                      <div className="table_header_text">
                        {field.headerName}
                      </div>
                      <div
                        className="table_sort"
                        onClick={() => handleSort(field.field)}
                      >
                        {sortField === field.field ? (
                          <div className="table_sorted">
                            {sortDirection === "asc" ? "▲" : "▼"}
                          </div>
                        ) : (
                          <div className="table_sort_option">
                            <div>▲</div>
                            <div>▼</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="column_resize_handle"
                      onMouseDown={(e) => {
                        e.preventDefault();
                        const initialWidth =
                          columnWidths[field.field] || default_width;
                        const startX = e.pageX;
                        document.addEventListener("mousemove", handleResize);
                        document.addEventListener("mouseup", stopResize);

                        function handleResize(e) {
                          const newWidth = initialWidth + (e.pageX - startX);
                          // Prevent columns from becoming too narrow
                          handleColumnResize(field.field, newWidth);
                        }

                        function stopResize() {
                          document.removeEventListener(
                            "mousemove",
                            handleResize
                          );
                          document.removeEventListener("mouseup", stopResize);
                        }
                      }}
                    >
                      <BiExpandHorizontal />
                    </div>
                  </div>
                ) : field.type === "action" ? (
                  <div
                    className="table_cell"
                    key={field.field}
                    style={{ width: columnWidths[field.field] }}
                  >
                    <div className="table_header_text">{field.headerName}</div>
                  </div>
                ) : null
              )}
            </div>

            <div className="table_body_outer">
              <div className="table_body" ref={tableBodyRef}>
                {currentItems.map((item) => (
                  <div
                    className="table_row"
                    key={item[fields.find((f) => f.type === "id").field]}
                  >
                    <div
                      className="table_cell check_box_place check_box_table_body"
                      key="check_box_place"
                      data-field="check_box_place"
                    >
                      <input
                        type="checkbox"
                        checked={selectedRecords.includes(
                          item[fields.find((f) => f.type === "id").field]
                        )}
                        onChange={() =>
                          toggleSelectRecord(
                            item[fields.find((f) => f.type === "id").field]
                          )
                        }
                      />
                    </div>
                    {fields.map((field) => (
                      <div
                        className="table_cell"
                        key={field.field}
                        style={{
                          width: columnWidths[field.field],
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {(field.type === "id" || field.type === "field") && (
                          <div className="table_cell_text">
                            {(() => {
                              const truncatedText = truncateText(
                                item[field.field],
                                columnWidths[field.field]
                              );
                              const isTruncated =
                                typeof truncatedText === "string" &&
                                truncatedText.endsWith(" ...");
                              const textWithoutEllipsis = isTruncated
                                ? truncatedText.slice(0, -4)
                                : truncatedText;
                              return (
                                <>
                                  {textWithoutEllipsis}
                                  {isTruncated && (
                                    <Tooltip
                                      title={
                                        <div
                                          className="tooltip-content"
                                          ref={tooltipRef}
                                        >
                                          {item[field.field]}
                                          <button
                                            onClick={copyToClipboard}
                                            className="copy-button"
                                          >
                                            {copied ? (
                                              <CheckCircleOutlineIcon
                                                style={{ color: "green" }}
                                              />
                                            ) : (
                                              <ContentCopyIcon />
                                            )}
                                          </button>
                                        </div>
                                      }
                                      arrow
                                      placement="right"
                                    >
                                      <span className="ellipsis">...</span>
                                    </Tooltip>
                                  )}
                                </>
                              );
                            })()}
                          </div>
                        )}
                        {field.type === "action" && field.renderCell(item)}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="pagination_main">
            <div className="per_page_setup_section">
              <div className="per_page_setup_section_text">Rows per page: </div>
              <select
                className="per_page_setup_section_select"
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value, 10));
                  setCurrentPage(1);
                }}
              >
                {[1, 2, 5, 10, 15, 20, 25, 50, 100, 150, 250, 500, 1000].map(
                  (option) => (
                    <option
                      key={option}
                      value={option}
                      className="per_page_setup_section_option"
                    >
                      {option}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="current_page_description_section">
              Total Records:{" "}
              <div className="pagination_data_result">
                {filteredData.length}
              </div>
            </div>
            <div className="current_page_description_section">
              Currently showing record{" "}
              <div className="pagination_data_result">
                {indexOfFirstItem + 1}
              </div>{" "}
              to record{" "}
              <div className="pagination_data_result">
                {Math.min(indexOfLastItem, filteredData.length)}
              </div>
            </div>
            <div className="current_page_description_section">
              Total pages:{" "}
              <div className="pagination_data_result">{totalPages}</div>
            </div>
            <div className="current_page_description_section">
              Currently showing page:{" "}
              <div className="pagination_data_result">{currentPage}</div>
            </div>
            <div className="skip_section">
              {currentPage <= 1 ? (
                <div className="previous_next_icon_disable">
                  <GrPrevious
                    className="previous_next_icon_icon"
                    color="#636363"
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    goToPreviousPage();
                  }}
                  className="previous_next_icon_active"
                >
                  <GrPrevious className="previous_next_icon_icon" />
                </div>
              )}

              {currentPage >= totalPages ? (
                <div className="previous_next_icon_disable">
                  <GrNext className="previous_next_icon_icon" color="#636363" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    goToNextPage();
                  }}
                  className="previous_next_icon_active"
                >
                  <GrNext className="previous_next_icon_icon" marginTop="8px" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      {tableDisplay === "Skeleton" ? (
        <div className="loading_container">
          <div className="skeleton_container"></div>
          <div className="circular_progress_container">
            <CircularProgress />
          </div>
        </div>
      ) : (
        (!initialData || initialData.length === 0) && <>
        
        </>
      )}
    </>
  );
};

export default TableWithPagination;
