import React, { useRef, useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Skeleton,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { TextareaAutosize } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import { FaEdit } from "react-icons/fa";

import CloseIcon from "@mui/icons-material/Close";
import "./BlogURLsPage.css";
import Header from "../common/Header/Header";
import {
  BUCKET_NAME,
  CHECK_CREDIT_CONDITION,
  DELETE_BLOG_URLS_DATA,
  GET_BLACK_LIST_DATA_OF_CAMPAIGN,
  GET_BLACK_LIST_DATA_OF_WORKSPACE,
  GET_BLOG_URLS_LIST,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  SAVE_BLOG_URLS_DATA,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  getFullApiUrl,
} from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import TableWithPagination from "../../utils/TableWithPagination";
import { GiFlatHammer } from "react-icons/gi";
import { IoFilterOutline } from "react-icons/io5";
import { MdOutlineFilterListOff } from "react-icons/md";
import {
  LAMBDA_FUNCTION_TO_GET_BLOG_DATA_BY_RANGE,
  LAMBDA_FUNCTION_TO_GET_BLOG_DATA_LENGTH_AND_FILE_NAME,
  LAMBDA_FUNCTION_TO_GET_INDIVIDUAL_BLOG_DATA,
  LAMBDA_FUNCTION_TO_SAVE_BLOG_DATA_BY_RANGE,
  LAMBDA_FUNCTION_TO_SAVE_INDIVIDUAL_BLOG_DATA,
  LAMBDA_FUNCTION_TO_SCRAPE_BLOG_DATA,
} from "../../utils/lambdaFunctionPath";

const initialScrapeMethodCheck = {
  Webcache: false,
  BeautifulSoup: false,
  Selenium: false,
};

const getNextScrapeMethod = (scrapeMethodCheck) => {
  for (const [method, checked] of Object.entries(scrapeMethodCheck)) {
    if (!checked) return method;
  }
  return null; // Return null if all methods have been executed
};
const BlogURLsPage = ({ toggleTheme }) => {
  //const apipath = "http://localhost:8001"
  const { campaignId } = useParams();
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditsWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [
    show_should_we_scrape_urls_modal,
    set_show_should_we_scrape_urls_modal,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditsPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState();
  const [show_progress_bar, set_show_progress_bar] = useState(false);
  const [blackListData, setBlackListData] = useState(null);
  useEffect(() => {
    getCampaignDetails();
  }, []); // Fetch credits point details on component mount

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      // console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      // console.log("points");
      // console.log(points);
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }

      fetchpointsDetails(0);
      // Close the Modal after deduction
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const [initial_loader_open, set_initial_loader_open] = React.useState(false);
  const [listofURL, setListofURL] = useState("");
  const [originalUrls, setOriginalUrls] = useState("");
  const [filteredUrls, setFilteredUrls] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterEnabled, setIsFilterEnabled] = useState(true);
  const [rows, setRows] = useState([]);
  const [table_data, set_table_data] = useState([]);
  const [editScrappedData, setEditScrappedData] = useState("");
  const [showEditScrapSection, setShowEditScrapSection] = useState(false);
  const [
    show_after_create_should_we_scrape_modal,
    set_show_after_create_should_we_scrape_modal,
  ] = useState(false);
  const [show_selectScrapOption_Section, set_show_selectScrapOption_Section] =
    useState(false);
  const [selected_scrapper_option, set_selected_scrapper_option] = useState("");
  const [scrape_method_check, set_scrape_method_check] = useState({
    BeautifulSoup: false,
    Webcache: false,
    Selenium: false,
  });
  const [savingRowsFromTextArea, setSavingRowsFromTextArea] = useState(false);

  const [scrapping_progress, set_scrapping_progress] = useState({
    total_count: 0,
    scrap_processed: 0,
    scrapped_count: 0,
    not_scrapped_count: 0,
  });
  const [fileNames, setFileNames] = useState([]);

  const handle_show_after_create_should_we_scrape_modal_close = async () => {
    set_show_after_create_should_we_scrape_modal(false);
  };
  const handle_show_after_create_should_we_scrape_modal_open = async () => {
    set_show_after_create_should_we_scrape_modal(true);
  };
  const [saving, set_saving] = useState(false);
  const [showScrapeSummaryModal, setShowScrapeSummaryModal] = useState(false);
  const [scrapeSummary, setScrapeSummary] = useState({
    scraped: 0,
    notScraped: 0,
  });
  const [scrapeMethodUpdated, setScrapeMethodUpdated] = useState(false);

  const updateScrapeMethodCheck = (method) => {
    set_scrape_method_check((prev) => ({ ...prev, [method]: true }));
    setScrapeMethodUpdated(true);
  };

  useEffect(() => {
    const handleScrapingProcess = async () => {
      if (scrapeMethodUpdated) {
        setScrapeMethodUpdated(false);
        await proceedWithScraping();

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    };

    handleScrapingProcess();
  }, [scrapeMethodUpdated]);

  const isDataFetched = useRef(false); // useRef to track if data is fetched

  // const lambdaUrlToGetBlogUrlLength = "https://bampo6yu7dook5voh4goc5ueui0bunvi.lambda-url.us-east-2.on.aws/"; // server
  // const lambdaUrlToGetBlogUrlLength = "https://2fcjl7u4blkaudxek6idbcsnvy0fhutp.lambda-url.us-east-2.on.aws/"; // testing1
  // const lambdaUrlToGetDataByRange = "https://n2be3p6rfgsw5soqoqvx42wory0kwkaj.lambda-url.us-east-2.on.aws/"; // server
  const lambdaUrlToGetDataByRange =
    "https://dgjly5g7p5xkd3itouw7gqkcya0xnqms.lambda-url.us-east-2.on.aws/"; // test
  const lambdaUrlToSaveBlogUrlDataByRange =
    "https://loeflttshyehegva533xkhfue40eanns.lambda-url.us-east-2.on.aws/"; // testing3
  // const lambdaUrlForScraping = "https://nx6ebwd5zpax2apxfsrmgtheya0rhaur.lambda-url.us-east-2.on.aws/";

  const requestData = {
    campaign_id: campaignId,
    bucket_name: BUCKET_NAME,
  };

  const fetchData = async () => {
    // Prevent fetch from being called multiple times
    if (isDataFetched.current) return;

    set_initial_loader_open(true);

    try {
      // Fetch the list of files and metadata
      const response = await fetch(
        LAMBDA_FUNCTION_TO_GET_BLOG_DATA_LENGTH_AND_FILE_NAME,
        {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.success) {
        const { file_names } = result.message;
        setFileNames(file_names);

        // Create a mapping of file names to their indices
        const fileNameToIndexMap = file_names.reduce((acc, fileName, index) => {
          acc[fileName] = index;
          return acc;
        }, {});

        // Prepare batch requests for each file
        const batchRequests = file_names.map((fileName) =>
          fetch(LAMBDA_FUNCTION_TO_GET_BLOG_DATA_BY_RANGE, {
            method: "POST",
            body: JSON.stringify({
              campaign_id: campaignId,
              bucket_name: BUCKET_NAME,
              file_name: String(fileName), // Correct parameter name
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((fileResponse) => {
              if (!fileResponse.ok) {
                throw new Error(
                  `HTTP error while fetching file data! Status: ${fileResponse.status}`
                );
              }
              return fileResponse.json();
            })
            .then((fileResult) => {
              if (fileResult.success) {
                return {
                  data: fileResult.message,
                  index: fileNameToIndexMap[fileName],
                }; // Include index for ordering
              } else {
                console.error("Failed to fetch file data: ", fileResult);
                return { data: [], index: fileNameToIndexMap[fileName] };
              }
            })
            .catch((error) => {
              console.error("Error fetching file data: ", error);
              return { data: [], index: fileNameToIndexMap[fileName] };
            })
        );

        // Fetch data from all files in parallel
        const allFileData = await Promise.all(batchRequests);

        // Sort the data based on the file names order
        const sortedData = allFileData
          .sort((a, b) => a.index - b.index) // Sort by index to preserve order
          .flatMap((fileData) => fileData.data); // Flatten the data arrays

        // Update state with sorted data
        if (sortedData.length > 0) {
          const updatedRows = sortedData.map((row) => ({ ...row }));
          setRows(updatedRows);

          const urlList = updatedRows.map((row) => row.url).join("\n");
          setListofURL(urlList);
          setOriginalUrls(urlList);
          set_table_data(updatedRows);

          const totalRows = updatedRows.length;
          const scrappedCount = updatedRows.filter(
            (row) => row.scraped === "scraped"
          ).length;
          const notScrappedCount = totalRows - scrappedCount;

          const progress = {
            scrap_processed: scrappedCount,
            scrapped_count: scrappedCount,
            not_scrapped_count: notScrappedCount,
            total_count: totalRows,
          };

          set_scrapping_progress(progress);
        } else {
          console.warn("No data found in the specified range");
        }

        isDataFetched.current = true; // Set to true after fetching data
      } else {
        console.error("Failed to fetch metadata: ", result);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      set_initial_loader_open(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [campaignId]);

  useEffect(() => {
    console.log("selected_scrapper_option ", selected_scrapper_option);
    console.log("scrape_method_check ", scrape_method_check);
  }, [selected_scrapper_option, scrape_method_check]);

  const saveRows = () => {
    setFinalScrapeSummaryModal(false);
    setShowScrapeSummaryModal(false);
    set_saving(true);
    console.log("rows");
    console.log(rows);
    fetch(getFullApiUrl(SAVE_BLOG_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, blog_urls_data: rows },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        set_saving(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        // console.log("Data not got saved");
        set_saving(false);
      });
    saveProjectData();
  };

  const saveProjectData = () => {
    fetch(getFullApiUrl(UPDATE_INDIVIDUAL_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, scrap_blog_pages: 1 },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
      })
      .catch((error) => {
        //console.error('Error:', error);
        // console.log("Data not got saved");
      });
  };
  const [isModalLoading, setIsModalLoading] = useState(false);

  const changeListofURLTextArea = (event) => {
    setListofURL(event.target.value);
    setOriginalUrls(event.target.value);
  };
  const deleteBlogUrlsData = async () => {
    try {
      const response = await fetch(getFullApiUrl(DELETE_BLOG_URLS_DATA), {
        method: "POST",
        body: JSON.stringify({ campaign_id: campaignId }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error deleting blog URLs data:", error);
    }
  };
  const saveRowsFromTextArea = async (data) => {
    setIsModalLoading(true); // Set loading state to true
    await deleteBlogUrlsData();
    const threshold = 10000; // Define the chunk size
    const totalLength = data.length; // Total number of rows in the data
    const promises = []; // Array to store promises for parallel processing

    // Function to save chunked data to the backend
    const saveChunk = async (chunk, maxRange) => {
      try {
        const response = await fetch(
          LAMBDA_FUNCTION_TO_SAVE_BLOG_DATA_BY_RANGE,
          {
            method: "POST",
            body: JSON.stringify({
              request_data: {
                campaign_id: campaignId,
                blog_urls_data: chunk,
                max_value: maxRange,
                bucket_name: BUCKET_NAME,
              },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.error(
            `Error saving data for max_value ${maxRange}:`,
            errorData
          );
          return;
        }

        const responseData = await response.json();
        console.log(`Data saved for max_value ${maxRange}:`, responseData);
      } catch (error) {
        console.error(`Error saving data for max_value ${maxRange}:`, error);
      }
    };

    // Split data into chunks and create promises for parallel saving
    for (
      let startIndex = 0;
      startIndex < totalLength;
      startIndex += threshold
    ) {
      const chunk = data.slice(startIndex, startIndex + threshold); // Get the chunk of data
      const maxRange = startIndex + threshold; // Update the max range for the current chunk
      promises.push(saveChunk(chunk, maxRange)); // Add the saveChunk promise to the array
    }

    // Wait for all promises to resolve
    await Promise.all(promises);

    setIsModalLoading(false); // Stop loading state
    setSavingRowsFromTextArea(true); // Set save status to true
  };

  const createScrappingJobsTableAndScrapeUrls = async () => {
    set_scrape_method_check({
      BeautifulSoup: false,
      Webcache: false,
      Selenium: false,
    });

    if (listofURL.trim() === "") {
      createScrappingJobsTable();
      return;
    }

    await createScrappingJobsTable();
  };

  const createScrappingJobsTable = async () => {
    set_show_scrap_progress_section(false);
    const existingUrlsData = rows.map((row) => row);
    const updatedUrls = listofURL
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");

    var row_objects_array = [];
    var row_object = {};

    for (let i = 0; i < updatedUrls.length; i++) {
      let matching_rows = existingUrlsData.filter(
        (item) => item["url"] === updatedUrls[i]
      );
      if (matching_rows.length > 0) {
        const first_matching_row = matching_rows[0];
        const updatedRow = { ...first_matching_row, id: i + 1, url_id: i + 1 };
        row_objects_array.push(updatedRow);
      } else {
        row_object = {
          id: i + 1,
          url_id: i + 1,
          url: updatedUrls[i],
          scraped: "not_scraped",
          scrapedData: "",
        };
        row_objects_array.push(row_object);
      }
    }

    // Update the `scraped` property
    const updatedRowObjectsArray = row_objects_array.map((row) => {
      row.scraped = "not_scraped";
      return row;
    });

    set_table_data(updatedRowObjectsArray);
    setRows(updatedRowObjectsArray);

    if (listofURL.trim() !== "") {
      await handle_show_after_create_should_we_scrape_modal_open();
      await saveRowsFromTextArea(updatedRowObjectsArray); // Chunked saving

      // Fetch updated file names
      const response = await fetch(
        LAMBDA_FUNCTION_TO_GET_BLOG_DATA_LENGTH_AND_FILE_NAME,
        {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          const { file_names } = result.message;
          setFileNames(file_names);
          console.log("filename ", file_names);
        } else {
          console.error("Failed to fetch updated file names: ", result);
        }
      } else {
        console.error(
          "HTTP error fetching updated file names: ",
          response.status
        );
      }
    } else {
      await deleteBlogUrlsData();
    }
  };

  //********************************************************** Scrapping section Start

  const handleScrapAllPages = async () => {
    console.log("handleScrapAllPages");
    handle_show_after_create_should_we_scrape_modal_close();
    set_show_selectScrapOption_Section(true);
    set_selected_scrapper_option("BeautifulSoup");
    console.log(selected_scrapper_option);
  };

  const handleRemainingUrlToScrape = async () => {
    setShowScrapeSummaryModal(false);

    console.log("handleRemainingUrlToScrape ", selected_scrapper_option);
    await validate_scrape_urls("Run Scrapper");
  };

  const handle_should_we_scrape_urls_close = () => {
    set_show_should_we_scrape_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setCreditWeekLimitAfterDeduction(0);
    setTotalCreditAfterDeduction(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const validate_scrape_urls = async (button) => {
    try {
      const deductionPoint = await getCreditPointToDeductByButton(button);
      console.log(table_data);

      const notScrapedUrlsList = table_data
        .filter((row) => row.scraped === "not_scraped")
        .map((row) => row.url);
      const notScrappedCount = notScrapedUrlsList.length;
      console.log(notScrappedCount);
      const credits_to_be_deducted = parseFloat(
        (notScrappedCount * parseFloat(deductionPoint)).toFixed(3)
      );

      const data = await fetchpointsDetails(credits_to_be_deducted);
      const threshold_value = data.threshold_value;
      setDeductionPoints(credits_to_be_deducted);

      // Check conditions separately
      let shouldOpenModal = false;

      if (
        !data.does_owner_have_enough_credits &&
        !data.does_user_have_enough_credits_week_limit
      ) {
        setErrorMassageForTotalCredits("Insufficient Credit Points");
        setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
        shouldOpenModal = true;
      } else if (!data.does_owner_have_enough_credits) {
        setErrorMassageForTotalCredits("Insufficient Credit Points");
        shouldOpenModal = true;
      } else if (!data.does_user_have_enough_credits_week_limit) {
        setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
        shouldOpenModal = true;
      } else if (credits_to_be_deducted === 0) {
        setErrorMessage(
          "There are no blog URLs available for scraping at the moment."
        );
        shouldOpenModal = true;
      }

      // Check if modal should be opened or execute processScrapeAndUpdate
      if (shouldOpenModal) {
        set_show_should_we_scrape_urls_modal(true);
      } else if (threshold_value > credits_to_be_deducted) {
        await api_call_scrape_urls();
        await updateCreditPoints(credits_to_be_deducted);
      } else {
        set_show_should_we_scrape_urls_modal(true);
      }
    } catch (error) {
      console.error("Error in validate_scrape_urls:", error);
    }
  };

  const [finalScrapeSummaryModal, setFinalScrapeSummaryModal] = useState(false);
  const api_call_scrape_urls = async () => {
    updateScrapeMethodCheck(selected_scrapper_option);
  };

  const [scrapping_progress_bar, set_scrapping_progress_bar] = useState(false);

  const generateRangesFromFileName = (fileName, batchSize, dataLength) => {
    const [start, end] = fileName.split("-").map(Number); // Extract the range from fileName
    const ranges = [];

    // Adjust end based on dataLength to avoid generating extra ranges
    const adjustedEnd = Math.min(end, dataLength);

    for (let i = start; i <= adjustedEnd; i += batchSize) {
      const rangeEnd = Math.min(i + batchSize - 1, adjustedEnd); // Calculate the end of each range
      ranges.push(`${i}-${rangeEnd}`); // Push the calculated range
    }

    return ranges;
  };

  const [show_scrap_progress_section, set_show_scrap_progress_section] =
    useState(false);

  const proceedWithScraping = async () => {
    set_show_should_we_scrape_urls_modal(false);
    set_show_selectScrapOption_Section(false);
    set_show_scrap_progress_section(true);
    set_scrapping_progress_bar(true);

    // Fetch updated file names and data length
    const response = await fetch(
      LAMBDA_FUNCTION_TO_GET_BLOG_DATA_LENGTH_AND_FILE_NAME,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaign_id: campaignId,
          bucket_name: BUCKET_NAME,
        }),
      }
    );

    if (!response.ok) {
      console.error(
        "Error fetching file names and data length:",
        response.status
      );
      return;
    }

    const result = await response.json();
    const { file_names, data_length } = result.message; // Assume data_length is also returned

    // Filter out not-scraped URLs
    const notScrapedUrls = table_data
      .filter((row) => row.scraped === "not_scraped")
      .map((row) => ({
        url_id: row.url_id,
        file_name: file_names.find((file) => isUrlIdInRange(row.url_id, file)),
      }));

    const updates = new Map(); // Intermediate storage for updates
    console.log("notScrapedUrls", notScrapedUrls);

    // Function to send request to Lambda and store the updates
    const scrapeFile = async (fileName, range) => {
      const requestData = {
        campaign_id: campaignId,
        scraper_option: selected_scrapper_option,
        file_name: fileName,
        range: range, // Add the calculated range here
        bucket_name: BUCKET_NAME,
      };

      try {
        const response = await fetch(LAMBDA_FUNCTION_TO_SCRAPE_BLOG_DATA, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        const result = await response.json();
        const scrapedUrlIds = result.successfully_scraped_url_ids || [];

        // Update table_data immediately after receiving scrapedUrlIds
        updateTableDataFromScrapedIds(scrapedUrlIds);

        // Store updates in the intermediate Map
        updates.set(fileName, scrapedUrlIds);

        return scrapedUrlIds;
      } catch (error) {
        console.error(`Error scraping file: ${fileName}`, error);
        return [];
      }
    };

    // Queue processing with a maximum of 10 concurrent requests
    const maxConcurrentRequests = 1;
    const scrapePromises = [];

    // Helper function to process the queue
    const processQueue = async (fileName, ranges) => {
      for (const range of ranges) {
        // Wait until there are fewer than maxConcurrentRequests active
        while (scrapePromises.length >= maxConcurrentRequests) {
          await Promise.race(scrapePromises); // Wait for the first promise to resolve
        }

        const promise = scrapeFile(fileName, range);
        scrapePromises.push(promise);

        // Clean up resolved promises from the queue
        promise.finally(() => {
          scrapePromises.splice(scrapePromises.indexOf(promise), 1);
        });
      }
    };

    // For each file, generate ranges and process the queue
    const batchSize = 10000; // Example batch size (you can set it dynamically if needed)

    for (const file of file_names) {
      const ranges = generateRangesFromFileName(file, batchSize, data_length); // Generate ranges for each file with data_length
      await processQueue(file, ranges); // Process ranges for the current file
    }

    // Wait for all scrape promises to complete before finalizing the scraping flow
    await Promise.all(scrapePromises);

    // Finalize the scraping flow
    await handleAfterScrapeModalHandle();
  };

  const updateTableDataFromScrapedIds = (scrapedUrlIds) => {
    const updatedTableData = [...table_data];

    // Process each scraped URL ID and apply it to the table data
    scrapedUrlIds.forEach((url_id) => {
      const rowIndex = updatedTableData.findIndex(
        (row) => row.url_id === url_id
      );
      if (rowIndex !== -1) {
        updatedTableData[rowIndex].scraped = "scraped";
      }
    });

    // Update the state with the new table data
    set_table_data(updatedTableData);
    setRows(updatedTableData);
  };

  const calculateScrapingCounts = async () => {
    const scrapped_count = table_data.filter(
      (row) => row.scraped === "scraped" || row.scraped === "edited"
    ).length;
    const not_scrapped_count = table_data.length - scrapped_count;

    // Update the scraping progress state with the counts
    set_scrapping_progress({
      scrap_processed: table_data.length,
      scrapped_count,
      not_scrapped_count,
      total_count: table_data.length,
    });

    // Update the scrape summary state with the counts
    setScrapeSummary({
      scraped: scrapped_count,
      notScraped: not_scrapped_count,
    });

    return { scrapped_count, not_scrapped_count };
  };

  useEffect(() => {
    if (table_data.length > 0) {
      calculateScrapingCounts();
    }
  }, [table_data]);

  // Utility function to determine if a URL ID is in a file's range
  const isUrlIdInRange = (url_id, file_name) => {
    const [start, end] = file_name.split("-").map(Number);
    console.log("[start, end] ", [start, end]);
    return url_id >= start && url_id <= end;
  };

  // Update the table data based on the stored updates

  /*
  // Update the table data based on the successfully scraped URLs
  function updateTableData(result) {
    const successfullyScrapedUrlIds = result.successfully_scraped_url_ids;
  
    const updatedTableData = table_data.map((row) => {
      if (successfullyScrapedUrlIds.includes(row.url_id)) {
        return { ...row, scraped: "scraped" };
      }
      return row;
    });
  
    set_table_data(updatedTableData);
    setRows(updatedTableData);
  }
  */

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  // Function to delay execution for a specified duration

  const handleAfterScrapeModalHandle = async () => {
    set_scrapping_progress_bar(false);
    const scrapped_count = table_data.filter(
      (row) => row.scraped === "scraped" || row.scraped === "edited"
    ).length;
    const not_scrapped_count = table_data.length - scrapped_count;
    if (not_scrapped_count === 0) {
      setShowScrapeSummaryModal(false);
      setFinalScrapeSummaryModal(true);
    } else {
      let nextOption;
      if (!scrape_method_check.BeautifulSoup) {
        nextOption = "BeautifulSoup";
      } else if (!scrape_method_check.Webcache) {
        nextOption = "Webcache";
      } else if (!scrape_method_check.Selenium) {
        nextOption = "Selenium";
      }

      // Update the selected_scrapper_option to the next option
      if (nextOption) {
        set_selected_scrapper_option(nextOption);
      }
      setShowScrapeSummaryModal(true);
      setFinalScrapeSummaryModal(false);
    }
  };

  /*
  function updateTableData(result) {
    const successfullyScrapedUrlIds = result.successfully_scraped_url_ids;

    // Map through the existing table data and update the `scraped` status only for the matching `url_id`
    const updatedTableData = table_data.map((row) => {
      if (successfullyScrapedUrlIds.includes(row.url_id)) {
        // Only update the `scraped` status and keep the rest of the data unchanged
        return { ...row, scraped: "scraped" };
      }
      // Return the row unchanged if `url_id` doesn't match
      return row;
    });

    // Update the state with the new table data
    set_table_data(updatedTableData);
    setRows(updatedTableData);
  }
    */

  const handleScrapperOptionChange = (event) => {
    const selectedOption = event.target.value;

    // Update the state with the selected scraper option
    set_selected_scrapper_option(selectedOption);

    if (selectedOption === "Selenium") {
      console.log("Note: Using Selenium scraper takes much time");
    }
  };

  const processScrapeClientUrlAndSave = async () => {
    await handleScrapAllPages();
  };

  const processScrapeAndUpdate = async () => {
    await api_call_scrape_urls();
    await updateCreditPoints(deductionPoints);
  };

  //********************************************************** Scrapping section End

  //getDataBy_url_id(url_id)
  const getDataBy_url_id = (url_id) => {
    var row = {};
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]["url_id"] == url_id) {
        row = rows[i];
        break;
      }
    }
    return row;
  };

  const saveDataBy_url_id = (row_to_be_updated) => {
    var url_id = row_to_be_updated["url_id"];
    var pos_of_found_data = -1;
    //var row = {}
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]["url_id"] == url_id) {
        pos_of_found_data = i;
        break;
      }
    }
    if (pos_of_found_data > -1) {
      var updatedRows = rows.map((row) => row);
      updatedRows[pos_of_found_data] = row_to_be_updated;
      setRows(updatedRows);
    }
  };

  //********************************************************** Scrap Data Edit section Start
  // Helper function to determine the min_max_range based on the url_id
  const getMinMaxRange = (url_id) => {
    const rangeSize = 10000;
    const minRange = Math.floor((url_id - 1) / rangeSize) * rangeSize + 1;
    const maxRange = minRange + rangeSize - 1;
    return `${minRange}-${maxRange}`;
  };

  const handleEditScrapData = async (url_data) => {
    try {
      // Lambda function URL
      const lambdaUrlToGetData =
        "https://8letdms8ni.execute-api.us-east-2.amazonaws.com/";

      // Get the min_max_range based on the url_id
      const minMaxRange = getMinMaxRange(url_data.url_id);
      console.log("minMaxRange ", minMaxRange);
      // Fetch scraped data from the Lambda function
      const response = await fetch(
        LAMBDA_FUNCTION_TO_GET_INDIVIDUAL_BLOG_DATA,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId, // Ensure campaign_id is available
            url_id: url_data.url_id,
            bucket_name: BUCKET_NAME,
            file_name: minMaxRange, // Send the min_max_range value
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        // Extract the scrapedData from the response message
        const scrapedData = data.message.scrapedData || "";

        // Update the state with the fetched data
        setEditScrappedData({
          url: url_data.url,
          url_id: url_data.url_id,
          scrapedData: scrapedData,
        });
        setShowEditScrapSection(true);
      } else {
        console.error("Failed to fetch scraped data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching scraped data:", error);
    }
  };

  const changeEditScrappedData = (event) => {
    var data = { ...editScrappedData };
    data["scrapedData"] = event.target.value;
    setEditScrappedData(data);
  };

  const saveEditableScrappedData = async (url_id) => {
    try {
      const minMaxRange = getMinMaxRange(url_id);
      console.log("minMaxRange ", minMaxRange);
      const lambdaUrlToSaveData =
        "https://b62j3bsy25.execute-api.us-east-2.amazonaws.com/";

      const response = await fetch(
        LAMBDA_FUNCTION_TO_SAVE_INDIVIDUAL_BLOG_DATA,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            scrapedData: editScrappedData["scrapedData"],
            campaign_id: campaignId,
            url_id: url_id,
            bucket_name: BUCKET_NAME,
            file_name: minMaxRange,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        updateEditableTableData(url_id);
        handleClose(); // Close the modal
        // You might want to refresh or update the list of rows here
      } else {
        console.error("Failed to save scraped data:", data.error);
      }
    } catch (error) {
      console.error("Error saving scraped data:", error);
    }
  };

  const updateEditableTableData = (url_id) => {
    set_table_data((prevData) =>
      prevData.map((row) =>
        row.url_id === url_id ? { ...row, scraped: "edited" } : row
      )
    );
  };

  const handleClose = () => {
    setShowEditScrapSection(false);
    set_show_selectScrapOption_Section(false);
  };

  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getBlackListDataOfCampaign = async () => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_BLACK_LIST_DATA_OF_CAMPAIGN),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            request_data: {
              campaign_id: campaignId,
            },
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setBlackListData(result.data); // Update state with the fetched data
        // setIsFilterApplied(true); // Update state to indicate filter is applied
        console.log("Fetched blacklist data:", result.data);
      } else {
        console.error(
          "Failed to fetch blacklist data:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };
  const getBlackListDataOfWorksoace = async () => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_BLACK_LIST_DATA_OF_WORKSPACE),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            request_data: {
              workspace_id: campaignData.workspace_id,
            },
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setBlackListData(result.data); // Update state with the fetched data
        // setIsFilterApplied(true); // Update state to indicate filter is applied
        console.log("Fetched blacklist data:", result.data);
      } else {
        console.error(
          "Failed to fetch blacklist data:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };
  const toggleFilter = () => {
    getBlackListDataOfCampaign();
    setIsFilterEnabled(!isFilterEnabled);
  };
  const handleButtonClick = async () => {
    toggleFilter();

    if (isFilterEnabled) {
      console.log("Filter enabled");
      const urlsArray = listofURL
        .split("\n")
        .map((url) => url.trim())
        .filter((url) => url !== "");
      const filteredUrls = applyFilters(urlsArray);
      setListofURL(filteredUrls.join("\n"));

      // Update table_data and rows with the filtered URLs
      const updatedRowObjectsArray = filteredUrls.map((url, index) => {
        return {
          id: index + 1,
          url_id: index + 1,
          url: url,
          scraped: "not_scraped",
          scrapedData: "",
        };
      });

      set_table_data(updatedRowObjectsArray);
      setRows(updatedRowObjectsArray);
      // await saveRowsFromTextArea(updatedRowObjectsArray);
    } else {
      console.log("Filter disabled");
      setListofURL(originalUrls);

      // Update table_data and rows with the full list of URLs
      const updatedUrls = originalUrls
        .split("\n")
        .map((url) => url.trim())
        .filter((url) => url !== "");
      const updatedRowObjectsArray = updatedUrls.map((url, index) => {
        return {
          id: index + 1,
          url_id: index + 1,
          url: url,
          scraped: "not_scraped",
          scrapedData: "",
        };
      });

      set_table_data(updatedRowObjectsArray);
      setRows(updatedRowObjectsArray);
      // await saveRowsFromTextArea(updatedRowObjectsArray);
    }
  };

  const applyFilters = (urls) => {
    let filteredUrls = urls.slice(); // Create a copy of the array for filtering
    const removalDetails = { removeInvalidUrls: [] }; // Object to track removal details per filter

    // Remove duplicates
    if (
      blackListData &&
      blackListData[0].remove_urls_options.includes("removeDuplicates")
    ) {
      const uniqueUrls = [...new Set(filteredUrls)];
      removalDetails.removeDuplicates = filteredUrls.filter(
        (url) => !uniqueUrls.includes(url)
      );
      filteredUrls = uniqueUrls;
    }

    // Filter out invalid URLs
    filteredUrls = filteredUrls.filter((url) => {
      try {
        new URL(url);
        return true;
      } catch (e) {
        removalDetails.removeInvalidUrls.push(url);
        return false;
      }
    });

    // Remove root-based domain URLs
    if (
      blackListData &&
      blackListData[0].remove_urls_options.includes("removeRootBasedDomain")
    ) {
      const nonRootBasedUrls = filteredUrls.filter((url) => {
        try {
          const domain = new URL(url).hostname;
          return domain.split(".").length > 2;
        } catch (e) {
          return false;
        }
      });
      removalDetails.removeRootBasedDomain = filteredUrls.filter(
        (url) => !nonRootBasedUrls.includes(url)
      );
      filteredUrls = nonRootBasedUrls;
    }

    // Remove URLs where the host is an IP address
    if (
      blackListData &&
      blackListData[0].remove_urls_options.includes("removeHostIsIP")
    ) {
      const nonIpUrls = filteredUrls.filter((url) => {
        try {
          const domain = new URL(url).hostname;
          return !/^\d{1,3}(\.\d{1,3}){3}$/.test(domain);
        } catch (e) {
          return false;
        }
      });
      removalDetails.removeHostIsIP = filteredUrls.filter(
        (url) => !nonIpUrls.includes(url)
      );
      filteredUrls = nonIpUrls;
    }

    // Trim URLs to root
    if (blackListData && blackListData[1].trim_url.includes("trimToRoot")) {
      filteredUrls = filteredUrls.map((url) => {
        try {
          const urlObj = new URL(url);
          return `${urlObj.protocol}//${urlObj.hostname}`;
        } catch (e) {
          return url;
        }
      });
    }

    // Trim URLs to the last folder
    if (
      blackListData &&
      blackListData[1].trim_url.includes("trimToLastFolder")
    ) {
      filteredUrls = filteredUrls.map((url) => {
        try {
          const urlObj = new URL(url);
          const pathParts = urlObj.pathname.split("/");
          if (pathParts.length > 2) {
            return `${urlObj.origin}/${pathParts.slice(0, -1).join("/")}/`;
          }
          return url;
        } catch (e) {
          return url;
        }
      });
    }

    // Trim URLs to a specific domain level (for example, the second-level domain)
    if (
      blackListData &&
      blackListData[1].trim_url.includes("trimToUrlsDomainLevel")
    ) {
      filteredUrls = filteredUrls.map((url) => {
        try {
          const urlObj = new URL(url);
          const domainParts = urlObj.hostname.split(".");
          if (domainParts.length > 2) {
            return `${urlObj.protocol}//${domainParts.slice(-2).join(".")}`;
          }
          return url;
        } catch (e) {
          return url;
        }
      });
    }

    // Clean URL tags
    if (blackListData && blackListData[1].trim_url.includes("cleanUrlTag")) {
      filteredUrls = filteredUrls.map((url) => {
        try {
          return url.split("?")[0].split("#")[0];
        } catch (e) {
          return url;
        }
      });
    }

    // Filter URLs based on TLDs
    if (blackListData && blackListData[2].filter_tlds.length > 0) {
      const allowedTLDs = blackListData[2].filter_tlds;
      const tldFilteredUrls = filteredUrls.filter((url) => {
        try {
          const domain = new URL(url).hostname;
          return !allowedTLDs.some((tld) => domain.endsWith(tld)); // Exclude URLs with these TLDs
        } catch (e) {
          // Handle invalid URLs gracefully by excluding them from the filtered list
          return false;
        }
      });
      removalDetails.filterTlds = filteredUrls.filter(
        (url) => !tldFilteredUrls.includes(url)
      );
      filteredUrls = tldFilteredUrls;
    }

    console.log("Filtered URLs: ", filteredUrls);
    console.log("Removed URLs: ", removalDetails);
    return filteredUrls;
  };

  const handleSkipScrapingMethod = () => {
    // Close the modal first
    setShowScrapeSummaryModal(false);

    // Use a timeout to ensure that the modal closes before updating the state
    setTimeout(() => {
      if (selected_scrapper_option) {
        set_scrape_method_check((prev) => {
          // Check if the current selected_scrapper_option is already true
          if (prev[selected_scrapper_option]) {
            return prev; // If already true, don't update
          }

          // Update the current selected scraper option to true
          const updatedCheck = {
            ...prev,
            [selected_scrapper_option]: true,
          };

          // Check if all methods are now true
          const allMethodsSelected = Object.values(updatedCheck).every(
            (v) => v
          );

          // If all methods are true, open the finalScrapeSummaryModal
          if (allMethodsSelected) {
            setFinalScrapeSummaryModal(true);
          } else {
            // Determine the next scraper option to be selected
            let nextOption;
            if (!updatedCheck.BeautifulSoup) {
              nextOption = "BeautifulSoup";
            } else if (!updatedCheck.Webcache) {
              nextOption = "Webcache";
            } else if (!updatedCheck.Selenium) {
              nextOption = "Selenium";
            }

            // Update the selected_scrapper_option to the next option
            if (nextOption) {
              set_selected_scrapper_option(nextOption);
              setShowScrapeSummaryModal(true);
            }

            // Reopen the modal after updating the state
          }

          return updatedCheck;
        });
      }
    }, 300); // Adjust the timeout duration if needed
  };

  useEffect(() => {
    // Automatically load blacklist data on component mount
    const loadBlackListData = async () => {
      if (isFilterEnabled) {
        await getBlackListDataOfCampaign();
      }
    };

    loadBlackListData();
  }, []);
  const handleGoToNextPage = () => {};
  return (
    <div className="page_Section">
      <div className="leftSection_withMenu">
        <LeftSectionWithMenu />
      </div>
      <div className="rightSection_withMenu">
        <div className="headerSection_withMenu">
          <Header onToggleTheme={toggleTheme} />
        </div>
        <div className="bodySection campaign_bodysection">
          <FloatingMenuBar campaignId={campaignId} />

          <Breadcrumbs
            id="Breadcrumbs"
            separator={<NavigateNext />}
            aria-label="breadcrumb"
          >
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to="/"
                color="inherit"
                underline="hover"
              >
                Home
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_teams`}
                color="inherit"
                underline="hover"
              >
                My Teams
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_team_workspaces/${campaignData.team_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.team_name} Workspaces
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace/${campaignData.workspace_id}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_workspace_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.workspace_name} Campaigns
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/solutions/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                {campaignData.campaign_name}
              </Link>
            </div>
            <div className="breadcrumb_text">
              <Link
                component={RouterLink}
                to={`/my_campaign/${campaignId}`}
                color="inherit"
                underline="hover"
              >
                All-In-One
              </Link>
            </div>
            <div className="breadcrumb_text">
              Prepare Prospects for Analysis
            </div>
          </Breadcrumbs>

          <div className="prepare_prospects_container">
            <div className="prepare_prospects_heading_container">
              <div className="prepare_prospects_heading_text">
                Prepare Prospects for Analysis
              </div>
            </div>

            {/* Subdescription Section */}
            <div className="prepare_prospects_sub_heading_container">
              <div className="prepare_prospects_sub_heading_text">
                <div>
                  🛠️ Use the 'Create Scraping Job' button to initiate scraping
                  for the given prospects.
                </div>
                <div>
                  🚫 Click the 'Black List' button to filter out blacklisted
                  elements from your list.
                </div>
                <div>
                  🔄 Press the 'Scrape' button to process and scrape the
                  existing URLs in the table.
                </div>
              </div>
            </div>
          </div>

          {initial_loader_open == true ? (
            <div style={{ position: "relative", width: "100%", height: "70%" }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="blog_url_function_container">
              <TextareaAutosize
                value={listofURL}
                onChange={changeListofURLTextArea}
                placeholder="Enter Blog URLs one by one in each line"
                minRows={5}
                overflowY="auto"
                maxRows={15}
                className="text_area_of_link_insertion blog_url_text_area"
                readOnly={!isFilterEnabled} // Conditionally set the readOnly prop
              />

              <div className="blog_url_create_scrape_button_container">
                <Box>
                  <div className="blog_url_black_list_button_container">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleButtonClick()}
                      style={{ marginRight: "8px" }}
                      startIcon={
                        isFilterEnabled ? (
                          <IoFilterOutline />
                        ) : (
                          <MdOutlineFilterListOff />
                        )
                      }
                    >
                      {isFilterEnabled
                        ? "Filter Using Black List"
                        : "Unfilter URLs"}
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => createScrappingJobsTableAndScrapeUrls()}
                    style={{ marginRight: "8px" }}
                  >
                    Create Scrapping Jobs
                  </Button>
                </Box>
              </div>
              <div className="blog_url_save_button_container">
                <div className="blog_page_scrapping_text_linear_bar_container">
                  {show_scrap_progress_section ? (
                    <div style={{ width: "500px", position: "relative" }}>
                      <LinearProgress
                        sx={{
                          height: "5px",
                          "& .MuiLinearProgress-barColorPrimary": {
                            backgroundColor: "#26d426",
                          },
                          "& .MuiLinearProgress-bar2Buffer": {
                            backgroundColor: "#f15757",
                          },
                          "& .css-8ub8io-MuiLinearProgress-dashed": {
                            backgroundImage:
                              "radial-gradient(rgb(255 255 255) 0%, rgb(0 127 255) 16%, #ffffff 42%);",
                          },
                        }}
                        variant="buffer"
                        value={
                          (100 * scrapping_progress["scrapped_count"]) /
                          scrapping_progress["total_count"]
                        }
                        valueBuffer={
                          (100 * scrapping_progress["scrap_processed"]) /
                          scrapping_progress["total_count"]
                        }
                      />

                      <Typography
                        style={{
                          position: "absolute",
                          color: "#00f",
                          top: -22,
                          left:
                            Math.floor(
                              (100 * scrapping_progress["scrap_processed"]) /
                                scrapping_progress["total_count"]
                            ).toFixed(0) /
                              2 +
                            "%",
                          transform: "translateX(-25%)",
                        }}
                      >
                        {scrapping_progress["total_count"] == 0
                          ? `0%`
                          : `${Math.floor(
                              (100 * scrapping_progress["scrap_processed"]) /
                                scrapping_progress["total_count"]
                            )}%`}
                      </Typography>

                      <Typography
                        style={{
                          position: "absolute",
                          color: "#a27608",
                          top: -22,
                          right:
                            scrapping_progress["total_count"] == 0
                              ? "0%"
                              : (100 -
                                  Math.floor(
                                    (100 *
                                      scrapping_progress["scrap_processed"]) /
                                      scrapping_progress["total_count"]
                                  ).toFixed(0)) /
                                  2 +
                                "%",
                          transform: "translateX(+25%)",
                        }}
                      >
                        {scrapping_progress["total_count"] == 0
                          ? `0%`
                          : `${
                              100 -
                              Math.floor(
                                (100 * scrapping_progress["scrap_processed"]) /
                                  scrapping_progress["total_count"]
                              )
                            }%`}
                      </Typography>

                      <Typography
                        style={{
                          position: "absolute",
                          color: "#039f03",
                          top: 0,
                          left:
                            (100 * scrapping_progress["scrapped_count"]) /
                              scrapping_progress["total_count"] /
                              2 <
                            5
                              ? (100 * scrapping_progress["scrapped_count"]) /
                                  scrapping_progress["total_count"] /
                                  2 -
                                1.5 +
                                "%"
                              : (100 * scrapping_progress["scrapped_count"]) /
                                  scrapping_progress["total_count"] /
                                  2 +
                                "%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        {`${Math.floor(
                          (100 * scrapping_progress["scrapped_count"]) /
                            scrapping_progress["total_count"]
                        ).toFixed(0)}%`}
                      </Typography>
                      <Typography
                        style={{
                          position: "absolute",
                          color: "#f00",
                          top: 0,
                          left:
                            ((100 * scrapping_progress["scrapped_count"]) /
                              scrapping_progress["total_count"] +
                              (100 * scrapping_progress["not_scrapped_count"]) /
                                scrapping_progress["total_count"]) /
                              2 <
                            5
                              ? (100 * scrapping_progress["scrapped_count"]) /
                                  scrapping_progress["total_count"] +
                                (100 *
                                  scrapping_progress["not_scrapped_count"]) /
                                  scrapping_progress["total_count"] /
                                  2 +
                                2.5 +
                                "%"
                              : (100 * scrapping_progress["scrapped_count"]) /
                                  scrapping_progress["total_count"] +
                                (100 *
                                  scrapping_progress["not_scrapped_count"]) /
                                  scrapping_progress["total_count"] /
                                  2 +
                                "%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        {scrapping_progress["total_count"] == 0
                          ? `0%`
                          : `${Math.floor(
                              (100 * scrapping_progress["not_scrapped_count"]) /
                                scrapping_progress["total_count"]
                            ).toFixed(0)}%`}
                      </Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                  {scrapping_progress_bar ? (
                    <div className="blog_page_scrape_text_progresbar_container">
                      <div>Scraping Progress ....................</div>
                      <CircularProgress />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="blog_url_table_container">
                <TableWithPagination
                  data={table_data}
                  file_name={"prepare_prospects_for_analysis_data"}
                  fields={[
                    {
                      type: "id",
                      field: "url_id",
                      headerName: "",
                      minWidth: 1,
                      width: 1,
                      maxWidth: 90,
                    },
                    {
                      type: "field",
                      field: "url",
                      headerName: "URL Address",
                      search_type: "text_search",
                      width: 800,
                      minWidth: 300,
                      maxWidth: 1500,
                    },
                    {
                      type: "field",
                      field: "scraped",
                      headerName: "Is Scraped ?",
                      search_type: "text_search",
                      width: 220,
                      minWidth: 300,
                      maxWidth: 1500,
                    },
                    {
                      type: "action",
                      field: "action",
                      headerName: "",
                      width: 65,
                      renderCell: (params) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <Tooltip title="Edit scrap data">
                              <IconButton
                                className="table_action_icon_link_exchange_row"
                                onClick={() => handleEditScrapData(params)}
                              >
                                <FaEdit />
                              </IconButton>
                            </Tooltip>
                          </div>
                        );
                      },
                    },
                  ]}
                  bulk_actions={[
                    /*  {
                        action: "save",
                        renderCell: (params) => {
                          return (
                            <>
                              {saving ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={true} // Disabled while saving
                                  startIcon={<CircularProgress size={20} />} // Show CircularProgress while saving
                                >
                                  Saving...
                                </Button>
                              ) : (
                                <Tooltip title="Save">
                                  <IconButton
                                    onClick={() => {
                                      saveRows()
                                    }}
                                  >
                                    <SaveIcon className="table_action_icon_link_exchange_row" />
                                  </IconButton>
                                </Tooltip>
                              )}
  
  
                            </>
                          );
                        },
                      },
                    */
                    {
                      action: "scrap",
                      renderCell: (params) => {
                        return (
                          <Tooltip title="scrap">
                            <IconButton
                              onClick={() => {
                                handleScrapAllPages();
                              }}
                            >
                              <GiFlatHammer className="table_action_icon_link_exchange" />
                            </IconButton>
                          </Tooltip>
                        );
                      },
                    },
                  ]}
                />
              </div>
              <div className="next_step_button_container">
                <Button
                  variant="contained"
                  size="small"
                  component={RouterLink}
                  to={`/my_campaign/${campaignId}/bulk_process`}
                  color="primary"
                >
                  Next Step 👉
                </Button>
              </div>
            </div>
          )}
          <Modal
            open={show_selectScrapOption_Section || showEditScrapSection}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box id="model_box">
              <div className="close_button_of_modal_container">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>

              {show_selectScrapOption_Section ? (
                <div className="blog_url_modal_content_cantainer">
                  <div className="blog_url_modal_header">
                    <div className="blog_url_modal_title">
                      🔍 Start Your Prospect Scraping
                    </div>
                    <div className="blog_url_modal_subheading">
                      <div>We are ready to begin scraping your prospects.</div>
                      <div>
                        Click on the 🚀 "Run Scraping" button to get started!
                      </div>
                    </div>
                  </div>

                  <div className="blog_url_scrapped_button_container">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => validate_scrape_urls("Run Scrapper")}
                      style={{ marginRight: "8px" }}
                    >
                      🚀 Run Scraping
                    </Button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {showEditScrapSection ? (
                <div className="edit_scrape_modal_container">
                  <div className="edit_scrape_modal_header">
                    {editScrappedData["url_id"]} : {editScrappedData["url"]}
                  </div>
                  <TextareaAutosize
                    value={editScrappedData["scrapedData"]}
                    onChange={changeEditScrappedData}
                    placeholder="Edit your scrapped text"
                    minRows={5}
                    style={{
                      width: "100%",
                      overflow: "auto",
                      borderRadius: "5px",
                      height: "85%",
                    }}
                  />

                  <div className="edit_scrape_modal_button_container">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() =>
                        saveEditableScrappedData(editScrappedData["url_id"])
                      }
                      style={{ marginRight: "8px" }}
                    >
                      Save Scrap Text
                    </Button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </Box>
          </Modal>

          {/* credit deduction */}
          <Modal
            open={show_should_we_scrape_urls_modal}
            onClose={handle_should_we_scrape_urls_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="LinkExchange_model_box">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={handle_should_we_scrape_urls_close}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="LinkExchange_modal_header">Current Credits</div>

              <div className="LinkExchange_modal_text_heading">
                Before proceeding with this action, please review the following:
              </div>
              <div className="LinkExchange_modal_text">
                Available Credits:
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForTotalCredits ? "red" : "inherit",
                    }}
                  >
                    {totalCreditPoints}
                  </div>
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left:
                <div className="LinkExchange_modal_number">
                  <div
                    style={{
                      color: errorMassageForCreditWeekLimits
                        ? "red"
                        : "inherit",
                    }}
                  >
                    {creditsPointsLimit}
                  </div>
                </div>
              </div>
              {errorMessage !==
                "There are no blog URLs available for scraping at the moment." && (
                <div className="LinkExchange_modal_text_deduct">
                  Performing this action will deduct{" "}
                  <div className="LinkExchange_modal_deduct_number">
                    {!isNaN(deductionPoints) ? deductionPoints : 0} credits
                  </div>{" "}
                  from your balance.
                </div>
              )}
              {errorMessage ||
              errorMassageForTotalCredits ||
              errorMassageForCreditWeekLimits ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontFamily: "monospace",
                    fontSize: "larger",
                  }}
                >
                  <div>{errorMassageForTotalCredits}</div>
                  <div>{errorMassageForCreditWeekLimits}</div>
                  <div>{errorMessage}</div>
                </div>
              ) : (
                <>
                  <div className="LinkExchange_modal_header">
                    After Deduction
                  </div>
                  <div className="LinkExchange_modal_text">
                    Balance Credits:
                    <div className="LinkExchange_modal_number">
                      {totalCreditAfterDeduction}
                    </div>
                  </div>
                  <div className="LinkExchange_modal_text">
                    Weekly Credits Limit Left:
                    <div className="LinkExchange_modal_number">
                      {creditsWeekLimitAfterDeduction}
                    </div>
                  </div>
                  <div className="LinkExchange_form_group">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={processScrapeAndUpdate}
                      color="primary"
                    >
                      Confirm Action
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </Modal>

           {/* Scrape Prospects URLs

          <Modal
            open={showScrapeSummaryModal}
            onClose={() => setShowScrapeSummaryModal(false)}
            aria-labelledby="modal-scrape-summary-title"
            aria-describedby="modal-scrape-summary-description"
          >
            <Box id="LinkExchange_model_box">
              <IconButton
                onClick={() => setShowScrapeSummaryModal(false)}
                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <CloseIcon />
              </IconButton>

              <div className="LinkExchange_modal_header">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  Scraping Summary 📊
                </Typography>
              </div>

              {scrapeSummary.notScraped > 0 ? (
                <>
                  <div className="blog_url_modal_summuary_content_container">
                    <div className="blog_url_modal_header">
                      <div className="blog_url_modal_title">
                        The scraping process is complete.
                      </div>
                      <div className="blog_url_modal_subheading">
                        <div>
                          We successfully scraped {scrapeSummary.scraped} URLs,
                          but unfortunately, there were issues with{" "}
                          {scrapeSummary.notScraped} URLs. 🛠️
                        </div>
                        <div>
                          You can review the results and choose to proceed to
                          the next step by clicking 🚀{" "}
                          <strong>"Next Step 👉"</strong>.
                        </div>
                      </div>
                    </div>
                    <div className="blog_url_modal_button_container">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => setShowScrapeSummaryModal(false)}
                        color="primary"
                      >
                        Stay ⏭️
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        component={RouterLink}
                        to={`/my_campaign/${campaignId}/bulk_process`}
                        color="primary"
                      >
                        Next Step 👉
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="blog_url_modal_summuary_content_container">
                  <div className="blog_url_modal_header">
                    <div className="blog_url_modal_subheading">
                      <div>
                        The scraping process is complete. All URLs have been
                        successfully processed! ✅
                      </div>
                      <div>
                        You can review the processed URLs on this page, or move
                        to the next step by clicking 🚀{" "}
                        <strong>"Next Step 👉"</strong>.
                      </div>
                    </div>
                  </div>
                  <div className="blog_url_modal_button_container">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setShowScrapeSummaryModal(false)}
                      color="primary"
                    >
                      Stay ⏭️
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      component={RouterLink}
                      to={`/my_campaign/${campaignId}/bulk_process`}
                      color="primary"
                    >
                      Next Step 👉
                    </Button>
                  </div>
                </div>
              )}
            </Box>
          </Modal> */}

          <Modal
            open={show_after_create_should_we_scrape_modal}
            onClose={handle_show_after_create_should_we_scrape_modal_close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box id="LinkExchange_model_box">
              <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                <IconButton
                  onClick={
                    handle_show_after_create_should_we_scrape_modal_close
                  }
                >
                  <CloseIcon />
                </IconButton>
              </div>

              {isModalLoading ? (
                <div className="blog_url_loading_modal_container">
                  <div className="blog_url_modal_header">
                    <div className="blog_url_modal_title">
                      🔍 Scrape Prospects URLs
                    </div>
                    <div className="blog_url_modal_subheading">
                      <div>Preparing to scrape your Prospect URLs...</div>
                      <div>
                        Please hold on while we gather all the necessary data.{" "}
                        <CircularProgress />
                      </div>
                      <div>
                        <Skeleton variant="text" width={600} height={50} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="LinkExchange_form_group">
                  <div className="blog_url_modal_header">
                    <div className="blog_url_modal_title">
                      🔍 Scrape Prospects URLs
                    </div>
                    <div className="blog_url_modal_subheading">
                      <div>
                        {" "}
                        👉 You have added Prospects URLs.{" "}
                      </div>
                      <div>
                        {" "}
                        Would you like to scrape all the Prospects URLs now?.{" "}
                      </div>
                      <div>
                        {" "}
                        Scraping the URLs will initiate the data retrieval
                        process for the added URLs.
                      </div>
                    </div>
                  </div>

                  <div className="LinkExchange_form_group">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={processScrapeClientUrlAndSave}
                      color="primary"
                    >
                      Scrape All Target URLs
                    </Button>
                  </div>
                </div>
              )}
            </Box>
          </Modal>

          {/* Scraping Process Summary */}

          <Modal
            open={showScrapeSummaryModal}
            onClose={() => setShowScrapeSummaryModal(false)}
            aria-labelledby="modal-scrape-summary-title"
            aria-describedby="modal-scrape-summary-description"
          >
            <Box id="LinkExchange_model_box">
              <IconButton
                onClick={() => setShowScrapeSummaryModal(false)}
                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <CloseIcon />
              </IconButton>
              {/* <div className="LinkExchange_modal_header">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ marginTop: "20px" }}
                >
                  The scraping process is complete.📊
                </Typography>
              </div> */}

              {scrapeSummary.notScraped > 0 ? (
                <>
                  <div className="blog_url_modal_summuary_content_container">
                    <div className="blog_url_modal_header">
                      <div className="blog_url_modal_title">
                        The scraping process is complete.
                      </div>
                      <div className="blog_url_modal_subheading">
                        <div>
                          We successfully scraped {scrapeSummary.scraped} URLs,
                          but unfortunately, there were issues with{" "}
                          {scrapeSummary.notScraped} URLs. 🛠️
                        </div>
                        <div>
                          You can review the results and choose to proceed to
                          the next step by clicking 🚀{" "}
                          <strong>"Next Step 👉"</strong>.
                        </div>
                      </div>
                    </div>
                    <div className="blog_url_modal_button_container">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => setShowScrapeSummaryModal(false)}
                        color="primary"
                      >
                        Stay ⏭️
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        component={RouterLink}
                        to={`/my_campaign/${campaignId}/bulk_process`}
                        color="primary"
                      >
                        Next Step 👉
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="blog_url_modal_summuary_content_container">
                  <div className="blog_url_modal_header">
                    <div className="blog_url_modal_subheading">
                      <div>
                        The scraping process is complete. All URLs have been
                        successfully processed! ✅
                      </div>
                      <div>
                        You can review the processed URLs on this page,
                      </div>
                      <div>
                       or move to the next step by clicking 🚀{" "}
                        <strong>"Next Step 👉"</strong>.
                      </div>
                    </div>
                  </div>
                  <div className="blog_url_modal_button_container">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setShowScrapeSummaryModal(false)}
                      color="primary"
                    >
                      Stay ⏭️
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      component={RouterLink}
                      to={`/my_campaign/${campaignId}/bulk_process`}
                      color="primary"
                    >
                      Next Step 👉
                    </Button>
                  </div>
                </div>
              )}
            </Box>
          </Modal>

          {/* finalScrapeSummaryModal */}

          <Modal
            open={finalScrapeSummaryModal}
            onClose={() => setFinalScrapeSummaryModal(false)}
            aria-labelledby="modal-final-scrape-summary-title"
            aria-describedby="modal-final-scrape-summary-description"
          >
            <Box id="LinkExchange_model_box">
              <IconButton
                onClick={() => setFinalScrapeSummaryModal(false)}
                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <CloseIcon />
              </IconButton>
              <div className="blog_url_modal_summuary_content_container">
                <div className="blog_url_modal_header">
                  <div className="blog_url_modal_title">
                    Scraping Summary 📊
                  </div>
                  <div className="blog_url_modal_subheading">
                    <div>
                      All scraping methods have been successfully executed. 🛠️
                    </div>
                    <div>
                      You can review the processed URLs on this page, or move to
                      the next step by clicking 🚀{" "}
                      <strong>"Next Step 👉"</strong>.
                    </div>
                  </div>
                </div>
                <div className="blog_url_modal_button_container">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setFinalScrapeSummaryModal(false)}
                    color="primary"
                  >
                    Stay ⏭️
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    component={RouterLink}
                    to={`/my_campaign/${campaignId}/bulk_process`}
                    color="primary"
                  >
                    Next Step 👉
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={notificationMessage}
          />
        </div>
      </div>
    </div>
  );
};
export default BlogURLsPage;

//onChange={(e) => setTextareaValue(e.target.value)}
