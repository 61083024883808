import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useParams,
} from "react-router-dom";
//import {Router, browserHistory} from 'react-router';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import { GiFlatHammer } from "react-icons/gi";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Modal,
  Typography,
  Breadcrumbs,
  Link,
  Snackbar,
  Skeleton,
} from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";

import { TextareaAutosize } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DownloadIcon from "@mui/icons-material/Download";

import { saveAs } from "file-saver";
import "./ClientURLsPage.css";
import LeftBar from "./../common/LeftBar";
import Header from "../common/Header/Header";
import {
  CHECK_CREDIT_CONDITION,
  FIND_AUTOMATIC_KEYWORDS,
  GET_CLIENT_URLS_DATA,
  GET_CREDIT_POINTS_BY_BUTTON_NAME,
  GET_INDIVIDUAL_CAMPAIGN_DETAIL,
  GET_POINTS_DETAILS,
  SAVE_CLIENT_URLS_DATA,
  SCRAPE_URLS,
  UPDATE_CREDIT_POINT_OF_DEDUCTION,
  UPDATE_INDIVIDUAL_CAMPAIGN_DATA,
  getFullApiUrl,
} from "../../utils/apiPath";
import LeftSectionWithMenu from "../common/Header/LeftSectionWithMenu/LeftSectionWithMenu";
import FloatingMenuBar from "../components/FloatingMenuBar/FloatingMenuBar";
import FloatingMenuBar2 from "../components/FloatingMenuBar/FloatingMenuBar2";
import { FaEdit, FaSearch } from "react-icons/fa";
import TableWithPagination from "../../utils/TableWithPagination";
import KeyboardIcon from "@mui/icons-material/Keyboard";

const getNextScrapeMethod = (scrapeMethodCheck) => {
  for (const [method, checked] of Object.entries(scrapeMethodCheck)) {
    if (!checked) return method;
  }
  return null; // Return null if all methods have been executed
};

const ClientURLsPage = ({ toggleTheme }) => {
  //const apipath = "http://localhost:8001"
  const { campaignId } = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [
    show_should_we_scrape_client_urls_modal,
    set_show_should_we_scrape_client_urls_modal,
  ] = useState(false);
  const [
    show_after_create_should_we_scrape_modal,
    set_show_after_create_should_we_scrape_modal,
  ] = useState(false);
  const [
    show_should_we_find_automatic_keywords_in_client_urls_modal,
    set_show_should_we_find_automatic_keywords_in_client_urls_modal,
  ] = useState(false);
  const [totalCreditAfterDeduction, setTotalCreditAfterDeduction] = useState(0);
  const [creditWeekLimitAfterDeduction, setCreditWeekLimitAfterDeduction] =
    useState(0);
  const [totalCreditPoints, setTotalCreditPoints] = useState(0);
  const [creditPointsLimit, setCreditPointsLimit] = useState(0);
  const [deductionPoints, setDeductionPoints] = useState(1);

  const [selected_client_url, set_selected_client_url] = useState();

  useEffect(() => {
    getCampaignDetails();
  }, []); // Fetch credit point details on component mount

  const fetchpointsDetails = async (points_to_be_deducted) => {
    try {
      const response = await fetch(getFullApiUrl(CHECK_CREDIT_CONDITION), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          campaign_id: campaignId,
          points_to_be_deducted: points_to_be_deducted,
        }),
      });
      console.log("button");

      const data = await response.json();
      setCreditPointsLimit(data.credits_limit_left_this_week_before_deduction);
      setTotalCreditPoints(data.owner_credits_left_before_deduction);
      setTotalCreditAfterDeduction(data.owner_credits_left_after_deduction);
      setCreditWeekLimitAfterDeduction(
        data.credits_limit_left_this_week_after_deduction
      );
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCreditPointToDeductByButton = async (button) => {
    try {
      const response = await fetch(
        getFullApiUrl(GET_CREDIT_POINTS_BY_BUTTON_NAME),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            button_name: button,
          }),
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateCreditPoints = async (points) => {
    try {
      const deductionResponse = await fetch(
        `${getFullApiUrl(
          UPDATE_CREDIT_POINT_OF_DEDUCTION
        )}?campaign_id=${campaignId}&user_id=${localStorage.getItem(
          "user_id"
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            points_deducted: points,
          }),
        }
      );

      if (!deductionResponse.ok) {
        throw new Error("Failed to deduct points");
      }
      // setNotificationMessage(`${points} Points deducted successfully`);
      // setOpenSnackbar(true);
      fetchpointsDetails(0);
      // Close the Modal after deduction
    } catch (error) {
      console.error("Error deducting points:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle_should_we_scrape_client_urls_close = () => {
    set_show_should_we_scrape_client_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setCreditWeekLimitAfterDeduction(0);
    setTotalCreditAfterDeduction(0);
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const handle_show_after_create_should_we_scrape_modal_close = () => {
    set_show_after_create_should_we_scrape_modal(false);
  };

  const handle_should_we_find_automatic_keywords_in_client_urls_close = () => {
    set_show_should_we_find_automatic_keywords_in_client_urls_modal(false);
    setTotalCreditPoints(0);
    setCreditPointsLimit(0);
    setErrorMessage("");
    setCreditWeekLimitAfterDeduction(0);
    setTotalCreditAfterDeduction(0);
    set_selected_client_url();
    setErrorMassageForCreditWeekLimits("");
    setErrorMassageForTotalCredits("");
  };

  const [initial_loader_open, set_initial_loader_open] = React.useState(false);
  const [listofURL, setListofURL] = useState("");
  const [rows, setRows] = useState([]);
  const [table_data, set_table_data] = useState([]);

  const [editScrappedData, setEditScrappedData] = useState("");
  const [showEditScrapSection, setShowEditScrapSection] = useState(false);

  const [editSummaryData, setEditSummaryData] = useState("");
  const [showEditSummarySection, setShowEditSummarySection] = useState(false);

  const [editKeywordsData, setEditKeywordsData] = useState("");
  const [showEditKeywordsSection, setShowEditKeywordsSection] = useState(false);

  const [show_selectScrapOption_Section, set_show_selectScrapOption_Section] =
    useState(false);
  const [selected_scrapper_option, set_selected_scrapper_option] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMassageForTotalCredits, setErrorMassageForTotalCredits] =
    useState("");
  const [errorMassageForCreditWeekLimits, setErrorMassageForCreditWeekLimits] =
    useState("");

  const [scrape_method_check, set_scrape_method_check] = useState({
    BeautifulSoup: false,
    Webcache: false,
    Selenium: false,
  });
  const [show_scrap_progress_section, set_show_scrap_progress_section] =
    useState(false);
  const [scrapeSummary, setScrapeSummary] = useState({
    scraped: 0,
    notScraped: 0,
  });
  const [scrapping_progress, set_scrapping_progress] = useState({
    total_count: 0,
    scrap_processed: 0,
    scrapped_count: 0,
    not_scrapped_count: 0,
  });
  const [scrapeMethodUpdated, setScrapeMethodUpdated] = useState(false);
  const updateScrapeMethodCheck = (method) => {
    set_scrape_method_check((prev) => ({ ...prev, [method]: true }));
    setScrapeMethodUpdated(true); // Trigger effect
  };
  useEffect(() => {
    if (scrapeMethodUpdated) {
      // Reset the trigger
      setScrapeMethodUpdated(false);
      // Proceed with the rest of the logic
      proceedWithScraping();
    }
  }, [scrapeMethodUpdated]);

  //********************************************************** Text to Table Creation - Start
  useEffect(() => {
    // Fetch data from the API before the page loads
    set_initial_loader_open(true);
    fetch(getFullApiUrl(GET_CLIENT_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({ request_data: { campaign_id: campaignId } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const updatedRows = data["data"].map((row, index) => ({
          ...row,
          id: index + 1,
          url_id: index + 1,
        }));
        setRows(updatedRows);
        set_table_data(updatedRows);
        const urlList = updatedRows.map((row) => row.url).join("\n");
        setListofURL(urlList);
        set_initial_loader_open(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const changeListofURLTextArea = (event) => {
    setListofURL(event.target.value);
  };
  //console.log('createScrappingJobsTable');
  //console.log('*****************************************************************');

  const createScrappingJobsTable = () => {
    const existingUrlsData = rows.map((row) => row);
    const updatedUrls = listofURL
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");
    var row_objects_array = [];
    var row_object = {};
    for (let i = 0; i < updatedUrls.length; i++) {
      let matching_rows = existingUrlsData.filter(
        (item) => item["url"] == updatedUrls[i]
      );
      if (matching_rows.length > 0) {
        const first_matching_row = matching_rows[0];
        const updatedRow = { ...first_matching_row, id: i + 1, url_id: i + 1 };
        row_objects_array.push(updatedRow);
      } else {
        row_object = {
          id: i + 1,
          url_id: i + 1,
          url: updatedUrls[i],
          is_scraped: 0,
          scrapedData: "",
          has_summary: 0,
          summary: "",
          has_keyword: 0,
          keywords: "",
        };
        row_objects_array.push(row_object);
      }
    }
    console.log(row_objects_array);
    const updatedRowObjectsArray = row_objects_array.map((row) => {
      row.is_scraped = row.is_scraped === 0 ? "not scraped" : "scraped";
      return row;
    });

    console.log(updatedRowObjectsArray);

    set_table_data(updatedRowObjectsArray);
    setRows(row_objects_array);
    set_table_data(updatedRowObjectsArray);
  };
  const createScrappingJobsTableAndScrapeUrls = async () => {
    set_scrape_method_check({
      BeautifulSoup: false,
      Webcache: false,
      Selenium: false,
    });
    if (listofURL.trim() === "") {
      createScrappingJobsTable();
      return;
    }
    createScrappingJobsTable();
    set_show_after_create_should_we_scrape_modal(true);
  };

  //********************************************************** Text to Table Creation - End

  //********************************************************** Data Manipulatuion GET SAVE Find - Start
  const [saving, set_saving] = useState(false);

  const saveRows = () => {
    setFinalScrapeSummaryModal(false);
    setShowScrapeSummaryModal(false);
    set_saving(true);
    fetch(getFullApiUrl(SAVE_CLIENT_URLS_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, client_urls_data: rows },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        set_saving(false);
        console.log(data);
      })
      .catch((error) => {
        //console.error('Error:', error);
        set_saving(false);
        console.log("Data not got saved");
      });
    saveProjectData();
  };

  const saveProjectData = () => {
    fetch(getFullApiUrl(UPDATE_INDIVIDUAL_CAMPAIGN_DATA), {
      method: "POST",
      body: JSON.stringify({
        request_data: { campaign_id: campaignId, add_client_urls: 1 },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        //console.error('Error:', error);
        console.log("Data not got saved");
      });
  };

  const getDataBy_url_id = (url_id) => {
    var row = {};
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]["url_id"] == url_id) {
        row = rows[i];
        break;
      }
    }
    return row;
  };
  const saveDataBy_url_id = (row_to_be_updated) => {
    var url_id = row_to_be_updated["url_id"];
    var pos_of_found_data = -1;
    //var row = {}
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]["url_id"] == url_id) {
        pos_of_found_data = i;
        break;
      }
    }
    if (pos_of_found_data > -1) {
      var updatedRows = rows.map((row) => row);
      updatedRows[pos_of_found_data] = row_to_be_updated;
      setRows(updatedRows);
    }
  };
  //********************************************************** Data Manipulatuion GET SAVE Find - End

  //********************************************************** Scrapping section - Start
  const handleScrapAllPages = () => {
    handle_show_after_create_should_we_scrape_modal_close();
    set_show_selectScrapOption_Section(true);
    set_selected_scrapper_option("BeautifulSoup");
  };

  const apiEndpoints = [
    "https://5po5s0eo70.execute-api.us-east-2.amazonaws.com",
    "https://l4bzdp8da9.execute-api.us-east-2.amazonaws.com",
    "https://h0fary5mbh.execute-api.us-east-2.amazonaws.com",
    "https://o0od5remnh.execute-api.us-east-2.amazonaws.com",
    "https://g1gpp5coqe.execute-api.us-east-2.amazonaws.com",
    "https://xmmi48n6i6.execute-api.us-east-2.amazonaws.com",
    "https://7wgl5kfmh3.execute-api.us-east-2.amazonaws.com",
    "https://3urq85a0hg.execute-api.us-east-2.amazonaws.com",
    "https://5slhaq9nx5.execute-api.us-east-2.amazonaws.com",
    "https://u9c84acq7l.execute-api.us-east-2.amazonaws.com",
  ];
  const [showScrapeSummaryModal, setShowScrapeSummaryModal] = useState(false);
  const [finalScrapeSummaryModal, setFinalScrapeSummaryModal] = useState(false);

  const proceedWithScraping = async () => {
    set_show_should_we_scrape_client_urls_modal(false);
    set_show_selectScrapOption_Section(false);
    set_show_scrap_progress_section(true);

    const error_codes = ["Error", "404"];
    let updatedRows = rows.map((row) => row);
    const totalRows = rows.length;
    let scrapped_count = rows.reduce(
      (count, row) => count + (row["scrapedData"] !== "" ? 1 : 0),
      0
    );
    let scrap_processed = scrapped_count;
    let not_scrapped_count = scrap_processed - scrapped_count;
    const batchSize = 10;
    const totalBatches = Math.ceil(totalRows / batchSize);
    const concurrentBatchLimit = 1;
    const batchesQueue = [];
    let progress = {
      scrap_processed: scrap_processed,
      scrapped_count: scrapped_count,
      not_scrapped_count: not_scrapped_count,
      total_count: totalRows,
    };
    set_scrapping_progress(progress);
    let responseCounter = 0;

    const process_single_scrapped_data = async (responseObj) => {
      scrap_processed += 1;
      if (responseObj.response !== null) {
        const { index, title, response } = responseObj;
        if (response !== "" && response !== undefined) {
          if (
            !error_codes.some((error_code) => response.includes(error_code))
          ) {
            updatedRows[index]["title"] = title;
            updatedRows[index]["scrapedData"] = response;
            updatedRows[index]["is_scraped"] = "scraped";
            scrapped_count += 1;
          } else {
            not_scrapped_count += 1;
          }
        } else {
          not_scrapped_count += 1;
        }
        responseCounter += 1;
        saveDataBy_url_id(updatedRows[index]);
      } else {
        not_scrapped_count += 1;
      }
      progress = {
        scrap_processed: scrap_processed,
        scrapped_count: scrapped_count,
        not_scrapped_count: not_scrapped_count,
        total_count: totalRows,
      };
      set_scrapping_progress(progress);

      // Check if scraping is complete
      if (scrap_processed === totalRows) {
        setScrapeSummary({
          scraped: scrapped_count,
          notScraped: not_scrapped_count,
        });

        // Directly show the final summary modal
        setShowScrapeSummaryModal(false);
        setFinalScrapeSummaryModal(true);
      }
    };

    const runBatch = async (currentBatch) => {
      const startIndex = currentBatch * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUrls = [];
      const fetchPromises = [];

      for (let i = startIndex; i < endIndex; i++) {
        const row = rows[i];
        if (row["scrapedData"] === "") {
          batchUrls.push(row["url"]);
          const apiIndex = i % apiEndpoints.length;
          const fetchPromise = fetch(apiEndpoints[apiIndex], {
            method: "POST",
            body: JSON.stringify({
              request_data: {
                urls: [row["url"]],
                scrapper: "BeautifulSoup", // Always use BeautifulSoup
              },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              process_single_scrapped_data({
                index: i,
                title: data["response"][0]["title"],
                response: data["response"][0]["text"],
              });
            })
            .catch((error) => {
              process_single_scrapped_data({
                index: i,
                title: null,
                response: null,
              });
            });

          fetchPromises.push(fetchPromise);
        }
      }
      await Promise.all(fetchPromises);
      return fetchPromises;
    };

    const currentBatchPromises = [];
    for (let batch = 0; batch < totalBatches; batch += concurrentBatchLimit) {
      for (
        let concurrentBatchIndex = 0;
        concurrentBatchIndex < concurrentBatchLimit;
        concurrentBatchIndex++
      ) {
        const currentBatch = batch + concurrentBatchIndex;
        if (currentBatch < totalBatches) {
          currentBatchPromises.push(runBatch(currentBatch));
        }
      }
      if (currentBatchPromises.length > 100) {
        const [winnerIndex, winnerPromise] = await Promise.race(
          currentBatchPromises.map((promise, index) =>
            promise.then(() => [index, promise])
          )
        );
        currentBatchPromises.splice(winnerIndex, 1);
      }
    }
    set_selected_scrapper_option("BeautifulSoup"); // Always set to BeautifulSoup
  };

  const proceedWithScraping1 = async () => {
    set_show_should_we_scrape_client_urls_modal(false);
    set_show_selectScrapOption_Section(false);
    set_show_scrap_progress_section(true);

    const error_codes = ["Error", "404"];
    let updatedRows = rows.map((row) => row);
    const totalRows = rows.length;
    let scrapped_count = rows.reduce(
      (count, row) => count + (row["scrapedData"] !== "" ? 1 : 0),
      0
    );
    let scrap_processed = scrapped_count;
    let not_scrapped_count = scrap_processed - scrapped_count;
    const batchSize = 10;
    const totalBatches = Math.ceil(totalRows / batchSize);
    const concurrentBatchLimit = 1;
    const batchesQueue = [];
    let progress = {
      scrap_processed: scrap_processed,
      scrapped_count: scrapped_count,
      not_scrapped_count: not_scrapped_count,
      total_count: totalRows,
    };
    set_scrapping_progress(progress);
    let responseCounter = 0;

    const process_single_scrapped_data = async (responseObj) => {
      scrap_processed += 1;
      if (responseObj.response !== null) {
        const { index, title, response } = responseObj;
        if (response !== "" && response !== undefined) {
          if (
            !error_codes.some((error_code) => response.includes(error_code))
          ) {
            updatedRows[index]["title"] = title;
            updatedRows[index]["scrapedData"] = response;
            updatedRows[index]["is_scraped"] = "scraped";
            scrapped_count += 1;
          } else {
            not_scrapped_count += 1;
          }
        } else {
          not_scrapped_count += 1;
        }
        responseCounter += 1;
        saveDataBy_url_id(updatedRows[index]);
      } else {
        not_scrapped_count += 1;
      }
      progress = {
        scrap_processed: scrap_processed,
        scrapped_count: scrapped_count,
        not_scrapped_count: not_scrapped_count,
        total_count: totalRows,
      };
      set_scrapping_progress(progress);

      if (scrap_processed === totalRows) {
        setScrapeSummary({
          scraped: scrapped_count,
          notScraped: not_scrapped_count,
        });

        const allMethodsExecuted = Object.values(scrape_method_check).every(
          (v) => v === true
        );

        if (allMethodsExecuted) {
          setShowScrapeSummaryModal(false);
          setFinalScrapeSummaryModal(true);
        } else {
          setShowScrapeSummaryModal(true);
        }
      }
    };

    const runBatch = async (currentBatch) => {
      const startIndex = currentBatch * batchSize;
      const endIndex = Math.min(startIndex + batchSize, totalRows);
      const batchUrls = [];
      const fetchPromises = [];

      for (let i = startIndex; i < endIndex; i++) {
        const row = rows[i];
        if (row["scrapedData"] === "") {
          batchUrls.push(row["url"]);
          const apiIndex = i % apiEndpoints.length;
          const fetchPromise = fetch(apiEndpoints[apiIndex], {
            method: "POST",
            body: JSON.stringify({
              request_data: {
                urls: [row["url"]],
                scrapper: selected_scrapper_option,
              },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              process_single_scrapped_data({
                index: i,
                title: data["response"][0]["title"],
                response: data["response"][0]["text"],
              });
            })
            .catch((error) => {
              process_single_scrapped_data({
                index: i,
                title: null,
                response: null,
              });
            });

          fetchPromises.push(fetchPromise);
        }
      }
      await Promise.all(fetchPromises);
      return fetchPromises;
    };

    const currentBatchPromises = [];
    for (let batch = 0; batch < totalBatches; batch += concurrentBatchLimit) {
      for (
        let concurrentBatchIndex = 0;
        concurrentBatchIndex < concurrentBatchLimit;
        concurrentBatchIndex++
      ) {
        const currentBatch = batch + concurrentBatchIndex;
        if (currentBatch < totalBatches) {
          currentBatchPromises.push(runBatch(currentBatch));
        }
      }
      if (currentBatchPromises.length > 100) {
        const [winnerIndex, winnerPromise] = await Promise.race(
          currentBatchPromises.map((promise, index) =>
            promise.then(() => [index, promise])
          )
        );
        currentBatchPromises.splice(winnerIndex, 1);
      }
    }
    set_selected_scrapper_option(getNextScrapeMethod(scrape_method_check));
  };

  const api_call_scrape_urls = async () => {
    updateScrapeMethodCheck(selected_scrapper_option);
  };

  const handleScrapAgain = (url_id) => {
    // Handle mark complete action
    console.log("handleScrapAgain:", url_id);
  };

  const handleEditScrapData = (url_id) => {
    var row = getDataBy_url_id(url_id);
    console.log(row);
    setEditScrappedData(row);
    setShowEditScrapSection(true);
    setShowEditSummarySection(false);
    setShowEditKeywordsSection(false);
  };
  const changeEditScrappedData = (event) => {
    //setListofURL(event.target.value);
    //var data = editScrappedData;
    var data = { ...editScrappedData };
    //console.log("***********************************")
    //console.log(data)
    data["scrapedData"] = event.target.value;
    //console.log(data)
    setEditScrappedData(data);
  };

  const saveEditableScrappedData = () => {
    // Step 1: Update the is_scraped field in editScrappedData
    const updatedEditScrappedData = {
        ...editScrappedData,
        is_scraped: "scraped"
    };

    // Step 2: Update the corresponding entry in table_data
    const updatedTableData = table_data.map(item => 
        item.id === updatedEditScrappedData.id ? { ...item, is_scraped: "scraped" } : item
    );

    // Step 3: Log for debugging
    console.log("updatedEditScrappedData", updatedEditScrappedData);
    console.log("updatedTableData", updatedTableData);

    // Save the updated data by URL ID
    saveDataBy_url_id(updatedEditScrappedData);

    // Hide the edit scrap section
    setShowEditScrapSection(false);
};

  //********************************************************** Scrap Data Edit section End

  //********************************************************** Summary section Start
  const handleRunAutomaticSummarizerAllPages = () => {
    /*
    var updatedRows = rows.map((row) => (row));

    for (let i = 0; i < updatedRows.length; i++)
    {

      fetch(getFullApiUrl(SCRAPE_URLS), {
          method: 'POST',
          body: JSON.stringify({"request_data":{"urls":[updatedRows[i]["url"]]}}),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            updatedRows[i]["scrapedData"] = data["response"]
            updatedRows[i]["is_scraped"] = 1
            saveDataBy_url_id(updatedRows[i]);
          })
          .catch((error) => {
            //console.error('Error:', error);
            console.log("Didn't got the data");
          });

    }
    */
  };
  const handleRunAutomaticSummarizerAgain = (url_id) => {
    // Handle mark complete action
    console.log("handleScrapAgain:", url_id);
  };
  //********************************************************** Summary section End

  //********************************************************** Summary Data Edit section Start
  const handleEditSummaryData = (url_id) => {
    var row = getDataBy_url_id(url_id);
    console.log(row);
    setEditSummaryData(row);
    setShowEditScrapSection(false);
    setShowEditSummarySection(true);
    setShowEditKeywordsSection(false);
  };
  const changeEditSummaryData = (event) => {
    //setListofURL(event.target.value);
    //var data = editScrappedData;
    var data = { ...editSummaryData };
    //console.log("***********************************")
    //console.log(data)
    data["summary"] = event.target.value;
    //console.log(data)
    setEditSummaryData(data);
  };
  const saveEditSummaryData = () => {
    saveDataBy_url_id(editSummaryData);
    setShowEditSummarySection(false);
  };
  //********************************************************** Summary Data Edit section End

  //********************************************************** Keyword Eetractor section Start
  const handleRunKeywordsEetractorAllPages = () => {
    /*
    var updatedRows = rows.map((row) => (row));

    for (let i = 0; i < updatedRows.length; i++)
    {

      fetch(getFullApiUrl(SCRAPE_URLS), {
          method: 'POST',
          body: JSON.stringify({"request_data":{"urls":[updatedRows[i]["url"]]}}),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          updatedRows[i]["scrapedData"] = data["response"]
          updatedRows[i]["is_scraped"] = 1
          saveDataBy_url_id(updatedRows[i]);
        })
        .catch((error) => {
          //console.error('Error:', error);
          console.log("Didn't got the data");
        });

    }
    */
  };

  //********************************************************** Keyword Eetractor section End

  //********************************************************** Keyword Data Edit section Start
  const handleEditKeywordsData = (url_id) => {
    var row = getDataBy_url_id(url_id);
    console.log(row);
    setEditKeywordsData(row);
    setShowEditScrapSection(false);
    setShowEditSummarySection(false);
    setShowEditKeywordsSection(true);
  };
  const changeEditKeywordsData = (event) => {
    //setListofURL(event.target.value);
    //var data = editScrappedData;
    var data = { ...editKeywordsData };
    //console.log("***********************************")
    //console.log(data)
    data["keywords"] = event.target.value;
    //console.log(data)
    setEditKeywordsData(data);
  };
  const saveEditKeywordsData = () => {
    editKeywordsData["keywords"] = editKeywordsData["keywords"]
      .trim()
      .replace(/^\n+|\n+$/g, "");
    saveDataBy_url_id(editKeywordsData);
    setShowEditKeywordsSection(false);
  };
  //********************************************************** Keyword Data Edit section End

  const handleEditURL = (url_id) => {
    // Handle edit action
    console.log("handleEditURL:", url_id);
    console.log(rows);
  };

  const navigate = useNavigate();
  const handleView = (url_id) => {
    // Handle view action
    //navigate(`/scrapping/${campaignId}`);
  };

  const handleDelete = (url_id) => {
    // Handle delete action
    console.log("Delete campaign:", url_id);
  };

  /*
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
*/
  const handleClose = () => {
    setShowEditScrapSection(false);
    setShowEditSummarySection(false);
    setShowEditKeywordsSection(false);
    set_show_selectScrapOption_Section(false);

    //setOpen(false);
  };

  const open_in_new_tab = (url) => {
    window.open(url, "_blank");
  };

  const [campaignData, setCampaignData] = useState({
    team_id: "",
    workspace_id: "",
    team_name: "",
    workspace_name: "",
    campaign_name: "",
  });

  const getCampaignDetails = async () => {
    try {
      const response = await fetch(
        `${getFullApiUrl(GET_INDIVIDUAL_CAMPAIGN_DETAIL)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch campaign details");
      }

      const data = await response.json();
      setCampaignData(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  const validate_scrape_client_urls = async (button) => {
    const deduction_point = await getCreditPointToDeductByButton(button);
    // const deduction_point = await getCreditPointToDeductByButton(button);

    console.log(rows.length);
    const totalRows = rows.length;

    const scrappedCount = rows.reduce(
      (count, row) => count + (row["scrapedData"] !== "" ? 1 : 0),
      0
    );
    const notScrappedCount = totalRows - scrappedCount;
    // const credits_to_be_deducted = notScrappedCount * parseInt(deduction_point);
    const credits_to_be_deducted = parseFloat(
      (notScrappedCount * parseFloat(deduction_point)).toFixed(3)
    );
    // Set deduction points in the state
    setDeductionPoints(credits_to_be_deducted);
    const data = await fetchpointsDetails(credits_to_be_deducted);

    let shouldOpenModal = false;
    const threshold_value = data.threshold_value;
    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no Target URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    if (shouldOpenModal) {
      set_show_should_we_scrape_client_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await api_call_scrape_urls();
      await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_scrape_client_urls_modal(true);
    }
  };
  const validate_find_automatic_keywords = async (button, client_url_id) => {
    const deduction_point = await getCreditPointToDeductByButton(button);

    set_selected_client_url(client_url_id);
    const credits_to_be_deducted = parseFloat(
      (1 * parseFloat(deduction_point)).toFixed(3)
    ); // Set deduction points in the state
    setDeductionPoints(credits_to_be_deducted);

    const data = await fetchpointsDetails(credits_to_be_deducted);
    const threshold_value = data.threshold_value;

    // Check conditions separately
    let shouldOpenModal = false;

    if (
      !data.does_owner_have_enough_credits &&
      !data.does_user_have_enough_credits_week_limit
    ) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (!data.does_owner_have_enough_credits) {
      setErrorMassageForTotalCredits("Insufficient Credit Points");
      shouldOpenModal = true;
    } else if (!data.does_user_have_enough_credits_week_limit) {
      setErrorMassageForCreditWeekLimits("Insufficient Weekly credits limit");
      shouldOpenModal = true;
    } else if (credits_to_be_deducted === 0) {
      setErrorMessage(
        "There are no Target URLs available for scraping at the moment."
      );
      shouldOpenModal = true;
    }

    // Check if modal should be opened or execute processScrapeAndUpdate
    if (shouldOpenModal) {
      set_show_should_we_find_automatic_keywords_in_client_urls_modal(true);
    } else if (threshold_value > credits_to_be_deducted) {
      await handleFindAutomaticKeywords(selected_client_url);
      await updateCreditPoints(credits_to_be_deducted);
    } else {
      set_show_should_we_find_automatic_keywords_in_client_urls_modal(true);
    }
  };
  const handleFindAutomaticKeywords = (url_id) => {
    set_show_should_we_find_automatic_keywords_in_client_urls_modal(false);
    console.log("handleFindAutomaticKeywords:", url_id);

    var row = getDataBy_url_id(url_id);

    // Make API call to fetch automatic keywords
    fetch(getFullApiUrl(FIND_AUTOMATIC_KEYWORDS), {
      method: "POST",
      body: JSON.stringify({
        request_data: {
          scrapedData: row["scrapedData"], // Send scrapedData in the request body
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json()) // Parse the response JSON
      .then((data) => {
        // Ensure that the keywords are extracted from the response correctly
        const keywords = data.data.keywords;

        // Join the keywords with newline characters
        row["keywords"] = keywords.join("\n");

        // Update the state with the new keywords data
        setEditKeywordsData(row);
        setShowEditKeywordsSection(true);
        // Adjust the visibility of sections
        setShowEditScrapSection(false);
        setShowEditSummarySection(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        console.log("find_automatic_keywords didn't work");
      });
  };
  const processScrapeClientUrlAndUpdate = async () => {
    try {
      await api_call_scrape_urls();
      await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
    } catch (error) {
      console.error("Error during the process:", error);
      // Handle error accordingly (e.g., rollback any partial changes, notify user, etc.)
    }
  };

  const processScrapeClientUrlAndSave = async () => {
    await handleScrapAllPages();
  };
  const processFindAutomaticKeywordsInClientUrlAndUpdate = async () => {
    await handleFindAutomaticKeywords(selected_client_url);
    await updateCreditPoints(deductionPoints); // Deduct points only if the process was successful
  };

  const handleRemainingUrlToScrape = async () => {
    setShowScrapeSummaryModal(false);
    validate_scrape_client_urls("Run Scrapper");
  };

  return (
    <>
      <div className="page_Section">
        <div className="leftSection_withMenu">
          <LeftSectionWithMenu />
        </div>
        <div className="rightSection_withMenu">
          <div className="headerSection_withMenu">
            <Header onToggleTheme={toggleTheme} />
          </div>

          <div className="bodySection campaign_bodysection">
            <Breadcrumbs
              id="Breadcrumbs"
              separator={<NavigateNext />}
              aria-label="breadcrumb"
            >
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to="/"
                  color="inherit"
                  underline="hover"
                >
                  Home
                </Link>
              </div>
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to={`/my_teams`}
                  color="inherit"
                  underline="hover"
                >
                  My Teams
                </Link>
              </div>
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to={`/my_team/${campaignData.team_id}`}
                  color="inherit"
                  underline="hover"
                >
                  {campaignData.team_name}
                </Link>
              </div>
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to={`/my_team_workspaces/${campaignData.team_id}`}
                  color="inherit"
                  underline="hover"
                >
                  {campaignData.team_name} Workspaces
                </Link>
              </div>
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to={`/my_workspace/${campaignData.workspace_id}`}
                  color="inherit"
                  underline="hover"
                >
                  {campaignData.workspace_name}
                </Link>
              </div>
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to={`/my_workspace_campaign/${campaignId}`}
                  color="inherit"
                  underline="hover"
                >
                  {campaignData.workspace_name} Campaigns
                </Link>
              </div>
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to={`/solutions/${campaignId}`}
                  color="inherit"
                  underline="hover"
                >
                  {campaignData.campaign_name}
                </Link>
              </div>
              <div className="breadcrumb_text">
                <Link
                  component={RouterLink}
                  to={`/my_campaign/${campaignId}`}
                  color="inherit"
                  underline="hover"
                >
                  All-In-One
                </Link>
              </div>
              <div className="breadcrumb_text">Add Target URLs</div>
            </Breadcrumbs>

            <FloatingMenuBar />

            {initial_loader_open == true ? (
              <div className="client_url_initial_loader_container">
                {/* <Skeleton variant="rectangular" width="100%" height="50vh" /> */}
                <CircularProgress />
              </div>
            ) : (
              <div className="client_url_text_area_container">
                <div className="add_target_urls_heading_and_sub_heading_container">
                  <div className="add_target_urls_heading_container">
                    <div className="add_target_urls_heading_text">
                      Add Target URLs
                    </div>
                  </div>

                  {/* Subdescription Section */}
                  <div className="add_target_urls_sub_heading_container">
                    <div className="add_target_urls_sub_heading_text">
                      <div>
                        🌐 Start by adding your target URLs in the textbox
                        provided below.
                      </div>
                      <div>
                        🔄 Click on the 'Process Target URLs' button to process
                        the added URLs.
                      </div>
                      <div>
                        🧹 Use the 'Scraper' button to process scraping with the
                        existing data.
                      </div>
                      <div>
                        🔍 Add keywords manually or use 'Find Automatic
                        Keywords' to discover keywords for your URLs.
                      </div>
                      <div>
                        ✏️ Edit keywords using the 'Edit Keywords' button for
                        each URL as needed.
                      </div>
                    </div>
                  </div>
                </div>
                <TextareaAutosize
                  className="text_area_of_link_insertion"
                  value={listofURL}
                  onChange={changeListofURLTextArea}
                  placeholder="Enter Client URLs one by one in each line"
                  minRows={5}
                  style={{ width: "100%" }}
                />
                <div className="client_url_create_scrape_button_container">
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => createScrappingJobsTableAndScrapeUrls()}
                      style={{ marginRight: "8px" }}
                    >
                      Process Target URLs
                    </Button>
                  </Box>
                </div>

                <div className="client_url_table_container">
                  <TableWithPagination
                    data={table_data}
                    file_name={"target_url_data"}
                    fields={[
                      {
                        type: "id",
                        field: "url_id",
                        headerName: "",
                        minWidth: 1,
                        width: 1,
                        maxWidth: 90,
                      },
                      {
                        type: "field",
                        field: "url",
                        headerName: "URL Address",
                        search_type: "text_search",
                        width: 800,
                        minWidth: 300,
                        maxWidth: 1500,
                      },
                      {
                        type: "field",
                        field: "is_scraped",
                        headerName: "Is Scraped ?",
                        search_type: "scrape_search",
                        width: 145,
                        minWidth: 120,
                        maxWidth: 1500,
                      },
                      {
                        type: "action",
                        field: "edit_action",
                        headerName: "",
                        width: 65,
                        renderCell: (params) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <div>&nbsp;&nbsp;&nbsp;&nbsp;</div> */}

                              <Tooltip title="Edit scrape data">
                                <IconButton
                                  className="table_action_icon_link_exchange_row"
                                  onClick={() =>
                                    handleEditScrapData(params.url_id)
                                  }
                                >
                                  <FaEdit />
                                </IconButton>
                              </Tooltip>
                            </div>
                          );
                        },
                      },
                      {
                        type: "action",
                        field: "action",
                        headerName: "Keywords Action",
                        width: 135,
                        renderCell: (params) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>

                              <Tooltip title="Find Automatic Keywords">
                                <IconButton
                                  className="table_action_icon_link_exchange_row"
                                  onClick={() =>
                                    validate_find_automatic_keywords(
                                      "Find Automatic Keywords",
                                      params.url_id
                                    )
                                  }
                                  style={{ marginRight: "8px" }}
                                >
                                  <FaSearch />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit Keywords">
                                <IconButton
                                  className="table_action_icon_link_exchange_row"
                                  onClick={() =>
                                    handleEditKeywordsData(params.url_id)
                                  }
                                  style={{ marginRight: "8px" }}
                                >
                                  <FaEdit />
                                </IconButton>
                              </Tooltip>
                            </div>
                          );
                        },
                      },
                    ]}
                    bulk_actions={[
                      {
                        action: "save",
                        renderCell: (params) => {
                          return (
                            <>
                              {saving ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={true}
                                  startIcon={<CircularProgress size={20} />}
                                >
                                  Saving...
                                </Button>
                              ) : (
                                <Tooltip title="Save">
                                  <IconButton
                                    onClick={() => {
                                      saveRows();
                                    }}
                                  >
                                    <SaveIcon className="table_action_icon_link_exchange_row" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          );
                        },
                      },

                      {
                        action: "scrape",
                        renderCell: (params) => {
                          return (
                            <Tooltip title="scrape">
                              <IconButton
                                onClick={() => {
                                  handleScrapAllPages();
                                  // validate_scrape_urls(params, "Run Scrapper");
                                }}
                              >
                                <GiFlatHammer className="table_action_icon_link_exchange" />
                              </IconButton>
                            </Tooltip>
                          );
                        },
                      },
                    ]}
                  />
                </div>
                <div className="next_step_button_container">
                  <Button
                    variant="contained"
                    size="small"
                    component={RouterLink}
                    to={`/my_campaign/${campaignId}/check_active_bloggers_using_serper`}
                    color="primary"
                  >
                    Next Step 👉
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={show_after_create_should_we_scrape_modal}
        onClose={handle_show_after_create_should_we_scrape_modal_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div style={{ position: "absolute", top: "10px", right: "10px" }}>
            <IconButton
              onClick={handle_show_after_create_should_we_scrape_modal_close}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_form_group">
            <div className="blog_url_modal_header">
              <div className="blog_url_modal_title">📊 Scrape Target URLs</div>
              <div className="blog_url_modal_subheading">
                <div>
                  {" "}
                  🚀 You have added target URLs. Would you like to scrape all
                  the target URLs now?.{" "}
                </div>
                <div>
                  {" "}
                  📝 Scraping the URLs will initiate the data retrieval process
                  for the added URLs.
                </div>
              </div>
            </div>
          </div>

          <div className="LinkExchange_form_group">
            <Button
              variant="contained"
              size="small"
              onClick={processScrapeClientUrlAndSave}
              color="primary"
            >
              🌐 Scrape All Target URLs
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={
          show_selectScrapOption_Section |
          showEditScrapSection |
          showEditSummarySection |
          showEditKeywordsSection
        }
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box id="model_box">
          <div style={{ position: "absolute", top: "10px", right: "10px" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          {show_selectScrapOption_Section ? (
            <div className="blog_url_modal_content_cantainer">
              <div className="blog_url_modal_header">
                <div className="blog_url_modal_title">🔍 Begin Scraping</div>
                <div className="blog_url_modal_subheading">
                  <div>
                    👉 Click the "Start Scraping" button below to initiate the
                    scraping process.
                  </div>
                </div>
              </div>
              <div className="blog_url_scrapped_button_container">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => validate_scrape_client_urls("Run Scrapper")}
                  style={{ marginRight: "8px" }}
                >
                  🚀 Start Scraping
                </Button>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {showEditScrapSection ? (
            <div className="edit_scrape_modal_container">
              <div className="edit_scrape_modal_header">
                {editScrappedData["url_id"]} : {editScrappedData["url"]}
              </div>
              <TextareaAutosize
                value={editScrappedData["scrapedData"]}
                onChange={changeEditScrappedData}
                placeholder="Edit your scrapped text"
                minRows={5}
                style={{
                  width: "100%",
                  overflow: "auto",
                  borderRadius: "5px",
                  height: "85%",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 0px 8px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => saveEditableScrappedData()}
                  style={{ marginRight: "8px" }}
                >
                  Save Scrap Text
                </Button>
              </Box>
            </div>
          ) : (
            <div></div>
          )}

          {showEditSummarySection ? (
            <Box
              sx={{
                margin: "15px 0px 0px 0px",
                borderRadius: "5px",
                border: "1px solid #b7b7b7",
                padding: "15px",
              }}
            >
              <h5 sx={{ margin: "0px 0px 15px 0px" }}>
                {editSummaryData["url_id"]} : {editSummaryData["url"]}
              </h5>
              <TextareaAutosize
                value={editSummaryData["summary"]}
                onChange={changeEditSummaryData}
                placeholder="Edit your summary text"
                minRows={5}
                style={{ width: "100%", overflow: "auto", borderRadius: "5px" }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 0px 8px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => saveEditSummaryData()}
                  style={{ marginRight: "8px" }}
                >
                  Save Summary Text
                </Button>
              </Box>
            </Box>
          ) : (
            <div></div>
          )}

          {showEditKeywordsSection ? (
            <Box
              sx={{
                margin: "15px 0px 0px 0px",
                borderRadius: "5px",
                border: "1px solid #b7b7b7",
                padding: "15px",
              }}
            >
              <h5 sx={{ margin: "0px 0px 15px 0px" }}>
                {editKeywordsData["url_id"]} : {editKeywordsData["url"]}
              </h5>
              <TextareaAutosize
                value={editKeywordsData["keywords"]}
                onChange={changeEditKeywordsData}
                placeholder="Edit your scrapped text"
                minRows={5}
                style={{ width: "100%", overflow: "auto", borderRadius: "5px" }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  margin: "20px 0px 8px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => saveEditKeywordsData()}
                  style={{ marginRight: "8px" }}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          ) : (
            <div></div>
          )}
        </Box>
      </Modal>
      <Modal
        open={showScrapeSummaryModal}
        onClose={() => setShowScrapeSummaryModal(false)}
        aria-labelledby="modal-scrape-summary-title"
        aria-describedby="modal-scrape-summary-description"
      >
        <Box id="LinkExchange_model_box">
          <IconButton
            onClick={() => setShowScrapeSummaryModal(false)}
            sx={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <CloseIcon />
          </IconButton>

          <div className="LinkExchange_modal_header">
            <Typography variant="h6" component="div" sx={{ marginTop: "20px" }}>
              Scraping Completed
            </Typography>
          </div>

          {scrapeSummary.notScraped > 0 ? (
            <>
              <div className="LinkExchange_form_group">
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ marginBottom: "10px" }}
                >
                  <span role="img" aria-label="checkmark">
                    ✔️
                  </span>{" "}
                  We have successfully scraped {scrapeSummary.scraped} URLs.
                </Typography>
                <Typography variant="body1" component="div">
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>{" "}
                  Unfortunately, {scrapeSummary.notScraped} URLs could not be
                  scraped.
                </Typography>
              </div>
              <div className="LinkExchange_form_group">
                <Typography variant="body1" component="div">
                  <span role="img" aria-label="thumbs-up">
                    👍
                  </span>{" "}
                  All URLs have been processed.
                </Typography>
              </div>
              <div
                className="LinkExchange_form_group"
                sx={{ marginTop: "15px" }}
              >
                <div className="modal_button_container">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      saveRows();
                    }}
                    color="primary"
                  >
                    Save Data
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setShowScrapeSummaryModal(false)}
                    color="primary"
                  >
                    Close
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="LinkExchange_form_group">
                <Typography variant="body1" component="div">
                  <span role="img" aria-label="thumbs-up">
                    🎉
                  </span>{" "}
                  All URLs have been successfully scraped!
                </Typography>
              </div>
              <div
                className="LinkExchange_form_group"
                sx={{ marginTop: "15px" }}
              >
                <div className="modal_button_container">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      saveRows();
                    }}
                    color="primary"
                  >
                    Save Data
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setShowScrapeSummaryModal(false)}
                    color="primary"
                  >
                    Close
                  </Button>
                </div>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/*     
      <Modal
        open={showScrapeSummaryModal}
        onClose={() => setShowScrapeSummaryModal(false)}
        aria-labelledby="modal-scrape-summary-title"
        aria-describedby="modal-scrape-summary-description"
      >
        <Box id="LinkExchange_model_box">
          <IconButton
            onClick={() => setShowScrapeSummaryModal(false)}
            sx={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <CloseIcon />
          </IconButton>
          <div className="LinkExchange_modal_header">
            <Typography variant="h6" component="div">
              {scrapeSummary.notScraped > 0
                ? "🛠️ Scraping Process Completed"
                : "✅ Scraping Process Summary"}
            </Typography>
          </div>
          {scrapeSummary.notScraped > 0 ? (
            <>
              <div
                className="LinkExchange_form_group"
                sx={{ marginBottom: "15px" }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ marginBottom: "10px" }}
                >
                  <span role="img" aria-label="checkmark">
                    ✔️
                  </span>{" "}
                  We have partially scraped {scrapeSummary.scraped} URLs.
                </Typography>
                <Typography variant="body1" component="div">
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>{" "}
                  Unfortunately, {scrapeSummary.notScraped} URLs could not be
                  scraped due to unforeseen issues.
                </Typography>
              </div>
              <div
                className="LinkExchange_form_group"
                sx={{ marginBottom: "15px" }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ marginBottom: "10px" }}
                >
                  Would you like to continue scraping the remaining URLs using
                  the selected method?
                </Typography>
              </div>

              <div
                className="LinkExchange_form_group"
                sx={{ marginBottom: "15px" }}
              >
                <FormControl variant="outlined">
                  <InputLabel id="scraper-select-label">
                    Choose Scraper
                  </InputLabel>
                  <Select
                    labelId="scraper-select-label"
                    id="scraper-select"
                    label="Choose Scraper"
                    defaultValue={getNextScrapeMethod(scrape_method_check)}
                    onChange={(event) =>
                      set_selected_scrapper_option(event.target.value)
                    }
                  >
                    <MenuItem value="BeautifulSoup">BeautifulSoup</MenuItem>
                    <MenuItem value="Webcache">Webcache</MenuItem>
                    <MenuItem value="Selenium">Selenium</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleRemainingUrlToScrape}
                  color="primary"
                  sx={{ marginTop: "10px" }}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <div className="LinkExchange_form_group">
              <Typography variant="body1" component="div">
                <span role="img" aria-label="thumbs-up">
                  👍
                </span>{" "}
                The scraping process is now complete. All URLs have been
                successfully processed.
              </Typography>
              <div
                className="LinkExchange_form_group"
                sx={{ marginTop: "15px" }}
              >
                <div className="modal_button_container">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      saveRows();
                    }}
                    color="primary"
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setShowScrapeSummaryModal(false)}
                    color="primary"
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal> */}

      {/* finalScrapeSummaryModal */}

      <Modal
        open={finalScrapeSummaryModal}
        onClose={() => setFinalScrapeSummaryModal(false)}
        aria-labelledby="modal-final-scrape-summary-title"
        aria-describedby="modal-final-scrape-summary-description"
      >
        <Box id="LinkExchange_model_box">
          <IconButton
            onClick={() => setFinalScrapeSummaryModal(false)}
            sx={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <CloseIcon />
          </IconButton>
          <div className="LinkExchange_modal_header">
            <Typography variant="h6" component="div">
              Scraping Completed
            </Typography>
          </div>
          <div className="LinkExchange_form_group">
            <Typography variant="body1" component="div">
              The scraping process for all URLs has been completed. Would you
              like to save the data?
            </Typography>
            <div className="LinkExchange_form_group" sx={{ marginTop: "15px" }}>
              <div className="modal_button_container">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    saveRows();
                  }}
                  color="primary"
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setFinalScrapeSummaryModal(false)}
                  color="primary"
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Credit Deduction Modal for Scrapping*/}
      <Modal
        open={show_should_we_scrape_client_urls_modal}
        onClose={handle_should_we_scrape_client_urls_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handle_should_we_scrape_client_urls_close}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processScrapeClientUrlAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* Credit Deduction Modal for find automatic Keywords*/}

      <Modal
        open={show_should_we_find_automatic_keywords_in_client_urls_modal}
        onClose={handle_should_we_find_automatic_keywords_in_client_urls_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="LinkExchange_model_box">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={
                handle_should_we_find_automatic_keywords_in_client_urls_close
              }
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="LinkExchange_modal_header">Current Credits </div>
          <div className="LinkExchange_modal_text_heading">
            Before proceeding with this action, please review the following:
          </div>
          <div className="LinkExchange_modal_text">
            Available Credits :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForTotalCredits ? "red" : "inherit",
                }}
              >
                {totalCreditPoints}
              </div>
              {/* {totalCreditPoints} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text">
            Weekly Credits Limit Left :{" "}
            <div className="LinkExchange_modal_number">
              <div
                style={{
                  color: errorMassageForCreditWeekLimits ? "red" : "inherit",
                }}
              >
                {creditPointsLimit}
              </div>
              {/* {creditPointsLimit} */}
            </div>
          </div>
          <div className="LinkExchange_modal_text_deduct">
            Performing this action will deduct{" "}
            <div className="LinkExchange_modal_deduct_number">
              {" "}
              {!isNaN(deductionPoints) ? deductionPoints : 0} credits{" "}
            </div>{" "}
            from your balance.
          </div>
          {(errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits) && (
            <div
              style={{
                color: "red",
                textAlign: "center",

                fontWeight: "bolder",
                fontFamily: "monospace",
                fontSize: "larger",
              }}
            >
              <div>{errorMassageForTotalCredits}</div>
              <div>{errorMassageForCreditWeekLimits}</div>
              <div>{errorMessage}</div>
            </div>
          )}

          {!(
            errorMessage ||
            errorMassageForTotalCredits ||
            errorMassageForCreditWeekLimits
          ) && (
            <>
              <div className="LinkExchange_modal_header">After Deduction</div>
              <div className="LinkExchange_modal_text">
                Balance Credits :
                <div className="LinkExchange_modal_number">
                  {totalCreditAfterDeduction}
                </div>
              </div>
              <div className="LinkExchange_modal_text">
                Weekly Credits Limit Left :{" "}
                <div className="LinkExchange_modal_number">
                  {creditWeekLimitAfterDeduction}
                </div>
              </div>

              <div className="LinkExchange_form_group">
                <Button
                  variant="contained"
                  size="small"
                  onClick={processFindAutomaticKeywordsInClientUrlAndUpdate}
                  color="primary"
                >
                  Confirm Action
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>

      {/* Scraping Process Summary */}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={notificationMessage}
      />
    </>
  );
};

export default ClientURLsPage;

//onChange={(e) => setTextareaValue(e.target.value)}
